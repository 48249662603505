import React from 'react';
import { Icon } from 'rsuite';
import { accentColor } from '@/assets/jss/colorConstants';

interface IProps {
    /** Current sort state. */
    sortBy: string,
}

/**
 * Component for sorting a table column heade icon.
 * @prop {string} sortBy Current sort state.
 */

export const SortableTableHeaderIcon = ({ sortBy }: IProps) => {
    return (
        <>
            {sortBy === '' ? <Icon
                className='pl-2'
                icon="sort"
                color={accentColor}
            /> : sortBy === 'desc' ?
                <Icon
                    className='pl-2'
                    icon="sort-desc"
                    color={accentColor} /> :
                <Icon
                    className='pl-2'
                    icon="sort-up"
                    color={accentColor} />}
        </>
    )
}