import { listBuilderAxios, userAccountAxios } from "@/axiosInstances"
import { logError } from "@/utils"
import { IPlatform } from "@/views/TikTok/Campaigns/Campaigns"
import { GetImportedCampaignSchema, GetNotImportedCampaignSchema, ImportedCampaignsStatusSchema } from "@/views/TikTok/Campaigns/types"
import { ActivationStatus, GetActivationsSchema as GetActivationsSchema, SubmitActivationsSchema, UpdateActivationStatusSchema } from "@/views/TikTok/Activations/types"
import { GetHashtagAudienceSchema, GetHashtagsSchema, IStatusOption } from "@/views/TikTok/Hashtags/types"
import { CampaignOptionsSchema, FinalActivationType } from "@/views/TikTok/ActivationManager/types"

var fileDownload = require('js-file-download');

export const tikTok = {
  status: async (accountId: number) => {
    const url = `/tik-tok/${accountId}/status`

    return userAccountAxios.get(url)
  },

  setAccessToken: async (authCode: string, accountId: number) => {
    const url = `/tik-tok/${accountId}/connect`

    return userAccountAxios.post(url, { authCode })
  },

  removeAccessToken: async (accountId: number) => {
    const url = `/tik-tok/${accountId}/remove`

    return userAccountAxios.post(url)
  },

  getAccessToken: async (authCode: string) => {
    const url = '/tik-tok/access-token'
    if (authCode) {
      const body = { 'authCode': authCode }

      try {
        const result = await userAccountAxios.post(url, body)
        return { accessToken: result.data.data?.access_token, code: result.data.code }
      } catch (error) {
        logError(error)
      }
    } else {      
      return null;
    }
  },
  getHashtags: async (category: string[], date: string, status: IStatusOption[], sortBy: string, limit: number, offset: number, search: string, accountId?: number) => {
    const url = '/tik-tok/hashtags'

    if (category && date && status && sortBy) {

      const body = {
          filters: {
            sortBy,
            category,
            date,
            status,
            search,
          },
          limit,
          offset,
          accountId
      }

      const result = await listBuilderAxios.post(url, body)
      return GetHashtagsSchema.parse(result.data)
    } else {
      return 
    }
  },
  getHashtagAudience: async (hashtagId: string, dateRange: string) => {
    if (hashtagId && dateRange) {
      const url = `/tik-tok/hashtags/audience/${hashtagId}?dateRange=${dateRange}`
      
      const result = await listBuilderAxios.get(url)
      return GetHashtagAudienceSchema.parse(result.data)
    } else {
      return
    }
  },
  getHashtagsStatus: async (hashtagsIds: string[], accountId?: number) => {
    const url = `/tik-tok/${accountId}/hashtags-status`

    if (!(hashtagsIds && accountId)) return
    const body = {
       hashtagsIds
    }

    const result = await userAccountAxios.post(url, body)
    return result.data

  },
  // Campaigns
  getImportedCampaigns: async (minCreatedTime?: string, 
    maxCreatedTime?: string, operationStatus?: string, search?: string, accountId?: number) => {
    if (!accountId) return

    let url = `/tik-tok/${accountId}/imported-campaigns?`
    
    if (minCreatedTime) url += `&minCreatedTime=${minCreatedTime}`
    if (maxCreatedTime) url += `&maxCreatedTime=${maxCreatedTime}`
    if (operationStatus) url += `&operationStatus=${encodeURIComponent(operationStatus)}`
    if (search) url += `&search=${encodeURIComponent(search)}`

    const result = await userAccountAxios.get(url)
    return GetImportedCampaignSchema.parse(result.data)
  },
  getNotImportedCampaigns: async (page: number, pageSize: number, accountId?: number, search?: string, selectedPlatform?: IPlatform) => {
    if (!(accountId && selectedPlatform)) return

    const url = `/tik-tok/${accountId}/local-campaigns?page=${page}&pageSize=${pageSize}&operationStatus=ENABLE${search ? `&search=${encodeURIComponent(search)}` : ''}`

    const result = await userAccountAxios.get(url)
    return GetNotImportedCampaignSchema.parse(result.data)
  },
  importCampaigns: async (campaignIds: number[], accountId?: number) => {
    if (!accountId || campaignIds.length < 1) return

    const body = {
      campaignIds:campaignIds
    }

    const url = `/tik-tok/${accountId}/save-imported`

    return userAccountAxios.post(url, body)
  },
  refreshCampaignsFromTikTok: async (accountId?: number) => {
    if (!accountId) return

    const url = `/tik-tok/${accountId}/campaigns`
    
    return userAccountAxios.get(url)
  },
  removeFromImportedCampaigns: async (campaignIds: Set<number>, accountId?: number) => {
    if (!(accountId && campaignIds)) return

    const body = {
      campaignIds: Array.from(campaignIds)
    }

    const url = `/tik-tok/${accountId}/delete-imported`
    
    return userAccountAxios.post(url, body)
  },
  getAdvertisers: async (accountId?: number) => {
    if (!accountId) return

    const url = `/tik-tok/advertiser/${accountId}`
    
    return userAccountAxios.get(url)
  },
  getActivations: async (status: string, accountId: number, page: number, pageSize: number, search?: string, campaign?: number, adGroup?: number, hashtagStatus?: string, minCreatedDate?: string, maxCreatedDate?: string) => {
    let url = `/tik-tok/${accountId}/activations?status=${status}&page=${page}&pageSize=${pageSize}`
    if (search) url += `&search=${encodeURIComponent(search)}`
    if (hashtagStatus) url += `&hashtagStatus=${hashtagStatus}`
    if (minCreatedDate) url += `&minCreatedDate=${minCreatedDate}`
    if (maxCreatedDate) url += `&maxCreatedDate=${maxCreatedDate}`
    
    if ([ActivationStatus.pending.id, ActivationStatus.active.id, ActivationStatus.failed.id].includes(status)) {
      if (campaign) url += `&campaign=${campaign}`
      if (adGroup) url += `&adGroup=${adGroup}`
    }

    const result = await userAccountAxios.get(url)
    return GetActivationsSchema.parse(result.data)
  },
  getImportedCampaignStatus: async (accountId: number) => {

    if (!accountId) return 

    const url = `/tik-tok/${accountId}/imported-campaigns-status`
    
    const result = await userAccountAxios.get(url)
    
    return ImportedCampaignsStatusSchema.parse(result.data)

  },
  getCampaignsAdGroupsHierarchy: async ( accountId: number ) => {
    if (!accountId) return 
    const url = `/tik-tok/${accountId}/campaigns-ad-groups-hierarchy`
    
    const result = await userAccountAxios.get(url)
    
    return CampaignOptionsSchema.parse(result.data)
  },
  changeActivationsStatus: async (accountId: number, activationIds: Set<number>) => {
    const url = `/tik-tok/${accountId}/activations`
    
    const body = {
      activationIds: Array.from(activationIds)
    }
    const result = await userAccountAxios.patch(url, body)
    
    return UpdateActivationStatusSchema.parse(result.data);
  },
  submitActivations: async (accountId: number, activations:FinalActivationType[] | undefined) => {
    const url = `/tik-tok/${accountId}/activations/submit`
    const body = {
      activateHashtagList: activations
    }
    const result = await userAccountAxios.post(url, body)
    
    return SubmitActivationsSchema.parse(result.data);
  },
  downloadActivations: async (accountId: number, activations: Set<number>) => {
    const url = `/tik-tok/${accountId}/download-activations`
    const result = await userAccountAxios.get(url, { responseType: 'blob', params: { activationIds: Array.from(activations) } })
    fileDownload(result.data, `activations.xlsx`)
  }
}
