import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum GOOGLE_PLACEMENT {
  DEVICE = 'device',
  PLACEMENT_TYPE = 'placementType',
  YOUTUBE_PLACEMENT_ID = 'youtubePlacementId',
  YOUTUBE_PLACEMENT_NAME = 'youtubePlacementName',
}

interface YoutubePlacementDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | GOOGLE_PLACEMENT
}

export interface YoutubePlacementReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | GOOGLE_PLACEMENT | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | YoutubePlacementDimensionOption)[]
}

export const dimensionOptions: Option<YoutubePlacementDimensionOption> = {
  [GOOGLE_PLACEMENT.DEVICE]: {
    name: 'Device',
    value: GOOGLE_PLACEMENT.DEVICE,
    description: `CONNECTED_TV,
                  DESKTOP,
                  MOBILE,
                  TABLET`
  },
  [GOOGLE_PLACEMENT.PLACEMENT_TYPE]: {
    name: 'Placement Type',
    value: GOOGLE_PLACEMENT.PLACEMENT_TYPE,
    description: 'Represents the type of placement' // Generated description
  },
  [GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_ID]: {
    name: 'Youtube Placement Id',
    value: GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_ID,
    description: 'Unique identifier for a specific placement' // Generated description
  },
  [GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_NAME]: {
    name: 'Youtube Placement Name',
    value: GOOGLE_PLACEMENT.YOUTUBE_PLACEMENT_NAME,
    description: 'Name associated with a specific placement' // Generated description
  },
}

export const baseYoutubePlacementReport: YoutubePlacementReport = {
  name: 'Youtube Placement Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.GOOGLE_PLACEMENT,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}
