import { userAccountAxios } from "@/axiosInstances";
import { ActivationReportConfiguration } from "@/views/ActivationReports/types/activation-report.types";
import {
    ActivationReportEditSchema,
    ActivationReportMainSchema
} from "@/views/ActivationReports/schemas/activation-report.schema";
import {downloadFile} from "@/utils_ts";


type GetReportConfigurationQueryKey = [string, number | undefined, number | undefined, number | undefined, string | undefined, string | undefined];

export const activationReportController = {
    validateForm: (formValues: ActivationReportConfiguration) => {
        let errorMessages: string[] = []

        Object.keys(formValues).forEach((key) => {
            if (formValues[key as keyof ActivationReportConfiguration] === '') {
                errorMessages.push(`${key.charAt(0).toUpperCase() + key.slice(1).toLowerCase()} cannot be empty.`);
            }
        })

        formValues.reports.forEach((report) => {
            if (report.columns.length === 0) {
                errorMessages.push('At least one filter must be selected in ' + report.name)
            }
            const hasDimensionColumn = report.columns.some((column : any) => column.category == 'Dimensions');

            if (!hasDimensionColumn) {
                errorMessages.push('At least one filter from the category "Dimensions" must be selected in ' + report.name);
            }

        })

        if (formValues.insertionOrders.length === 0) {
            errorMessages.push('At least one Insertion Order must be selected.')
        }

        if (formValues.startDate === null || formValues.endDate === null) {
            errorMessages.push('A date range must be selected.')
        }
        return errorMessages
    },
    normalizeForm: (formValues: ActivationReportConfiguration ) => {
        const modifyReport = formValues.reports.map((report: any) => ({
            ...report,
            columns: report.columns.map((column: any) => column.value)
        }));

        const configuration = {
            ...formValues,
            reports: modifyReport
        }

        return configuration
    },
    handleCreateReport: async (formValues: ActivationReportConfiguration, accountId: number) => {

        const configuration = activationReportController.normalizeForm(formValues);

        const dataToSend = {
            accountId,
            configuration,
        }
        try {
            const url = `/v1/reporting/activation-reports`

            const response = await userAccountAxios.post(url, dataToSend);

            return response;

        } catch (error) {
            console.error(error)
            throw error;

        }

    },
    downloadReport: async (formValues: ActivationReportConfiguration, needNormalizeForm: boolean = false) => {
        try {
            const configuration = needNormalizeForm ? activationReportController.normalizeForm(formValues) : formValues;

            const response = await userAccountAxios.post(
                '/v1/reporting/activation-reports/generate',
              configuration,
                {
                    responseType: 'blob'
                })

            const fileName = `${configuration.name}.zip`

            downloadFile(response.data, fileName)

            return response;
        } catch (error) {
            console.error('API error:', error)
        }
    },
    getReportConfigurations: async ({ queryKey }: { queryKey: GetReportConfigurationQueryKey }) => {
        try {
            const [ , accountId, page, pageSize, orderBy, sortBy] = queryKey

            let url = `/v1/reporting/activation-reports?accountId=${accountId}&page=${page}&pageSize=${pageSize}`

            if (orderBy) url += `&orderBy=${orderBy}`
            if (sortBy) url += `&sort=${sortBy}`

            const { data } = await userAccountAxios.get(url)

            const resultData = ActivationReportMainSchema.parse(data)

            return resultData
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    getReportConfigurationsById: async ( id :number ) => {
        try {
            const url = `/v1/reporting/activation-reports/${id}`

            const { data } = await userAccountAxios.get(url)

            const aylienIndustriesParsing = ActivationReportEditSchema.parse(data)

            return aylienIndustriesParsing
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    handleEditReport: async (formValues: ActivationReportConfiguration, accountId: number, reportId: number) => {

        const configuration = activationReportController.normalizeForm(formValues);

        const dataToSend = {
            accountId,
            configuration,
        }
        try {
            const url = `/v1/reporting/activation-reports/${reportId}`

            const response = await userAccountAxios.patch(url, dataToSend);

            return response;

        } catch (error) {
            console.error(error)
            throw error;

        }

    },
    handleDeleteReport: async (id: number) => {
        try {
            const url = `/v1/reporting/activation-reports/${id}`

            const response = await userAccountAxios.delete(url)

            if (response.status == 204) {
                return true;
            }
            return false;
        } catch (error) {
            console.error(error)
            throw error
        }
    },

}

