import React from 'react'
import { Link, useMatch } from '@tanstack/react-location'
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { api } from '@/views/Admin/Scenarios/api'
import { PatchThemeSchema, PatchThemeType } from '@/views/Admin/Scenarios/types'
import SightlyButton from '@/components/Sightly/SightlyButton'
import toast from 'react-hot-toast'
import { isAxiosError } from '@/utils_ts'
import { logError } from '@/utils'
import { MyLocationGenerics } from '@/classes/utils'
import { LoadingPage } from '@/components/LoadingPage'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle'

export const EditTheme = () => {
  const [deletedValue, setDeletedValue] = React.useState(false)

  const {
    params: { themeId }
  } = useMatch<MyLocationGenerics>()

  const themeQuery = useQuery(['ScenarioTheme', themeId], () => api.themes.getById({ themeId: Number(themeId) }), {
    enabled: !!themeId
  })
  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    reset,
    formState: { errors, isDirty, isValid }
  } = useForm<PatchThemeType>({
    defaultValues: {
      name: '',
      description: ''
    },
    resolver: zodResolver(PatchThemeSchema)
  })

  React.useEffect(() => {
    if (!themeQuery.data) return
    reset(themeQuery.data)
  }, [themeQuery.data])

  const onSubmit = (theme: PatchThemeType) => {
    submitMutation.mutate({ theme, themeId: Number(themeId) })
  }

  const submitMutation = useMutation(api.themes.patch, {
    onSuccess: () => {
      toast.success('Changes saved')
    },
    onError: (err) => {
      reset(themeQuery.data)
      if (isAxiosError<any>(err)) {
        // Access to config, request, and response
        const isDuplicateNameError = err?.response?.data.includes('already exists')
        const linkedToScenarioError = err?.response?.data.includes('Cannot archive theme linked to scenario')
        if (isDuplicateNameError) {
          toast.error('That name is already used, please try another name')
        } else if (linkedToScenarioError) {
          toast.error('Cannot archive theme linked to scenario.')
        } else {
          toast.error('Unknown error ocurred')
          logError(err, { info: 'error posting scenario' })
        }
      } else {
        toast.error('Unknown error ocurred')
        logError(err, { info: 'error posting scenario' })
      }
    },
    onSettled: () => {
      reset(getValues())
    }
  })

  const softDeleteMutation = useMutation(() => api.themes.delete(Number(themeId)), {
    onSuccess: () => {
      toast.success('Theme archived')
      setDeletedValue(true)
    },
    onError: (err: any) => {
      toast.error(err.response.data.message ? err.response.data.message : 'Unknown error ocurred')
      logError(err, { info: 'error archiving theme' })
      setDeletedValue(false)
    }
  })

  const recoverMutation = useMutation(() => api.themes.recover(Number(themeId)), {
    onSuccess: () => {
      toast.success('Theme recovered')
    },
    onError: (err) => {
      toast.error('Unknown error ocurred')
      logError(err, { info: 'error recovering theme' })
    }
  })

  if (themeQuery.isLoading) {
    return <LoadingPage message="Fetching Theme" />
  }

  return (
    <div className="w-full flex justify-center items-center mt-12">
      <div className="w-3/6">
        <Link to={'/app/admin/scenarios/themes'}>Back to Themes</Link>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Edit Theme
            </h2>
          </div>
        </div>
        <form
          className="w-full space-y-4 mt-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SightlyToggle
            id="archived"
            setEnabled={(v) => {
              if (v) {
                softDeleteMutation.mutate()
                setDeletedValue(true)
              } else {
                recoverMutation.mutate()
                setDeletedValue(false)
              }
            }}
            enabled={deletedValue}
            enabledText="Archived"
            disabledText="Active"
            isDisabled={false}
          />
          <SightlyInputForm
            control={control}
            id="name"
            name="name"
            register={register}
            label="Name"
            required
            autoFocus
            error={errors?.name?.message}
            disabled={Boolean(getValues().deletedAt)}
          />

          <SightlyInputForm
            control={control}
            id="description"
            name="description"
            register={register}
            label="Description"
            required
            autoFocus
            error={errors?.description?.message}
            textarea
            disabled={Boolean(getValues().deletedAt)}
          />

          <SightlyButton
            id="submitButton"
            handleClick={() => undefined}
            text="Submit"
            disabled={!isDirty || !isValid}
          />
        </form>
      </div>
    </div>
  )
}
