import React from 'react'
import SidePanel from '@/views/Discover/Boards/components/conversations/SidePanel'
import {
  SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID,
  SOCIAL_NARRATIVE_PANEL_HEADER_TITLE,
  SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS
} from '@/views/Discover/Boards/Narratives/constants'
import SocialOverviewTabContents from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialOverviewTabContents'
import SocialPostsTabContents from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialPostsTabContents'
import PlatformsOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/PlatformsOverview'
import ThemesOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/ThemesOverview'
import useSocialClusterById from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/useSocialClusterById'
import { LoadingPage } from '@/components/LoadingPage'

interface SocialNarrativePanelProps {
  openSocialNarrativePanel: boolean
  setOpenSocialNarrativePanel: React.Dispatch<React.SetStateAction<boolean>>
  selectedSocialClusterId: string
  tab?: string
}

const SocialNarrativeSlideOver = ({
  openSocialNarrativePanel,
  setOpenSocialNarrativePanel,
  selectedSocialClusterId,
  tab
}: SocialNarrativePanelProps) => {
  const [activeTabId, setActiveTabId] = React.useState<string>(tab || SOCIAL_NARRATIVE_PANEL_DEFAULT_TAB_ID)

  const { socialClusterData, socialClusterIsLoading } = useSocialClusterById(selectedSocialClusterId)

  const renderTabContent = (): React.ReactNode => {
    switch (activeTabId) {
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[0].id:
        return <SocialOverviewTabContents overview={socialClusterData?.overview} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[1].id:
        return <SocialPostsTabContents posts={socialClusterData?.posts} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[2].id:
        return <PlatformsOverview platformsOverview={socialClusterData?.platforms} />
      case SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS[3].id:
        return <ThemesOverview themesOverview={socialClusterData?.themes} />
      default:
        return null
    }
  }

  return (
    <SidePanel
      open={openSocialNarrativePanel}
      activeTabId={activeTabId}
      setActiveTabId={setActiveTabId}
      headerTitle={SOCIAL_NARRATIVE_PANEL_HEADER_TITLE}
      tabOptions={SOCIAL_NARRATIVE_PANEL_TAB_OPTIONS}
      onHide={() => setOpenSocialNarrativePanel(false)}
      dataTestId="conversations-social-narrative-side-panel"
    >
      {socialClusterIsLoading ? <LoadingPage message="Loading narrative data" /> : renderTabContent()}
    </SidePanel>
  )
}

export default SocialNarrativeSlideOver
