import React from 'react'
import { Link, useMatch } from '@tanstack/react-location'
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation, useQuery } from '@tanstack/react-query'
import { api } from '@/views/Admin/Scenarios/api'
import { LoadingPage } from '@/components/LoadingPage'
import { CheckTreePicker } from 'rsuite'
import { PatchScenarioSchema, PatchScenarioType, ScenarioType } from '@/views/Admin/Scenarios/types'
import { FormLabel } from '@/components/Sightly/SightlyFormElements/SightlyFormLabel'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { FormError } from '@/components/Sightly/SightlyFormElements/SightlyFormError'
import toast from 'react-hot-toast'
import { isAxiosError } from '@/utils_ts'
import { logError } from '@/utils'
import { useThemes } from './useThemes'
import { MyLocationGenerics } from '@/classes/utils'

export const EditScenario = () => {
  const {
    params: { scenarioId }
  } = useMatch<MyLocationGenerics>()

  const scenarioQuery = useQuery(
    ['scenario', scenarioId],
    () => api.scenarios.getById({ scenarioId: Number(scenarioId) }),
    {
      enabled: !!scenarioId,
      onError: (err) => {
        logError(err)
      }
    }
  )
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors, isDirty, isValid }
  } = useForm<ScenarioType>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      id: 0,
      name: '',
      themes: [],
      deletedAt: null
    },
    resolver: zodResolver(PatchScenarioSchema)
  })

  React.useEffect(() => {
    if (scenarioQuery.data) {
      // Transform the themes array to the required format
      const transformedData = {
        ...scenarioQuery.data,
        themes: scenarioQuery.data.themes.map((theme) => theme.id)
      }
      reset(transformedData)
    }
  }, [scenarioQuery.data, reset])

  const onSubmit = (scenario: PatchScenarioType) => {
    const themes = scenario.themes.map((t) => ({ id: t }))
    submitMutation.mutate({ ...scenario, themes })
  }

  const submitMutation = useMutation(
    (scenario: PatchScenarioType) => api.scenarios.patch(scenario, Number(scenarioId)),
    {
      onSettled: () => {
        reset(getValues())
      },
      onSuccess: () => {
        toast.success('Changes saved')
      },
      onError: (err) => {
        if (isAxiosError<any>(err)) {
          // Access to config, request, and response
          const isDuplicateNameError = err?.response?.data.includes('already exists')
          if (isDuplicateNameError) {
            toast.error('That name is already used, please try another name')
          } else {
            toast.error('Unknown error ocurred')
            logError(err, { info: 'error posting scenario' })
          }
        } else {
          toast.error('Unknown error ocurred')
          logError(err, { info: 'error posting scenario' })
        }
      }
    }
  )

  const themesQuery = useThemes({ includeDeleted: false })

  if (themesQuery.isLoading) {
    return <LoadingPage message="Fetching data" />
  }

  return (
    <div className="w-full flex justify-center items-center mt-12">
      <div className="w-3/6">
        <Link to={'/app/admin/scenarios'}>Back to Scenarios</Link>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Edit Scenario
            </h2>
          </div>
        </div>
        <form
          className="space-y-4 mt-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SightlyInputForm
            control={control}
            id="name"
            name="name"
            register={register}
            label="Name"
            required
            autoFocus
            error={errors?.name?.message}
            disabled={Boolean(getValues().deletedAt)}
          />

          <div>
            <FormLabel
              text="Themes"
              id="themes"
              required
            />
            <CheckTreePicker
              preventOverflow
              data={themesQuery.data || []}
              labelKey="name"
              valueKey="id"
              onChange={(v) => {
                setValue('themes', v, {
                  shouldValidate: true,
                  shouldDirty: true
                })
              }}
              value={getValues().themes || []}
              block
              disabled={Boolean(getValues().deletedAt)}
            />
            <FormError
              id="message"
              error={errors?.themes?.message}
            />
          </div>

          <SightlyButton
            id="submitButton"
            handleClick={() => undefined}
            text="Submit"
            disabled={!isDirty || !isValid || getValues().themes.length === 0}
          />
        </form>
      </div>
    </div>
  )
}
