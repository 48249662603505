import React, { useEffect, useState } from 'react';
import { isAfter, isBefore, subDays } from 'date-fns';
import DateRangeSection from "@/components/Sightly/SightlyFormElements/DateRangeSection";
import { formatDateyyyyMMdd } from "@/utils";
import BarChartOverview from '@/views/Trends/SearchResults/components/BarChartOverview';
import TotalsOverview from '@/views/Trends/SearchResults/components/TotalsOverview';
import { IMoment, ISearchResult, SearchResultTabs } from '@/views/Trends/types';
import { getLast7Days } from '@/utils/dateRanges';

interface IProps {
    currentTabData: {
        tab: SearchResultTabs;
        data: ISearchResult[];
    };
    tabsCountData: { tab: SearchResultTabs; count: number }[];
}

const Overview = ({ currentTabData, tabsCountData }: IProps) => {
    const [currentTabCountPerDay, setCurrentTabCountPerDay] = useState<{ date: string; count: number }[]>([]);
    const [dateRange, setDateRange] = useState(getLast7Days());

    const endDate = dateRange ? dateRange[1] : new Date();
    const startDate = dateRange ? dateRange[0] : subDays(endDate, 60);

    const handleChangeDateRange = (selectedRange: [Date, Date]) => {
        if (selectedRange === dateRange) return;
        setDateRange(selectedRange);
    };

    useEffect(() => {
        const postCountsInRange: Record<string, number> = {};

        let currentDate = new Date(startDate);
        while (currentDate <= endDate) {
            const formattedDate = formatDateyyyyMMdd(currentDate);
            postCountsInRange[formattedDate] = 0;
            currentDate.setDate(currentDate.getDate() + 1);
        }

        currentTabData.data.forEach((result) => {
            let date: Date;

            if ((result as IMoment).maxPublishedAt) {
                date = new Date((result as IMoment).maxPublishedAt);
            } else {
                date = result.updatedAt ? new Date(result.updatedAt) : new Date();
            }

            if (isAfter(date, startDate) && isBefore(date, endDate)) {
                const formattedDate = formatDateyyyyMMdd(date);
                postCountsInRange[formattedDate] = (postCountsInRange[formattedDate] || 0) + 1;
            }
        });

        const formattedData = Object.entries(postCountsInRange).map(([date, count]) => ({
            date,
            count,
        }));

        setCurrentTabCountPerDay(formattedData);
    }, [dateRange, currentTabData]);

    return (
        <div className="w-full h-full flex flex-col justify-between">
            {/* Date range filter */}
            <div className="flex flex-col gap-2 pb-4" data-test-id="overview-date-range">
                <span className="text-sm">{currentTabData.tab}s by day</span>
                <DateRangeSection
                    onSelectDateRange={handleChangeDateRange}
                    rangeValue={getLast7Days()}
                />
            </div>

            <BarChartOverview data={currentTabCountPerDay} />

            <TotalsOverview tabsCountData={tabsCountData} />
        </div>
    );
};

export default Overview;
