import { api } from "@/api/api"
import { logError } from "@/utils"
import { useQuery } from "@tanstack/react-query"

export function useNarrativeSearch(search?: string) {

  const narrativesQuery = useQuery(
    ['narrativesQuery', search],
    async ({ signal }) => {
      return await api.narratives.get(search, signal)
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10, 
      enabled: !!search
    }
  )

  return {
    narrativesData: narrativesQuery.data,
    narrativesError: narrativesQuery.error,
    isLoadingNarratives: narrativesQuery.isLoading,
  }
}

export function useNarrativesById(narrativeId: number) {
  const narrativesQuery = useQuery(
    ['narrativesQuery', narrativeId],
    async () => {
      return await api.narratives.getById(narrativeId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting topic' })
      }
    }
  )

  return {
    narrativesQuery
  }
}