import SlideOver from '@/components/SlideOver';
import React, { useState } from 'react'
import { Layer, Rectangle, ResponsiveContainer, Sankey, Tooltip, TooltipProps } from 'recharts';
import TrendMomentViewSlideOver from '@/views/Trends/components/TrendMomentSlideover';
import TopicMomentViewSlideOver from '@/views/Trends/components/TopicMomentSlideover';
import { Placeholder } from 'rsuite';
import { LoadingPage } from '@/components/LoadingPage';

interface IProps {
    dataNarratives: any,
    dataMoments: any,
    loading: boolean
    trendId?: string,
    topicId?: string,
}

const SankeyGraphComponent = ({ dataNarratives, dataMoments, loading, trendId, topicId }: IProps) => {

    const [showSlideOver, setShowSlideOver] = React.useState(false)

    const SANKEY_BASE_NODE_COLOR = 'rgba(79, 70, 229, 1)';
    const SANKEY_GREEN_NODE_COLOR = 'rgba(72, 194, 148, 1)';

    const SANKEY_BASE_FILL_COLOR = 'rgba(0, 136, 254, 0.5)';
    const SANKEY_BASE_FILL_COLOR_HOVER = 'rgba(0, 125, 204, 0.5)';

    const SANKEY_BASE_FILL_COLOR_GREEN = 'rgba(72, 194, 148, 0.3)';
    const SANKEY_BASE_FILL_COLOR_HOVER_GREEN = 'rgba(72, 194, 148, 0.5)';

    interface NodeProps {
        x: number;
        y: number;
        width: number;
        height: number;
        index: number;
        type: string;
        payload: {
            name: string;
            value: number;
        };
        containerWidth: number;
    }

    interface CustomLinkProps {
        sourceX: number;
        targetX: number;
        sourceY: number;
        targetY: number;
        sourceControlX: number;
        targetControlX: number;
        linkWidth: number;
        index: number;
        type: string;
    }

    const Link: React.FC<CustomLinkProps> = ({
        sourceX,
        targetX,
        sourceY,
        targetY,
        sourceControlX,
        targetControlX,
        linkWidth,
        index,
        type
    }) => {
        let baseColor = SANKEY_BASE_FILL_COLOR;
        let hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;

        switch (type) {
            case 'Topic':
                baseColor = SANKEY_BASE_FILL_COLOR;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
            case 'Trend':
                baseColor = SANKEY_BASE_FILL_COLOR;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
            case 'Moment':
                baseColor = SANKEY_BASE_FILL_COLOR_GREEN;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER_GREEN;
                break;
            default:
                baseColor = SANKEY_BASE_FILL_COLOR;
                hoverColor = SANKEY_BASE_FILL_COLOR_HOVER;
                break;
        }

        const [fill, setFill] = useState(baseColor);

        return (
            <Layer key={`CustomLink${index}`}>
                <path
                    d={`
            M${sourceX},${sourceY + linkWidth / 2}
            C${sourceControlX},${sourceY + linkWidth / 2}
              ${targetControlX},${targetY + linkWidth / 2}
              ${targetX},${targetY + linkWidth / 2}
            L${targetX},${targetY - linkWidth / 2}
            C${targetControlX},${targetY - linkWidth / 2}
              ${sourceControlX},${sourceY - linkWidth / 2}
              ${sourceX},${sourceY - linkWidth / 2}
            Z
          `}
                    fill={fill}
                    strokeWidth="0"
                    onMouseEnter={() => setFill(hoverColor)}
                    onMouseLeave={() => setFill(baseColor)}
                />
            </Layer>
        );
    };


    const Node: React.FC<NodeProps> = ({
        x,
        y,
        width,
        height,
        index,
        type,
        payload,
        containerWidth,
    }) => {
        const isOut = x + width + 6 > containerWidth;

        let baseColor = SANKEY_BASE_NODE_COLOR;

        switch (type) {
            case 'Topic':
                baseColor = SANKEY_BASE_NODE_COLOR;
                break;
            case 'Trend':
                baseColor = SANKEY_BASE_NODE_COLOR;
                break;
            case 'Moment':
                baseColor = SANKEY_GREEN_NODE_COLOR;
                break;
            default:
                break;
        }

        return (
            <Layer key={`CustomNode${index}`}>
                <Rectangle
                    x={x}
                    y={y}
                    width={width}
                    height={height}
                    fill={baseColor}
                    fillOpacity="1"
                />
                {(type === "Moment" || type === "Narrative") && (
                    <text
                        textAnchor="start"
                        x={x + width + 6}
                        y={y + height / 2 + 4}
                        fontSize="12"
                        fill="#333"
                        fillOpacity="0.8"
                    >
                        {splitText(payload.name, 100).map((line, i) => (
                            <tspan key={i} x={x + width + 6} dy={i === 0 ? 0 : 14}>
                                {line}
                            </tspan>
                        ))}
                    </text>
                )}
            </Layer>
        );

    };
    const [selectedType, setSelectedType] = useState('Moments');

    const handleTypeChange = (type: React.SetStateAction<string>) => {
        if (loading) return

        setSelectedType(type);
    };

    return (
        <div className="flex flex-col justify-center w-full">
            <div className="flex flex-col gap-[2vh] justify-center items-end" >
                <div className='flex gap-[1vw]'>
                    <button disabled={loading} data-testid="sankey-overview-moments-tab"
                        onClick={() => handleTypeChange('Moments')}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition ${loading ? 'bg-gray-100 text-gray-300' :
                            selectedType === 'Moments'
                                ? 'bg-purple-500 text-white'
                                : 'bg-gray-200 text-black'
                            }`}
                    >
                        Moments
                    </button>
                    <button disabled={loading} data-testid="sankey-overview-narratives-tab"
                        onClick={() => handleTypeChange('Narratives')}
                        className={`px-4 py-2 rounded-full text-sm font-medium transition ${loading ? 'bg-gray-100 text-gray-300' :
                            selectedType === 'Narratives'
                                ? 'bg-purple-500 text-white'
                                : 'bg-gray-200 text-black'
                            }`}
                    >
                        Narratives
                    </button>
                </div>
                <span className={`${loading ? 'text-gray-300 cursor-not-allowed' : 'text-[#00AEEF] cursor-pointer'} mr-[0.5vw]`}
                    onClick={(e) => {
                        e.preventDefault()
                        if (loading) return
                        setShowSlideOver(true)
                    }}>View all</span>
            </div>

            { loading ?
                <>
                    <LoadingPage message={'Loading sankey chart'}/>
                    <Placeholder.Graph active />
                </>
            :
                <div className="flex justify-center w-[75vw] h-[80vh]">
                    <ResponsiveContainer width="100%" height="100%">
                        <Sankey
                            margin={{ top: 30, bottom: 30, right: 600 }}
                            data={selectedType === 'Moments' ? dataMoments : dataNarratives}
                            nodeWidth={20}
                            nodePadding={50}
                            linkCurvature={0.5}
                            iterations={30}
                            link={(props) => (
                                <Link
                                    {...props}
                                    linkWidth={Math.min(props.payload.source.dy, props.payload.target.dy)}
                                    type={props.payload.target.type}
                                    opacity={0.7}
                                />
                            )}
                            node={(props) => (
                                <Node
                                    {...props}
                                    type={props.payload.type}
                                    containerWidth={1200}
                                    className="fill-[#696cff] stroke-white stroke-2"
                                />
                            )}
                        >
                            <Tooltip content={<CustomTooltip />} />
                        </Sankey>
                    </ResponsiveContainer>
                </div>
            }
            <SlideOver
                show={showSlideOver}
                onHide={() => setShowSlideOver(false)}
                headerTitle="View Moments"
                headerForegroundColor="text-black"
                headerBackgroundColor="bg-white"
                showToggleFullScreenButton={false}
                maxOffset="60%">

                <div className="px-5 h-full">
                    {trendId ? (
                        <TrendMomentViewSlideOver trendId={trendId} />
                    ) : (
                        <TopicMomentViewSlideOver topicId={topicId} />
                    )}
                </div>
            </SlideOver>
        </div>
    );

}

const CustomTooltip: React.FC<TooltipProps<number, string>> = ({ active, payload }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload;

        if (data && data.payload) {
            const { source, target } = data.payload;

            if (source && target) {
                const label = target.type === "Trend" ? "Trend:" : "Moment:";
                const name = target.name;
                const color = target.type === "Trend" ? "bg-[#5192ca]" : "bg-[#48C294]";

                return (
                    <div className="flex flex-row items-center bg-[#f9f9f9] rounded-lg p-2.5 shadow-md">
                        <div className={`w-4 h-4 ${color} rounded-full m-2`}></div>
                        <div className="flex flex-row max-w-md flex-wrap">
                            <p className="mr-2 font-bold underline">{label}</p>
                            <p className="m-0">{name}</p>
                        </div>
                    </div>
                );
            } else {
                let label = '';
                let color = 'bg-[#5192ca]';

                switch (data.payload.type) {
                    case 'Topic':
                        label = 'Topic:'
                        color = 'bg-[#5192ca]';
                        break;
                    case 'Trend':
                        label = 'Trend:'
                        color = 'bg-[#5192ca]';
                        break;
                    case 'Moment':
                        label = 'Moment:'
                        color = 'bg-[#48C294]';
                        break;
                    default:
                        label = ''
                        break;
                }

                return (
                    <div className="flex flex-row items-center bg-[#f9f9f9] rounded-lg p-2.5 shadow-md">
                        <div className={`w-4 h-4 ${color} rounded-full m-2`}></div>
                        <div className="flex flex-row max-w-md flex-wrap">
                            <p className="mr-2 font-bold underline">{label}</p>
                            <p className="m-0">{data.payload.name}</p>
                        </div>
                    </div>
                );
            }
        }
    }

    return null;
};

const splitText = (text: string, maxLength: number) => {
    const words = text.split(' ');
    const lines = [];
    let currentLine = '';

    words.forEach((word: string) => {
        if ((currentLine + word).length > maxLength) {
            lines.push(currentLine.trim());
            currentLine = word + ' ';
        } else {
            currentLine += word + ' ';
        }
    });

    if (currentLine.trim()) {
        lines.push(currentLine.trim());
    }

    return lines;
};

export default SankeyGraphComponent