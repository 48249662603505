import React from 'react'
import ProgressLine from '@/views/Discover/Boards/Narratives/OverviewSidePanel/ProgressLine'
import PlatformIcon from '@/views/Discover/Boards/components/conversations/PlatformIcon'
import {ConversationPanelSectionLayout} from "@/views/Discover/Boards/Narratives/PanelSectionLayout.";
import {Loader} from "rsuite";

type PlatformsOverviewProps = {
  platformName: string
  score: number
  postCount: number
}[]


const PlatformsOverview = ({platformsOverview}:{platformsOverview : PlatformsOverviewProps}) => {

  if (!platformsOverview.length) {
    return <Loader center/>
  }

  return (
    <ConversationPanelSectionLayout headerName="Platforms" headerCount={platformsOverview.length}>
      <table className="w-full">
        <tbody>
        {platformsOverview.map(({platformName: platform, score, postCount}) => (
          <tr className="h-14">

            <td className="w-40">
              <div className="flex items-center gap-2">
                <PlatformIcon platformName={platform} width="2rem"/>
                <p className="text-sm font-semibold">
                  {platform}
                </p>
              </div>
            </td>

            <td className="p-2 flex-4">
              <ProgressLine score={score}/>
            </td>

            <td className="w-20 font-semibold">{postCount} Posts</td>

          </tr>
        ))}
        </tbody>
      </table>
    </ConversationPanelSectionLayout>
  )
}

export default PlatformsOverview
