import React from 'react'
import { Placeholder } from 'rsuite';

interface IProps {
    itemsCount?: number
    graphShape?: 'circle' | 'square' | 'image'
    paragraphRows?: number
}

export const LoadingItems = ({ itemsCount = 5, graphShape = 'square', paragraphRows = 2 }: IProps) => {

    return (
        <div className="w-full flex flex-col gap-8">
            {Array.from({ length: itemsCount }).map((_, index) => (
                <Placeholder.Paragraph
                    key={index}
                    className="w-full"
                    rows={paragraphRows}
                    graph={graphShape}
                    active
                />
            ))}
        </div>
    )
}
