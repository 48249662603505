import dayjs from 'dayjs';
import * as Sentry from '@sentry/react';
import React from 'react';
import Table from 'rsuite/lib/Table';

var calendar = require('dayjs/plugin/calendar');
dayjs.extend(calendar);

export function logError(err, info) {
  if (process.env.REACT_APP_STAGE === 'production') {
    Sentry.captureException(err, { extra: { info } });
  } else {
    console.error('Logging Error: ', err, { extra: { info } });
  }
}

export function joinClasses(...classes) {
  return classes.join(' ');
}

export function openInNewTab(url) {
  if (!url || url.length < 1) {
    console.error('url must be present');
    return;
  }
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
  if (newWindow) newWindow.opener = null;
}

export function getCalendarDate(_date) {
  if (!_date) return '';
  return dayjs(_date).calendar();
}

 export function formatDateyyyyMMdd(date) {
  if (!date) return ''

  const year = date.getFullYear()
  const month = String(date.getMonth() + 1).padStart(2, '0')
  const day = String(date.getDate()).padStart(2, '0')
  return `${year}-${month}-${day}`
}

export function formatDateToShortMonthDayYear(inputDate) {
  const date = new Date(inputDate);

  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  };

  return new Intl.DateTimeFormat('en-US', options).format(date);
}

const normalizeDate = (date) => new Date(date).setHours(0, 0, 0, 0);

export function isDateBetween(date, start, end) {
  const updatedAt = normalizeDate(date);
  const normalizedStartDate = normalizeDate(start);
  const normalizedEndDate = normalizeDate(end);
  
  return updatedAt >= normalizedStartDate && updatedAt <= normalizedEndDate;
}

export function isRecentDate(dateString) {
  const recentDaysPeriod = 3; // change number to change how long a moment, etc is "new"
  const dateToCheck = dayjs(dateString);
  const threeDaysAgo = dayjs().subtract(recentDaysPeriod, 'days');
  const now = dayjs();
  return dateToCheck.isAfter(threeDaysAgo) && dateToCheck.isBefore(now);
}

export function delimitNumber(num) {
  const locale = navigator.language || 'en-US';
  return Intl.NumberFormat(locale).format(num);
}

export const permissionView = localStorage.getItem('permissionView') === '1'

export const TableColumnJs = (props) => {
  return <Table.Column flexGrow={props.flexGrow} width={props.width}>{props.children}</Table.Column>
}

export const getDateRangeOptions = () => {
  return [
    { id: 1, label: 'Last 24 hrs' },
    { id: 7, label: 'Last 7 days' },
    { id: 30, label: 'Last 30 days' },
    { id: 60, label: 'Last 60 days' }
  ]
}