import React from 'react';
import { permissionView } from '@/utils';
import useUser from '@/hooks/useUser';
import { api } from '@/api/api';
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox';
import { MyProfileFormType, MyProfileFormTypeSchema } from '@/views/Settings/MyProfile/types';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { useMutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { ApiErrorResponse } from '@/api/apiTypes';
import styled from 'styled-components';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput';
import { SightlyModernButton } from '@/components/Sightly/SightlyModern/SightlyModernButton';
import { WarningModalTailwind } from '@/components/WarningModalTailwind';
import {UserType } from '@/schemas/schemas';
import { SettingsLayout } from '@/layouts/SettingsLayout';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const ContainerDiv = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`

const Header = styled.div`
    width: 100%;
    padding: 1rem 1.5rem 0 1.5rem;
    font-size: 1.25rem;
    font-weight: 600;
    color: #333D47;
`

const Label = styled.p`
    font-size: 1.125rem;
    font-weight: 500;
    color: #111827;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    max-width: 48rem;
    padding: 1.5rem;
`

const NameFieldsDiv = styled.div`
    > div {
        flex: 1;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 1em;
`

export const MyProfile: React.FC = () => {
    const { user, perms, userPermissions } = useUser()
    const { sightlyRegister, handleSubmit, formState: { isValid }, trigger, watch, getFieldState } = useSightlyForm<MyProfileFormType>(MyProfileFormTypeSchema, {
        defaultValues: {
            firstName: user?.firstName,
            lastName: user?.lastName,
            email: user?.email,
        }
    })
    const [showGenericErrorDialog, setShowGenericErrorDialog] = React.useState(false)

    const handleViewPermissionsModeClick = (checked: boolean) => {
        if (checked) {
            localStorage.setItem('permissionView', '1')
        } else {
            localStorage.removeItem('permissionView')
        }
        window.location.reload()
    }

    const mutation = useMutation(async (data: Partial<UserType>) => await api.user.updateUser({ ...user, ...data }), {
        onSuccess: (_) => {
        },
        onError: (_: AxiosError<ApiErrorResponse>) => {
            setShowGenericErrorDialog(true)
        }
    });

    const onSubmit = handleSubmit((data) => {
        mutation.mutate(data)
    });

    return (
        <SettingsLayout>
            <ContainerDiv>
                {userCanPermissionProductQuota({
                    requiredPermissionValue: perms.ADMIN_READ,
                    userPermissions: userPermissions,
                    checkType: PermissionCheckType.PERMISSION_CHECK
                }) && (
                    <div style={{ position: 'absolute', bottom: 48, right: 48 }}>
                        <SightlyCheckbox
                            id="viewPermissionsMode"
                            checked={permissionView}
                            handleChange={handleViewPermissionsModeClick}
                            label={'View permissions mode'}
                        />
                    </div>
                )}
                <Header>My Profile</Header>
                <Form onSubmit={onSubmit}>
                    <NameFieldsDiv>
                        <SightlyModernFormInput {...sightlyRegister('firstName', 'First name')} />
                        <SightlyModernFormInput {...sightlyRegister('lastName', 'Last name')} />
                    </NameFieldsDiv>
                    <SightlyModernFormInput {...sightlyRegister('email', 'Work email address')} placeholder='you@example.com' />

                    <div>
                        <SightlyModernButton
                            id='submitButton'
                            type='primary'
                            handleClick={() => undefined}
                            text='Save'
                            disabled={mutation.isLoading || !isValid}
                        />
                    </div>

                    <WarningModalTailwind
                        open={showGenericErrorDialog}
                        setOpen={setShowGenericErrorDialog}
                        title='Something went wrong'
                        subtitle='Please try again, or contact support at support@sightly.com'
                    />
                </Form>
            </ContainerDiv>
        </SettingsLayout>
    )
}
