import sparklesAISvg from '@/assets/img/AI/SparklesAI.svg'
import clockSvg from '@/assets/img/AI/justification/clockIcon.svg'
import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import NoBrandProfiles from '@/components/NoBrandProfiles'
import { SelectAllMomentsPanel } from '@/components/SelectAllMomentsPanel'
import SightlyButton from '@/components/Sightly/SightlyButton'
import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { SightlyInput } from '@/components/Sightly/SightlyFormElements/SightlyInput'
import config from '@/config'
import useBrandProfileInsertionOrderIds from '@/hooks/brandProfile/useBrandProfileInsertionOrderIds'
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import useSessionStorage from '@/hooks/useSessionStorage'
import useUser from '@/hooks/useUser'
import { PATH_MOMENTS_V2_SETTINGS_REDESIGN } from '@/routes'
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota'
import { formatDateyyyyMMdd, logError } from '@/utils'
import { isAxiosError } from '@/utils_ts'
import { GENERAL_BOARD_DEFAULT_TYPES } from '@/views/BrandProfiles/constants'
import useBoards, { Board } from '@/views/Discover/Boards/useBoards'
import BoardTabs from '@/views/Discover/Boards/BoardTabs'
import MomentCard from '@/views/Discover/Moments/v2/MomentCard'
import { rqKeys as MomentViewRqKeys } from '@/views/Discover/Moments/v2/rqKeys'
import {
  ClusterActivationType,
  ClusterType,
  MomentJustificationOrigin,
  MomentSortBy
} from '@/views/Discover/Moments/v2/types'
import { ArrowDownTrayIcon, BoltIcon, NoSymbolIcon } from '@heroicons/react/20/solid'
import { Navigate, useNavigate, useSearch } from '@tanstack/react-location'
import { useQueryClient } from '@tanstack/react-query'
import { useFlag, useUnleashContext } from '@unleash/proxy-client-react'
import dayjs from 'dayjs'
import debounce from 'just-debounce-it'
import React, { useEffect } from 'react'
import toast from 'react-hot-toast'
import { CheckTreePicker, CheckTreePickerProps } from 'rsuite'
import Tooltip from '@/components/TailwindTooltip'
import { activationsRqKeys } from '../../Activations/activationsRqKeys'
import { ActionsMenu } from '../../Moments/v2/ActionsMenu'
import { TargetListProps, TargetListPropsV2, api } from '../../Moments/v2/api'
import { SelectedClusterType } from '@/views/Discover/Moments/v2/types'
import ActionMessages from '../components/ActionMessages'
import { activationStatusOptions } from '../types'
import { BlockSlideOver } from '../../Moments/v2/BlockSlideOver'
import { TargetMoment } from '../../Moments/v2/TargetMoment'
import { SortBy } from '../../Moments/v2/SortBy'
import { TargetSlideOver } from '../../Moments/v2/TargetSlideOver'
import { MomentSlideOver } from '../../Moments/v2/components/MomentSlideOver/MomentSlideOver'
import AylienBoardPortalModal from '../components/AylienBoardPortalModal'
import ConfirmationModals from '../components/ConfirmationModals'
import { TargetListType } from '../../Moments/v2/TargetMoment'
import InfiniteScroll from 'react-infinite-scroll-component'
import { LoadingItems } from '@/components/LoadingItems'

const MAX_NUMBER_OF_MOMENTS_TO_TARGET = 500

const actionOptions = [
  { id: 'Target', label: 'Target' },
  { id: 'Monitor', label: 'Monitor' },
  { id: 'Block', label: 'Block' },
  { id: 'No Action', label: 'No Action' }
]

interface IProps {
  getSelectedBoardNames: Function
  selectedTabIndex: number
  setSelectedTabIndex: Function
  setShowSlidingModal: Function
  setShowFromSlidingModal: Function
  moments: ClusterType[]
  fetchNextPage: () => void
  isFetchingNextPage: boolean
  isMomentsError: boolean
  handleSelectMoment: (moment: SelectedClusterType) => void
  momentsIsLoading: boolean
  setSortBy: (sortBy: MomentSortBy) => void
  handleSelectAllOnPage: (checked: boolean) => void
  selectAllMoments: () => void
  allMomentsOnPageAreSelected: boolean
  allMomentsAreSelected: boolean
  setAllMomentsAreSelected: (selected: boolean) => void
  allMomentsIsFetching: boolean
  allMoments: SelectedClusterType[]
  selectedMomentIds: number[]
  postTrendRecActionFeedback: (feedback: any) => void
  postTrendKeywordsFeedback: (feedback: any) => void
  aylienNewsOptions: any[]
  aylienIndustriesOptions: any[]
  setAylienNews: (news: string[]) => void
  setAylienIndustries: (industries: string[]) => void
  aylienIndustries: string[]
  aylienNews: string[]
  totalResultCount: number
  hasNextPage: boolean
  requestedPageSize: number
  selectedMoments: SelectedClusterType[]
  resetSelectedMoments: any
  handleSelectedMomentsTarget: (brandProfileId: number | undefined, targetList: Map<number, TargetListType>) => void
  getDefaultSelectedMoments: any
  setSelectedMoments: (moments: ClusterType[]) => void
  invalidateMomentsQuery: () => void
  invalidateAylienTaxonomiesQuery: () => void
  renderAlert: () => React.ReactNode
  actions: string[]
  setActions: (actions: string[]) => void
  activationStatus: string[]
  setActivationStatus: (activationStatus: string[]) => void
}

export default function MomentsTab({
  getSelectedBoardNames,
  selectedTabIndex,
  setSelectedTabIndex,
  setShowSlidingModal,
  setShowFromSlidingModal,
  moments,
  fetchNextPage,
  isFetchingNextPage,
  isMomentsError,
  handleSelectMoment,
  momentsIsLoading,
  setSortBy,
  handleSelectAllOnPage,
  selectAllMoments,
  allMomentsOnPageAreSelected,
  allMomentsAreSelected,
  setAllMomentsAreSelected,
  allMomentsIsFetching,
  allMoments,
  selectedMomentIds,
  postTrendRecActionFeedback,
  postTrendKeywordsFeedback,
  aylienNewsOptions,
  aylienIndustriesOptions,
  setAylienNews,
  setAylienIndustries,
  aylienIndustries,
  aylienNews,
  totalResultCount,
  hasNextPage,
  requestedPageSize,
  selectedMoments,
  resetSelectedMoments,
  handleSelectedMomentsTarget,
  getDefaultSelectedMoments,
  setSelectedMoments,
  invalidateMomentsQuery,
  invalidateAylienTaxonomiesQuery,
  renderAlert,
  actions,
  setActions,
  activationStatus,
  setActivationStatus
}: IProps) {
  const updateContext = useUnleashContext()
  updateContext({ userId: localStorage.getItem('email')?.toString() })

  const speedImprovements = useFlag('enable_speed_improvements_features')

  const { subscriptionQuota, perms, products, userPermissions, userProducts } = useUser()
  let quotaIsAllowed = false
  if (subscriptionQuota.has(products.BOARDS)) {
    const quota = subscriptionQuota.get(products.BOARDS)
    quotaIsAllowed = quota.isAllowed
  }
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const boardsObj = useBoards()

  const [downloadingTrends, setDownloadingTrends] = React.useState(false)
  const [showTooManyTrendsError, setShowTooManyTrendsError] = React.useState(false)

  const [isViewingTargetModal, setIsViewingTargetModal] = React.useState(false)
  const [isViewingBlockModal, setIsViewingBlockModal] = React.useState(false)
  const [isViewingMoment, setIsViewingMoment] = React.useState(false)

  const [tryingToBlockWithTarget, setTryingToBlockWithTarget] = React.useState(false)
  const [tryingToBlockWithMonitor, setTryingToBlockWithMonitor] = React.useState(false)
  const [tryingToTargetWithBlock, setTryingToTargetWithBlock] = React.useState(false)
  const [tryingToTargetWithMonitor, setTryingToTargetWithMonitor] = React.useState(false)

  const [isTargeting, setIsTargeting] = React.useState(false)
  const [showTargetSuccess, setShowTargetSuccess] = React.useState(false)
  const [targetError, setTargetError] = React.useState<string>('')

  const [blockingPlacements, setBlockingPlacements] = React.useState<boolean>(false)
  const [showBlockPlacementsSuccess, setShowBlockPlacementsSuccess] = React.useState<boolean>(false)
  const [blockPlacementsError, setBlockPlacementsError] = React.useState<string>('')

  const [blockingKeywords, setBlockingKeywords] = React.useState<boolean>(false)
  const [blockKeywordsError, setBlockKeywordsError] = React.useState<string>('')
  const [showBlockKeywordsSuccess, setShowBlockKeywordsSuccess] = React.useState<boolean>(false)
  const { boardsQuotaTally } = useBoards()

  const isNewActivationManagerEnabled = useFlag(`enable_new_activation_mgr_${config.environment}`)

  const getLast24Hrs = (): [Date, Date] => {
    const now = new Date()
    const yesterday = new Date(now)
    yesterday.setTime(yesterday.getTime() - 24 * 60 * 60 * 1000)
    return [yesterday, now]
  }

  const [boardsDateRange, setBoardsDateRange] = useSessionStorage<[Date, Date]>('boardsDateRange', getLast24Hrs())

  const startingRange = boardsDateRange.map((dateVal) => new Date(dateVal))

  const DATE_FMT_YYYY_MM_DD = 'YYYY-MM-DD'
  const [startingDateString, setStartingDateString] = React.useState<string>(
    dayjs(startingRange[0]).format(DATE_FMT_YYYY_MM_DD)
  )
  const [endingDateString, setEndingDateString] = React.useState<string>(
    dayjs(startingRange[1]).format(DATE_FMT_YYYY_MM_DD)
  )

  const [startingDateObj, setStartingDateObj] = React.useState<Date>(startingRange[0])
  const [endingDateObj, setEndingDateObj] = React.useState<Date>(startingRange[1])

  React.useEffect(() => {
    const newStartDate = startingDateObj || dayjs(startingDateString, DATE_FMT_YYYY_MM_DD, true).toDate()
    const newEndDate = endingDateObj || dayjs(endingDateString, DATE_FMT_YYYY_MM_DD, true).toDate()

    const isValidDate = (date: any) => dayjs(date, DATE_FMT_YYYY_MM_DD, true).isValid()

    if (isValidDate(newStartDate) && isValidDate(newEndDate)) {
      setBoardsDateRange([newStartDate, newEndDate])
    }
  }, [startingDateString, endingDateString])

  const [isDateOutOfBounds, setIsDateOutOfBounds] = React.useState<boolean>(false)

  const [noIOsLinkedToBrandProfile, setNoIOsLinkedToBrandProfile] = React.useState(false)

  const [selectedIndustryCategoryIds, setSelectedIndustryCategoryIds] = React.useState<number[]>([])
  const [selectedNewsCategoryIds, setSelectedNewsCategoryIds] = React.useState<number[]>([])

  const { data: brandProfiles, isLoading: brandProfilesIsLoading } = useBrandProfiles({
    submittedOnly: true
  })

  const {
    brandProfileId,
    boardIds,
    startDate = startingDateString,
    endDate = endingDateString,
    sortBy = 'ranking',
    searchTerm = '',
    selectedMomentId
  } = useSearch<MyLocationGenerics>()

  const handleTarget = async (args: TargetListProps) => {
    setShowTargetSuccess(false)
    setTargetError('')
    setIsTargeting(true)

    try {
      const res = await api.moments.createTargetList(args)

      if (res.status === 200) {
        setShowTargetSuccess(true)
        await queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
        resetSelectedMoments(brandProfileId)
      }
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data === 'No Cids found') {
          setTargetError('Error: No Cids found')
          setShowTargetSuccess(false)
          return
        }
      }

      setTargetError('An error occurred targeting these moments.')
      logError(err, { info: 'error targeting on aylien boards' })
    } finally {
      setIsTargeting(false)
    }
  }

  const handleTargetV2 = async (payload: TargetListPropsV2) => {
    setShowTargetSuccess(false)
    setTargetError('')
    setIsTargeting(true)

    try {
      const res = await api.moments.createTargetListV2(payload)
      if (res.status === 200) {
        setShowTargetSuccess(true)
        await queryClient.invalidateQueries(activationsRqKeys.todoActivations({ brandProfileId }))
        resetSelectedMoments(brandProfileId)
      }
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data === 'No Cids found') {
          setTargetError('Error: No Cids found')
          setShowTargetSuccess(false)
          return
        }
      }

      setTargetError('An error occurred targeting these moments.')
      logError(err, { info: 'error targeting on aylien boards' })
    } finally {
      setIsTargeting(false)
    }
  }

  const handleBlock = async ({
    keywordClusters,
    placementClusters,
    ioIds
  }: {
    keywordClusters: ClusterActivationType[]
    placementClusters: ClusterActivationType[]
    ioIds: number[]
  }) => {
    if (brandProfileId === null || brandProfileId === undefined) {
      return
    }
    setBlockPlacementsError('')
    setBlockKeywordsError('')
    setShowBlockPlacementsSuccess(false)
    setShowBlockKeywordsSuccess(false)

    if (keywordClusters.length > 0) {
      setBlockingKeywords(true)
      try {
        const keywordResult = await api.moments.createKeywordsBlockList({
          brandProfileId,
          clusters: keywordClusters,
          ioIds
        })
        if (keywordResult?.status === 200 && keywordResult.data === 'No Cids found') {
          setBlockKeywordsError('Error: No Cids found')
          setShowBlockKeywordsSuccess(false)
          return
        }

        if (keywordResult?.status === 200) {
          setBlockingKeywords(false)
          setShowBlockKeywordsSuccess(true)
          setBlockKeywordsError('')
        }
      } catch (err) {
        logError(err, { info: 'error blocking keywords moments list' })
        setBlockKeywordsError('An unknown error occurred blocking keywords.')
        setShowBlockKeywordsSuccess(false)
      } finally {
        resetSelectedMoments(brandProfileId)
        setBlockingKeywords(false)
      }
    }

    if (placementClusters.length > 0) {
      setBlockingPlacements(true)

      try {
        const placementResult = await api.moments.createPlacementsBlockList({
          brandProfileId,
          clusters: placementClusters,
          ioIds
        })

        if (placementResult?.status === 200 && placementResult.data === 'No Cids found') {
          setBlockPlacementsError('Error: No Cids found')
          setShowBlockPlacementsSuccess(false)
          return
        }

        if (placementResult?.status === 200) {
          setShowBlockPlacementsSuccess(true)
          setBlockPlacementsError('')
        }
      } catch (err) {
        logError(err, { info: 'error blocking keywords moments list' })
        setBlockPlacementsError('An unknown error occurred blocking videos.')
        setShowBlockPlacementsSuccess(false)
      } finally {
        resetSelectedMoments(brandProfileId)
        setBlockingPlacements(false)
      }
    }
  }

  const adjustOutOfBoundsDates = (
    startDate: string | undefined,
    endDate: string | undefined
  ): { dateCheckStartDate: string; dateCheckEndDate: string } => {
    const now = dayjs()
    const sixtyDaysAgo = dayjs().subtract(61, 'day')
    const yesterday = dayjs().subtract(1, 'day')

    const parsedStartDate = startDate ? dayjs(startDate, DATE_FMT_YYYY_MM_DD, true).toDate() : new Date()
    const parsedEndDate = endDate ? dayjs(endDate, DATE_FMT_YYYY_MM_DD, true).toDate() : new Date()

    let adjustedStartDate = parsedStartDate
    let adjustedEndDate = parsedEndDate

    if (dayjs(parsedStartDate).isBefore(sixtyDaysAgo) || dayjs(parsedStartDate).isAfter(now)) {
      adjustedStartDate = yesterday.toDate()
    }

    if (dayjs(parsedEndDate).isAfter(now) || dayjs(parsedEndDate).isBefore(sixtyDaysAgo)) {
      adjustedEndDate = now.toDate()
    }

    return {
      dateCheckStartDate: dayjs(adjustedStartDate).format(DATE_FMT_YYYY_MM_DD),
      dateCheckEndDate: dayjs(adjustedEndDate).format(DATE_FMT_YYYY_MM_DD)
    }
  }

  const { dateCheckStartDate: dateCheckedStartDate, dateCheckEndDate: dateCheckedEndDate } = adjustOutOfBoundsDates(
    startDate as string,
    endDate as string
  )

  useEffect(() => {
    const formattedStartDate = startDate ? dayjs(startDate, DATE_FMT_YYYY_MM_DD, true).toDate() : new Date()
    const formattedEndDate = endDate ? dayjs(endDate, DATE_FMT_YYYY_MM_DD, true).toDate() : new Date()

    if (
      dateCheckedStartDate !== dayjs(formattedStartDate).format(DATE_FMT_YYYY_MM_DD) ||
      dateCheckedEndDate !== dayjs(formattedEndDate).format(DATE_FMT_YYYY_MM_DD)
    ) {
      setIsDateOutOfBounds(true)
    } else {
      setIsDateOutOfBounds(false)
    }
  }, [startDate, endDate])

  useEffect(() => {
    // When the URL parameters change, assign the <Date> state variable that controls calendar display
    // Separate string state vars are defined as string for API
    const [startYear, startMonth, startDay] = startDate.split('-').map(Number)
    const [endYear, endMonth, endDay] = endDate.split('-').map(Number)

    setStartingDateObj(new Date(startYear, startMonth - 1, startDay))
    setEndingDateObj(new Date(endYear, endMonth - 1, endDay))

    setStartingDateString(`${startYear}-${startMonth}-${startDay}`)
    setEndingDateString(`${endYear}-${endMonth}-${endDay}`)
  }, [startDate, endDate])

  const iosQuery = useBrandProfileInsertionOrderIds(brandProfileId)

  const handleSearch = debounce((text: string) => {
    navigate({
      search: (prev) => {
        return {
          ...prev,
          searchTerm: text
        }
      }
    })
  }, 500)

  const handleDateRangeSelect = (selectedRange: any) => {
    setAllMomentsAreSelected(false)

    const startDate = formatDateyyyyMMdd(selectedRange[0])
    const endDate = formatDateyyyyMMdd(selectedRange[1])

    setStartingDateString(startDate)
    setEndingDateString(endDate)

    setStartingDateObj(selectedRange[0])
    setEndingDateObj(selectedRange[1])

    navigate({
      search: (old) => {
        return {
          ...old,
          startDate: startDate,
          endDate: endDate
        }
      }
    })
    invalidateMomentsQuery()
    invalidateAylienTaxonomiesQuery()
  }

  const selectedMoment = React.useMemo(() => {
    if (!moments) return undefined
    if (!selectedMomentId) return undefined
    const momentSelected = queryClient.getQueryData(
      MomentViewRqKeys.momentInViewSlideOver(brandProfileId, selectedMomentId)
    )
    return moments?.filter((moment: ClusterType) => moment.clusterId === selectedMomentId)[0] || momentSelected
  }, [selectedMomentId, moments])

  const [allMomentsSelected, setAllMomentsSelected] = React.useState(false)

  const handleSelectAll = () => {
    selectAllMoments()
  }

  useEffect(() => {
    setAllMomentsAreSelected(false)
  }, [])

  useEffect(() => {
    setAllMomentsSelected(selectedMomentIds.length === allMoments.length && allMoments.length !== 0)
  }, [selectedMoments])

  const handleDownloadTrendsByFilter = async () => {
    if (brandProfileId === undefined || boardIds === undefined || actions === undefined) return
    setDownloadingTrends(true)
    if (totalResultCount > 1000) {
      setDownloadingTrends(false)
      setShowTooManyTrendsError(true)
      return
    }
    const args = {
      brandProfileId,
      boardIds,
      startDate: startingDateString,
      endDate: endingDateString,
      actions,
      sortBy,
      searchTerm,
      aylienNews,
      aylienIndustries,
      activationStatus
    }
    try {
      await api.moments.downloadByFilter(args)
    } catch (err) {
      toast.error('There was an error downloading your moments.')
    } finally {
      setDownloadingTrends(false)
    }
  }

  const handleDownloadTrends = async () => {
    if (brandProfileId === undefined) return
    setDownloadingTrends(true)
    if (selectedMomentIds.length > 1000) {
      setDownloadingTrends(false)
      setShowTooManyTrendsError(true)
      return
    }
    const args = {
      brandProfileId,
      clusterIds: selectedMomentIds
    }
    try {
      await api.moments.download(args)
    } catch (err) {
      toast.error('There was an error downloading your moments.')
      setDownloadingTrends(false)
    }

    setDownloadingTrends(false)
  }

  const hasMonitor = React.useMemo(() => {
    if (!moments) return false
    for (const trend of moments) {
      if (trend.action === 'monitor' && selectedMomentIds.includes(trend.clusterId)) {
        return true
      }
    }
    return false
  }, [moments, selectedMomentIds])

  const hasTarget = React.useMemo(() => {
    if (!moments) return false
    for (const trend of moments) {
      if (trend.action === 'target' && selectedMomentIds.includes(trend.clusterId)) {
        return true
      }
    }
    return false
  }, [moments, selectedMomentIds])

  const hasBlock = React.useMemo(() => {
    if (!moments) return false
    for (const trend of moments) {
      if (trend.action === 'block' && selectedMomentIds.includes(trend.clusterId)) {
        return true
      }
    }
    return false
  }, [moments, selectedMomentIds])

  if (brandProfilesIsLoading) {
    return null
  }

  if (brandProfiles?.length === 0) {
    return <NoBrandProfiles />
  }

  if (boardsObj.boardsIsLoading) {
    return <LoadingPage message="Fetching boards" />
  }

  if (
    (!boardsObj.boards ||
      // only navigate if you're on the brand profile that has 0 boards
      // If the account is maxed out on their board quota,
      // and has multiple brand profiles, they should
      // still be able to see the boards of the brand profile that has them
      (boardsObj.boards.length === 0 && boardsQuotaTally?.isMaxedOut)) &&
    userCanPermissionProductQuota({
      requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
      requiredProductValue: products.BOARDS,
      userPermissions: userPermissions,
      userProducts: userProducts,
      checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
    })
  ) {
    return <Navigate to={PATH_MOMENTS_V2_SETTINGS_REDESIGN} />
  }

  // CheckTreePicker doesn't correctly focus on clicked items. These functions fix the issue
  const focusOnClickedItem = (event: React.SyntheticEvent | undefined): void => {
    const target = event?.target as HTMLElement | null
    const itemLabel = target?.closest('.rs-check-tree-node')?.querySelector<HTMLElement>('.rs-check-tree-node-label')

    if (itemLabel) {
      itemLabel.focus()
    }
  }

  const handleActionsDropdownChange: CheckTreePickerProps['onChange'] = (actions, event) => {
    setAllMomentsAreSelected(false)
    actions.length > 0 && setActions(actions)
    focusOnClickedItem(event)
  }

  const handleStatusDropdownChange: CheckTreePickerProps['onChange'] = (newValue, event) => {
    setAllMomentsAreSelected(false)
    setActivationStatus(newValue)
    focusOnClickedItem(event)
  }

  const findTaxonomyCode = (items: any[], id: number): string => {
    for (const item of items) {
      if (item.id === id) {
        return item.taxonomyCode
      }
      if (item.children) {
        const result = findTaxonomyCode(item.children, id)
        if (result) {
          return result
        }
      }
    }

    return ''
  }

  const getTaxonomyCodes = (items: any[], ids: number[]): string[] => {
    return ids.map((id) => findTaxonomyCode(items, id)).filter((code) => code !== '')
  }

  const handleNewsDropdownChange: CheckTreePickerProps['onChange'] = (values, event) => {
    setAllMomentsAreSelected(false)
    setSelectedNewsCategoryIds(values)
    setAylienNews(getTaxonomyCodes(aylienNewsOptions, values))
    focusOnClickedItem(event)
  }

  const handleIndustriesDropdownChange: CheckTreePickerProps['onChange'] = (values, event) => {
    setAllMomentsAreSelected(false)
    setSelectedIndustryCategoryIds(values)
    setAylienIndustries(getTaxonomyCodes(aylienIndustriesOptions, values))
    focusOnClickedItem(event)
  }

  const isTargetDisabled = (): boolean | undefined => {
    return (
      selectedMomentIds.length > MAX_NUMBER_OF_MOMENTS_TO_TARGET ||
      (selectedMomentIds.length <= 0 &&
        ((boardIds && boardIds?.length > 0) || totalResultCount === 0 || momentsIsLoading))
    )
  }

  const getTargetTooltipContent = (): React.ReactNode => {
    return selectedMomentIds.length > MAX_NUMBER_OF_MOMENTS_TO_TARGET
      ? `For the moment target is disabled when more than ${MAX_NUMBER_OF_MOMENTS_TO_TARGET} are selected`
      : 'To target moments, please select a moment first'
  }

  return (
    <>
      <div className="header-aylien">
        <div className="w-full">
          {/*<Filters*/}
          <div
            data-testid="anticipation-boards-filters-panel"
            className="flex items-center justify-between py-4 bg-gray-100 w-100"
          >
            <div className="flex gap-4 px-6">
              <div data-testid="anticipation-boards-filters-panel-actionDropdown">
                <CheckTreePicker
                  id="actionDropdown"
                  style={{ width: 162 }}
                  preventOverflow
                  data={actionOptions}
                  labelKey="label"
                  valueKey="id"
                  onChange={handleActionsDropdownChange}
                  value={actions}
                  cleanable={false}
                />
              </div>
              {
                <div data-testid="anticipation-boards-filters-panel-activationStatusDropdown">
                  <CheckTreePicker
                    id="activationStatusDropdown"
                    style={{ width: 162 }}
                    preventOverflow
                    data={activationStatusOptions}
                    labelKey="label"
                    valueKey="id"
                    placeholder="Activation Status"
                    onChange={handleStatusDropdownChange}
                    cleanable={false}
                    value={activationStatus}
                  />
                </div>
              }
              <div data-testid="anticipation-boards-filters-panel-dateDropdown">
                <DateRangeSection
                  onSelectDateRange={handleDateRangeSelect}
                  onCalendarStateChange={() => {}}
                  rangeValue={[startingDateObj, endingDateObj]}
                />
              </div>
              <div data-testid="anticipation-boards-filters-panel-news-filter">
                <CheckTreePicker
                  id="pendo-aylien-news-filter"
                  style={{ width: 162 }}
                  menuStyle={{ maxWidth: '90vw' }}
                  preventOverflow
                  data={aylienNewsOptions}
                  labelKey="name"
                  valueKey="id"
                  cascade={false}
                  placeholder="News"
                  onChange={handleNewsDropdownChange}
                  value={selectedNewsCategoryIds}
                />
              </div>
              <div data-testid="anticipation-boards-filters-panel-industries-filter">
                <CheckTreePicker
                  id="pendo-aylien-industry-filter"
                  style={{ width: 162 }}
                  menuStyle={{ maxWidth: '90vw' }}
                  preventOverflow
                  data={aylienIndustriesOptions}
                  labelKey="name"
                  valueKey="id"
                  cascade={false}
                  placeholder="Industries"
                  onChange={handleIndustriesDropdownChange}
                  value={selectedIndustryCategoryIds}
                />
              </div>
            </div>

            <div
              data-testid="anticipation-boards-search-box"
              className="px-6"
            >
              <SightlyInput
                id="searchMoments"
                value={searchTerm}
                placeholder="Search moments"
                onChange={(val) => handleSearch(val)}
                hasSearchIcon
                width={240}
              />
            </div>
          </div>
          {/*<Actions*/}
          <div
            data-testid="anticipation-boards-action-buttons"
            className="flex items-center justify-start w-full gap-4 px-6 my-4"
          >
            <div
              data-testid="anticipation-boards-action-buttons-selectAllCheckbox"
              className="mr-2"
            >
              <SightlyCheckbox
                data-testid="select-all-moments-checkbox"
                id="selectAllCheckbox"
                indeterminate={selectedMomentIds.length > 0 && !allMomentsOnPageAreSelected}
                checked={allMomentsOnPageAreSelected}
                handleChange={(checked: boolean) => {
                  handleSelectAllOnPage(checked)
                }}
                label={
                  selectedMomentIds.length > 0 ? (
                    <div className="text-xs text-sightly-slate">{selectedMomentIds.length} item(s) selected</div>
                  ) : (
                    ''
                  )
                }
              />
            </div>

            {userCanPermissionProductQuota({
              requiredPermissionValue: perms.TRENDS_DOWNLOAD,
              requiredProductValue: products.BOARDS,
              userPermissions: userPermissions,
              userProducts: userProducts,
              checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
            }) && (
              <div data-testid="anticipation-boards-action-buttons-download">
                <ActionsMenu
                  buttonId="download"
                  buttonText="Download"
                  buttonIcon={
                    <ArrowDownTrayIcon
                      className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                      aria-hidden="true"
                    />
                  }
                  options={[
                    {
                      disabled: selectedMomentIds.length < 1 || totalResultCount === 0 || momentsIsLoading,
                      name: 'Download selected moments',
                      description: 'Download all of the selected moments and their meta data',
                      onClick: handleDownloadTrends,
                      icon: (
                        <ArrowDownTrayIcon
                          className="flex-shrink-0 w-6 h-6 text-indigo-600"
                          aria-hidden="true"
                        />
                      )
                    },
                    {
                      disabled: totalResultCount < 1 || momentsIsLoading,
                      name: 'Download all moments',
                      description: 'Download all of the moments and their meta data, according to your filters',
                      onClick: handleDownloadTrendsByFilter,
                      icon: (
                        <ArrowDownTrayIcon
                          className="flex-shrink-0 w-6 h-6 text-indigo-600"
                          aria-hidden="true"
                        />
                      )
                    }
                  ]}
                  selectorClass="pendo-download-button-selector"
                />
              </div>
            )}
            {userCanPermissionProductQuota({
              requiredPermissionValue: perms.TRENDS_TARGET,
              requiredProductValue: products.BOARDS,
              userPermissions: userPermissions,
              userProducts: userProducts,
              checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
            }) && (
              <>
                <Tooltip
                  content="To block moments, please select a moment first"
                  disabled={selectedMomentIds.length > 0}
                >
                  <div data-testid="anticipation-boards-action-buttons-block">
                    <SightlyButton
                      id="blockButton"
                      disabled={
                        selectedMomentIds.length <= 0 &&
                        ((boardIds && boardIds?.length > 0) || totalResultCount === 0 || momentsIsLoading)
                      }
                      handleClick={() => {
                        if (!!(iosQuery.data && iosQuery.data.length < 1)) {
                          setNoIOsLinkedToBrandProfile(true)
                        } else {
                          hasTarget
                            ? setTryingToBlockWithTarget(true)
                            : hasMonitor
                            ? setTryingToBlockWithMonitor(true)
                            : setIsViewingBlockModal(true)
                        }
                      }}
                      text="Block"
                      type="secondaryblack"
                      icon={
                        <NoSymbolIcon
                          className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                          aria-hidden="true"
                        />
                      }
                    />
                  </div>
                </Tooltip>

                <Tooltip
                  content={getTargetTooltipContent()}
                  disabled={!isTargetDisabled()}
                >
                  <div data-testid="anticipation-boards-action-buttons-target">
                    <SightlyButton
                      id="targetButton"
                      disabled={isTargetDisabled()}
                      handleClick={() => {
                        if (!!(iosQuery.data && iosQuery.data.length < 1)) {
                          setNoIOsLinkedToBrandProfile(true)
                        } else {
                          hasMonitor
                            ? setTryingToTargetWithMonitor(true)
                            : hasBlock
                            ? setTryingToTargetWithBlock(true)
                            : setIsViewingTargetModal(true)
                        }
                      }}
                      text="Target"
                      type="secondaryblack"
                      icon={
                        <BoltIcon
                          className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                          aria-hidden="true"
                        />
                      }
                    />
                  </div>
                </Tooltip>
              </>
            )}

            <Tooltip
              content={
                allMomentsAreSelected
                  ? 'To analyze moments, please select only on screen moments'
                  : 'To analyze moments, please select a moment first'
              }
              disabled={!allMomentsAreSelected && selectedMomentIds.length > 0}
            >
              <div data-testid="anticipation-boards-action-buttons-ai-analyze">
                <SightlyButton
                  id="aiAnalyzeButton"
                  handleClick={() => {
                    setShowFromSlidingModal(MomentJustificationOrigin.Analyze)
                    setShowSlidingModal(true)
                  }}
                  text="Analyze"
                  disabled={selectedMomentIds.length != 1}
                  type="gradient-ai"
                  icon={
                    <img
                      data-testid={'ai-analyze-image'}
                      src={sparklesAISvg}
                      className="-ml-0.5 mr-2 h-4 w-4 text-grey-900"
                    />
                  }
                />
              </div>
            </Tooltip>

            <div className="w-[1px] h-9 bg-[#E5E7EB]" />

            <div data-testid="anticipation-boards-action-buttons-ai-history">
              <SightlyButton
                id="aiHistoryButton"
                handleClick={() => {
                  setShowFromSlidingModal(MomentJustificationOrigin.History)
                  setShowSlidingModal(true)
                }}
                text=""
                type="gradient-ai"
                icon={
                  <img
                    data-testid={'ai-history-image'}
                    src={clockSvg}
                    className="w-5 h-5 text-grey-900"
                  />
                }
              />
            </div>

            <div className="flex ml-auto">
              {!momentsIsLoading && moments && moments.length > 0 && (
                <div
                  data-testid="showing-moments-count"
                  className="flex flex-col justify-center mr-5 text-xs text-sightly-slate"
                >
                  {`Showing ${moments.length} of ${totalResultCount}`}
                </div>
              )}
              <div>
                <SortBy
                  selected={sortBy}
                  onChange={(val: MomentSortBy) => setSortBy(val)}
                  buttonClass="pendo-moments-sort-by-button"
                />
              </div>
            </div>
          </div>

          <ActionMessages
            downloadingTrends={downloadingTrends}
            noIOsLinkedToBrandProfile={noIOsLinkedToBrandProfile}
            setNoIOsLinkedToBrandProfile={setNoIOsLinkedToBrandProfile}
            brandProfileId={brandProfileId}
            showTargetSuccess={showTargetSuccess}
            setShowTargetSuccess={setShowTargetSuccess}
            isTargeting={isTargeting}
            targetError={targetError}
            setTargetError={setTargetError}
            showBlockKeywordsSuccess={showBlockKeywordsSuccess}
            setShowBlockKeywordsSuccess={setShowBlockKeywordsSuccess}
            showBlockPlacementsSuccess={showBlockPlacementsSuccess}
            setShowBlockPlacementsSuccess={setShowBlockPlacementsSuccess}
            blockingKeywords={blockingKeywords}
            blockingPlacements={blockingPlacements}
            blockPlacementsError={blockPlacementsError}
            setBlockPlacementsError={setBlockPlacementsError}
            blockKeywordsError={blockKeywordsError}
            setBlockKeywordsError={setBlockKeywordsError}
          />
        </div>
      </div>

      <div
        id="moments-scroll-container"
        className={`px-4 pt-4 pb-8 transition-colors duration-500 ${
          momentsIsLoading ? 'bg-white' : 'bg-gray-100'
        } main`}
      >
        {isMomentsError ? (
          <InfoPage message="There was an unknown error fetching Moments. Please refresh the page to try again. If the problem persists, please contact support." />
        ) : isDateOutOfBounds ? (
          <InfoPage message={'Please select a date range within past the 60 days'} />
        ) : !boardsObj.boards || boardsObj.boards.length === 0 ? (
          <InfoPage message="We currently don’t have any boards created and processed for this Brand Profile." />
        ) : boardIds?.length === 0 &&
          userCanPermissionProductQuota({
            requiredPermissionValue: perms.AYLIEN_BOARD_CREATE,
            requiredProductValue: products.BOARDS,
            userPermissions: userPermissions,
            userProducts: userProducts,
            checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK
          }) ? (
          <InfoPage message="Please select a board" />
        ) : momentsIsLoading ? (
          <LoadingItems />
        ) : !momentsIsLoading && moments && moments.length < 1 ? (
          <InfoPage message="There are no moments for your filters" />
        ) : (
          <div className="w-full">
            {allMomentsOnPageAreSelected &&
              !allMomentsSelected &&
              selectedMomentIds.length !== totalResultCount &&
              totalResultCount > requestedPageSize && (
                <SelectAllMomentsPanel
                  allMomentsIsFetching={allMomentsIsFetching}
                  selectedMomentsAmount={selectedMomentIds.length}
                  allMomentsAmount={totalResultCount}
                  handleSelectAll={handleSelectAll}
                />
              )}

            {speedImprovements ? (
              <>
                {/* Inline style needed because InfiniteScroll will half cover GARM flag SVG */}
                <style>
                  {`
                         .infinite-scroll-component {
                             overflow: visible !important;
                         }
                         `}
                </style>
                <InfiniteScroll
                  dataLength={moments.length}
                  next={fetchNextPage}
                  hasMore={hasNextPage}
                  loader={<LoadingPage message={'Loading moments'} />}
                  scrollThreshold={0.5}
                  endMessage={
                    <p
                      data-testid="end-of-moments-message"
                      className="text-center py-4"
                    >
                      You have seen it all
                    </p>
                  }
                  scrollableTarget="moments-scroll-container"
                >
                  {moments?.map((moment: ClusterType) => {
                    return (
                      <MomentCard
                        key={moment.clusterId}
                        trendingMoments={boardsObj.boards?.find(
                          (board: Board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS
                        )}
                        momentsIsLoading={momentsIsLoading}
                        checked={selectedMomentIds.includes(moment.clusterId)}
                        handleSelectMoment={handleSelectMoment}
                        brandProfileId={brandProfileId}
                        moment={moment}
                        handleTrendNameClick={(moment: ClusterType) => {
                          navigate({
                            search: (search) => ({
                              ...search,
                              selectedMomentId: moment.clusterId
                            })
                          })
                          setIsViewingMoment(true)
                        }}
                      />
                    )
                  })}
                </InfiniteScroll>
              </>
            ) : (
              <>
                {moments?.map((moment: ClusterType) => {
                  return (
                    <MomentCard
                      key={moment.clusterId}
                      trendingMoments={boardsObj.boards?.find(
                        (board: Board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS
                      )}
                      momentsIsLoading={momentsIsLoading}
                      checked={selectedMomentIds.includes(moment.clusterId)}
                      handleSelectMoment={handleSelectMoment}
                      brandProfileId={brandProfileId}
                      moment={moment}
                      handleTrendNameClick={(moment: ClusterType) => {
                        navigate({
                          search: (search) => ({
                            ...search,
                            selectedMomentId: moment.clusterId
                          })
                        })
                        setIsViewingMoment(true)
                      }}
                    />
                  )
                })}
                {hasNextPage && (
                  <div className="flex justify-center w-full py-4">
                    <SightlyButton
                      datatestid="load-more-moments-button"
                      block
                      id="loadmorebutton"
                      text="Load More"
                      handleClick={fetchNextPage}
                      loading={isFetchingNextPage}
                      disabled={isFetchingNextPage}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {renderAlert()}

      <AylienBoardPortalModal
        showTooManyTrendsError={showTooManyTrendsError}
        setShowTooManyTrendsError={setShowTooManyTrendsError}
      />

      <BlockSlideOver
        open={isViewingBlockModal}
        setOpen={setIsViewingBlockModal}
        selectedMoments={selectedMoments}
        handleBlock={handleBlock}
        handleSelectMoment={handleSelectMoment}
      />

      {isNewActivationManagerEnabled ? (
        isViewingTargetModal && (
          <TargetMoment
            open={isViewingTargetModal}
            trendingMoments={boardsObj.boards?.find(
              (board: Board) => board.defaultType === GENERAL_BOARD_DEFAULT_TYPES.TRENDING_MOMENTS
            )}
            setOpen={setIsViewingTargetModal}
            selectedMoments={selectedMoments}
            handleTarget={handleTargetV2}
            handleSelectMoment={handleSelectMoment}
          />
        )
      ) : (
        <TargetSlideOver
          open={isViewingTargetModal}
          setOpen={setIsViewingTargetModal}
          selectedMoments={selectedMoments}
          handleTarget={handleTarget}
          handleSelectMoment={handleSelectMoment}
        />
      )}

      {brandProfileId && selectedMoment && (
        <MomentSlideOver
          show={isViewingMoment}
          close={setIsViewingMoment}
          moment={selectedMoment}
          brandProfileId={brandProfileId}
          postTrendRecActionFeedback={postTrendRecActionFeedback}
          postTrendKeywordsFeedback={postTrendKeywordsFeedback}
          invalidateMomentsQuery={invalidateMomentsQuery}
        />
      )}

      <ConfirmationModals
        tryingToBlockWithTarget={tryingToBlockWithTarget}
        setTryingToBlockWithTarget={setTryingToBlockWithTarget}
        setIsViewingBlockModal={setIsViewingBlockModal}
        tryingToBlockWithMonitor={tryingToBlockWithMonitor}
        setTryingToBlockWithMonitor={setTryingToBlockWithMonitor}
        tryingToTargetWithBlock={tryingToTargetWithBlock}
        setTryingToTargetWithBlock={setTryingToTargetWithBlock}
        setIsViewingTargetModal={setIsViewingTargetModal}
        tryingToTargetWithMonitor={tryingToTargetWithMonitor}
        setTryingToTargetWithMonitor={setTryingToTargetWithMonitor}
      />
    </>
  )
}
