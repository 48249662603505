import { userAccountAxios } from "@/axiosInstances";
import { SegmentPaginatedResponseSchema, SegmentSchema } from "../schemas/SegmentSchema";

type GetSegmentsQueryKey = [string, number | undefined, number | undefined, number | undefined, string | undefined, string | undefined];

export const segmentsController = {
    getSegments: async ({ queryKey }: { queryKey: GetSegmentsQueryKey }) => {
        try {
            const [ , accountId, page, pageSize, orderBy, sortBy] = queryKey;

            let url = `/v1/smart-lists/segments?accountId=${accountId}&page=${page}&pageSize=${pageSize}`;

            if (orderBy) url += `&orderBy=${orderBy}`
            if (sortBy) url += `&sort=${sortBy}`

            const { data } = await userAccountAxios.get(url);

            const resultData = SegmentPaginatedResponseSchema.parse(data);

            return resultData;
        } catch (error) {
            console.error(error)
            throw error
        }
    },
    getSegmentById: async ( id :number ) => {
        try {
            const url = `/v1/smart-lists/segments/${id}`

            const { data } = await userAccountAxios.get(url)

            const resultData = SegmentSchema.parse(data)

            return resultData

        } catch (error) {
            console.error(error)
            throw error
        }
    },
    handleRemoveSegment: async (id: number) => {
        try {
            const url = `/v1/smart-lists/segments/${id}`

            const response = await userAccountAxios.delete(url)

            if (response.status == 204) {
                return true;
            }
            return false;
        } catch (error) {
            console.error(error)
            throw error
        }
    },



}

