import { Navigate } from '@tanstack/react-location';
import React from 'react';
import { LoadingPage } from '@/components/LoadingPage';
import useUser from '@/hooks/useUser';
import { UnauthorizedPage } from '@/auth/UnauthorizedPage'
import { routes } from '@/routes';
import {isIframe} from "@/validations";
import {accountHasAccess} from "@/services/checkPaymentStatus";
import {StripePricing} from "@/views/Stripe/pricing/page";
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

const canSeeWithPermissionsAndProducts = (userCanPermissionProductQuota: (permsObj: {
    requiredPermissionValue?: string,
    requiredProductValue?: string,
    userAction?: string,
    userProducts: string[],
    userPermissions: string[],
    checkType: PermissionCheckType
  }) => boolean,
  requiredAccessList: accessListType[],
  userProducts: string[],
  userPermissions: string[]
) => {
  if (!requiredAccessList.length) {
    return true;
  }

  for (const requiredAccess of requiredAccessList) {
    const { requiredPermission, requiredProduct, userAction } = requiredAccess;

    let checkType: PermissionCheckType;

    if (requiredPermission && requiredProduct) {
      checkType = PermissionCheckType.PRODUCT_PERMISSION_CHECK;
    } else if (requiredPermission) {
      checkType = PermissionCheckType.PERMISSION_CHECK;
    } else if (requiredProduct) {
      checkType = PermissionCheckType.PRODUCT_CHECK;
    } else {
      continue;
    }

    const hasAccess = userCanPermissionProductQuota({
      requiredPermissionValue: requiredPermission,
      requiredProductValue: requiredProduct,
      userAction,
      userProducts,
      userPermissions,
      checkType
    });

    if (!hasAccess) {
      return false;
    }
  }

  return true;
}

const canSeeWithAtLeastOnePermissionsAndProducts = (
  userCanPermissionProductQuota: (options: {
    requiredPermissionValue?: string,
    requiredProductValue?: string,
    userAction?: string,
    userProducts: string[],
    userPermissions: string[],
    checkType: PermissionCheckType
  }) => boolean,
  atleastOneAccessList: accessListType[],
  userProducts: string[],
  userPermissions: string[]
) => {
  for (const requiredAccess of atleastOneAccessList) {
    const { requiredPermission, requiredProduct, userAction } = requiredAccess;

    let checkType: PermissionCheckType;

    if (requiredPermission && requiredProduct) {
      checkType = PermissionCheckType.PRODUCT_PERMISSION_CHECK;
    } else if (requiredPermission) {
      checkType = PermissionCheckType.PERMISSION_CHECK;
    } else if (requiredProduct) {
      checkType = PermissionCheckType.PRODUCT_CHECK;
    } else {
      continue;
    }

    const hasAccess = userCanPermissionProductQuota({
      requiredPermissionValue: requiredPermission,
      requiredProductValue: requiredProduct,
      userAction,
      userProducts,
      userPermissions,
      checkType
    });

    if (hasAccess) {
      return true;
    }
  }
  return false;
}

/**
 *
 * @param {Component} component The view or page to render when user meets permissions requirements
 * @param {requiredAccessList[]} requiredPermission, requiredProduct(optional),userAction(optional) The permissions a user must posses to be granted access.
 * @param {atLeastOneAccessList[]} requiredPermission, requiredProduct(optional),userAction(optional) At least one of these permissions must be met to be granted access.
 * A user has permissions through roles.
 * A user has products through their account's package, which is joined to products through package_products
 * @returns needPermissionsPage or error copy
 */

export type accessListType = {
    requiredPermission: string
    requiredProduct?:string
    userAction?: string
}
type ProtectedRoutePropType = {
    requiredAccessList?: accessListType[]
    atleastOneAccessList?: accessListType[]
    component: React.ComponentType<any>
    checkAccountActive?: boolean
}

const ProtectedRoute = ({ component : Component, requiredAccessList=[], atleastOneAccessList=[], checkAccountActive = true}:ProtectedRoutePropType) => {
  const { accounts, user, currentAccount, perms, isLoading, isInvalidUser, products, userProducts, userPermissions } = useUser();

  const socialDashboardEnabled = userCanPermissionProductQuota({
    requiredProductValue: products.KRAFT_SOCIAL_METABASE_DASHBOARD,
    userProducts,
    checkType: PermissionCheckType.PRODUCT_CHECK
  }) || userCanPermissionProductQuota({
    requiredProductValue: products.LULULEMON_SOCIAL_METABASE_DASHBOARD,
    userProducts,
    checkType: PermissionCheckType.PRODUCT_CHECK
  }) || userCanPermissionProductQuota({
    requiredProductValue: products.DEMO_SOCIAL_METABASE_DASHBOARD,
    userProducts,
    checkType: PermissionCheckType.PRODUCT_CHECK
  })

  const accountIsActive = accountHasAccess(currentAccount)

  const canSee = React.useMemo(
    () => {
      if (atleastOneAccessList.length) {
        return canSeeWithAtLeastOnePermissionsAndProducts(userCanPermissionProductQuota, atleastOneAccessList, userProducts, userPermissions ?? []);
      }
      return canSeeWithPermissionsAndProducts(userCanPermissionProductQuota, requiredAccessList, userProducts, userPermissions ?? []);
    },
    [user, perms, accounts, currentAccount, requiredAccessList, atleastOneAccessList, userProducts, userPermissions, userCanPermissionProductQuota]
  )

  if (isLoading) {
    return <LoadingPage message="Loading User Data" />
  }

  if (isInvalidUser) {
    return <Navigate to={'/userNotFound'} />
  }

  if (!accountIsActive) {
    const routeToAccount = currentAccount?.stripeSubscriptionId && location.pathname !== routes.app.settings.account.path
    const routeToStripe = !currentAccount?.stripeSubscriptionId && location.pathname !== routes.stripe.path

    if (isIframe() && routeToStripe) {
      return <StripePricing />
    }

    if (routeToAccount) {
      return <Navigate to={routes.app.settings.account.path} />
    }
  }

  if (location.pathname === routes.app.socialTopics.path && !socialDashboardEnabled) {
    return <UnauthorizedPage/>
  }

  if(checkAccountActive && !accountIsActive) {
    return <UnauthorizedPage/>
  }

  if (canSee) {
    return <Component />
  }

  return <UnauthorizedPage/>
}
export default ProtectedRoute;
