import { listBuilderAxios } from '@/axiosInstances'
import { IMoment } from '@/views/Trends/types'
import { ClusterSchema } from '@/views/Discover/Moments/v2/types'

export const moments = {
  get: async (search?: string, signal?: AbortSignal) => {
    let url = `/trend-analysis/moments${search ? `?prompt=${search}` : ''}`

    return listBuilderAxios.get<IMoment[]>(url, { signal} )
  },
  getById: async (momentId: number, brandProfileId?: number) => {
    const url = `/trend-analysis/moments/${momentId}
      ${brandProfileId ? `?brandProfileId=${brandProfileId}` : ''}`

    const result = await listBuilderAxios.get(url)
    return ClusterSchema.parse(result.data)
  }
}
