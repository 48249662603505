import React from 'react'
import { IconButton, Icon, Popover, Whisper, Dropdown } from 'rsuite'

interface ActionsCellProps {
  rowData: any
  onEdit: (rowData: any) => void
  onDelete?: (rowData: any) => void
  onRecover?: (rowData: any) => void
  [key: string]: any
}

const ActionsCell: React.FC<ActionsCellProps> = ({ rowData, onEdit, onDelete, onRecover, ...props }) => {
  const renderMenu = ({ left, top, className }: any, ref: React.Ref<any>) => (
    <Popover
      ref={ref}
      className={className}
      style={{ left, top }}
      full
    >
      <Dropdown.Menu>
        <Dropdown.Item
          onSelect={() => {
            onEdit(rowData)
          }}
        >
          Edit
        </Dropdown.Item>
        {onRecover && (
          <Dropdown.Item
            onSelect={() => {
              onRecover(rowData)
            }}
          >
            Recover
          </Dropdown.Item>
        )}
        {onDelete && (
          <Dropdown.Item
            onSelect={() => {
              onDelete(rowData)
            }}
          >
            Delete
          </Dropdown.Item>
        )}
      </Dropdown.Menu>
    </Popover>
  )

  return (
    <div
      {...props}
      className="actions-cell"
    >
      <Whisper
        placement="leftStart"
        trigger="click"
        speaker={renderMenu}
      >
        <IconButton
          icon={<Icon icon="more" />}
          appearance="subtle"
        />
      </Whisper>
    </div>
  )
}

export default ActionsCell
