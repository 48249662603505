import React from "react";
import {Loader, Modal} from "rsuite";

interface IProps {
    /** To show or hide the loading component to the modal */
    loading: boolean
    /** Title to be displayed in the Loading Modal */
    title: string
}

/**
 * Component to display in the Loading Modal.
 * @prop {boolean} loading To show or hide the loading component to the modal
 * @prop {string} title Title to be displayed in the Loading Modal.

 */

export default function LoadingModal({ loading, title }: IProps) {

    return (
        <Modal
            data-testid='div-report-generator-downloading-modal'
            show={loading}
            onHide={() => {}}
            size="xs"
        >
            <Modal.Body className="flex justify-center ">
                <Loader
                    content={title}
                    vertical
                    size="md"
                />
            </Modal.Body>
        </Modal>
    )
}