import sparklesAISvg from '@/assets/img/AI/SparklesAI.svg'
import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { PATH_MOMENTS_V2 } from '@/routes'
import AnalyzeSlidedeover from '@/views/Trends/components/AnalyzeSlideover'
import DetailDisplaySections from '@/views/Trends/components/DetailDisplaySections'
import DetailOverview from '@/views/Trends/components/DetailOverview'
import DetailTimeline from '@/views/Trends/components/DetailTimeline'
import TimelineChartWrapper from '@/views/Trends/components/TimelineChartWrapper'
import {
  useGetSankeyData,
  useGetTopicArticles,
  useGetTopicMomentTimeline,
  useGetTopicSocialPosts,
  useTopicById
} from '@/views/Trends/hooks/useTopics'
import { IMetricsPerDay, ITopic } from '@/views/Trends/types'
import { ArrowDownTrayIcon, XMarkIcon } from '@heroicons/react/20/solid'
import { useMatch, useNavigate } from '@tanstack/react-location'
import { useFlag } from '@unleash/proxy-client-react'
import React, { useEffect, useState } from 'react'
import Chip, { ChipSize } from '../Discover/Boards/components/conversations/Chip'
import { Placeholder } from 'rsuite'

export const TopicDetails = () => {
  const navigate = useNavigate()
  const trendsEnabled = useFlag('enable_trends_feature')
  if (!trendsEnabled) navigate({ to: PATH_MOMENTS_V2 })
  const downloadEnabled = useFlag('enable_trends_feature_download')

  const { params } = useMatch<MyLocationGenerics>()
  const topicId = params.topicId;

  const [topicData, setTopicData] = useState<ITopic>()
  const [lineChartData, setLineChartData] = useState<{
    socialPosts: IMetricsPerDay[],
    articles: IMetricsPerDay[]
  }>()

  const [topBrands, setTopBrands] = useState<{ id: number; name: string }[]>([]);
  const [topPeople, setTopPeople] = useState<{ id: number; name: string }[]>([]);

  const { topicQuery } = useTopicById(topicId)

  useEffect(() => {
    if (topicQuery.data) {
      const data = topicQuery.data.data
      setTopicData(data)
      setTopBrands(data.brands?.length ? data.brands : [])
      setTopPeople(data.people?.length ? data.people : [])
      setLineChartData({
        socialPosts: data.metrics.narrative.postCountPerDay || [],
        articles: data.metrics.moment.articleCountPerDay || []
      })
    }
  }, [topicQuery.data])

  const { momentTimelineQuery } = useGetTopicMomentTimeline(topicId)
  const momentTimeline = momentTimelineQuery?.data || []

  const { articlesQuery } = useGetTopicArticles(topicId)

  const { socialPostsQuery } = useGetTopicSocialPosts(topicId)

  const { sankeyQuery } = useGetSankeyData(topicId)
  const sankeyData = sankeyQuery?.data

  const [showAnalyzeSlideover, setShowAnalyzeSlideover] = React.useState(false)

  return (
    <div className="flex flex-col gap-7 p-6">
      <AnalyzeSlidedeover
        showSlideOver={showAnalyzeSlideover}
        setShowSlideOver={setShowAnalyzeSlideover}
        entityData={topicData}
      />
      <div
        className="flex flex-row justify-between items-center"
        data-testid="topic-details-header"
      >
        <p className='text-2xl font-semibold' data-testid="topic-details-title">{topicData?.name || 'Topic...'}</p>
        <div className="flex flex-row gap-4 items-center">
          <div className="flex flex-row gap-2">
            {downloadEnabled && <div className="h-10">
              <SightlyButton
                datatestid="topic-detail-download-btn"
                handleClick={() => { }}
                id="downloadActivation"
                text="Download"
                type="transparent"
                disabled={false}
                col={true}
                icon={
                  <ArrowDownTrayIcon
                    className="h-4 w-4 text-purple-600"
                    aria-hidden="true"
                  />
                }
              />
            </div>}

            <div className="border-l border-gray-300 mx-6"></div>

            <div className="h-10 flex justify-center">
              <SightlyButton
                id="aiAnalyzeButton"
                handleClick={() => setShowAnalyzeSlideover(true)}
                text="Analyze"
                type="gradient-ai"
                icon={
                  <img
                    data-testid={'ai-analyze-image'}
                    src={sparklesAISvg}
                    className=" h-4 w-4 text-grey-900"
                  />
                }
              />
            </div>
          </div>

          <div className="flex items-center justify-center w-10 h-10 rounded-full border-[1.1px] border-[rgba(212,217,217,1)] bg-gray-200">
            <button
              data-testid="moment-close-button"
              type="button"
              className="text-black rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
              onClick={() => window.history.back()}
            >
              <span className="sr-only">Close panel</span>
              <XMarkIcon
                className="w-6 h-6"
                aria-hidden="true"
              />
            </button>
          </div>
        </div>
      </div>

      <div className="w-fit">
        <Chip
          dataTestId={`detail-page-topic-chip`}
          text={'Topic'}
          size={ChipSize.MEDIUM}
        />
      </div>

      <div className="flex flex-row justify-between gap-4">
        <div className="w-3/5">
          {topicQuery.error ?
            <div className='mb-4'>
              <InfoPage message="There was an error fetching topic details. Please contact support for help." />
            </div>
            : topicQuery.isLoading || !topicData ?
              <div className='mb-4'>
                <LoadingPage message="Loading topic overview" />
                <Placeholder.Paragraph rows={8} style={{ marginTop: 30 }} active />
              </div>
              : <DetailOverview
                overviewText={topicData.overview || ''}
                overviewBullets={topicData.bulletsText || []}
                totalMoments={topicData.metrics?.moment?.count || 0}
                totalNarratives={topicData.metrics?.narrative?.count || 0}
                totalArticles={topicData.metrics?.moment?.articleCount || 0}
                totalSocialPosts={topicData.metrics?.narrative?.postCount || 0}
                sentimentScore={topicData.metrics?.sentimentScore || 0}
                sentimentType={
                  topicData.metrics?.sentimentScore === 0 || !topicData.metrics?.sentimentScore
                    ? ''
                    : topicData.metrics?.sentimentScore > 0
                      ? 'Positive'
                      : 'Negative'
                }
                topBrands={topBrands}
                topPeople={topPeople}
              />
          }
        </div>

        <div className="border-l border-gray-300 mx-6"></div>

        <div className="flex flex-col w-2/5 gap-4 pb-5">
          <DetailTimeline timelineData={momentTimeline}
            timelineDataIsLoading={momentTimelineQuery.isLoading}
            timelineDataError={momentTimelineQuery.error} />

          <div className="border-t border-black-300"></div>

          <TimelineChartWrapper
            lineChartData={lineChartData}
          />
        </div>
      </div>

      <div className="border-t border-black-300"></div>

      <DetailDisplaySections
        topicId={topicId}
        articles={articlesQuery.data || []}
        articlesError={articlesQuery.error}
        articlesLoading={articlesQuery.isLoading}
        socialPosts={socialPostsQuery.data || []}
        socialPostsError={socialPostsQuery.error}
        socialPostsLoading={socialPostsQuery.isLoading}
        sanKeyData={sankeyData ? sankeyData : undefined}
        sanKeyLoading={sankeyQuery.isLoading} />
    </div>
  )
}
