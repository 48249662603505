import { api } from "@/api/api"
import { brandProfile } from "@/api/brandProfile"
import { logError } from "@/utils"
import { useQuery } from "@tanstack/react-query"

export function useTopicTrendMoments(search?: string) {

  const momentsQuery = useQuery(
    ['momentsQuery', search],
    async ({ signal }) => {
      return await api.moments.get(search, signal);
    },
    {
      retry: false,
      staleTime: 1000 * 60 * 5,
      cacheTime: 1000 * 60 * 10, 
      enabled: !!search
    }
  )

  return {
    momentsData: momentsQuery.data?.data,
    momentsError: momentsQuery.error,
    isLoadingMoments: momentsQuery.isLoading,
  }
}

export function useTopicTrendMomentById(momentId?: number, brandProfileId?: number,) {
  const momentsQuery = useQuery(
    ['momentsQuery', momentId],
    async () => {
      return await api.moments.getById(momentId!, brandProfileId)
    },
    {
      retry: 1,
      cacheTime: 0,
      onError: (err) => {
        logError(err, { info: 'Error getting topic' })
      },
      enabled: !!momentId
    }
  )

  return {
    momentsQuery
  }
}