import { z } from 'zod'

const ActivationSchema = z.object({
  activationId: z.number(),
  hashtagId: z.string(),
  createdAt: z.string(),
  hashtagName: z.string(),
  hashtagStatus: z.string(),
  campaignName: z.string(),
  adGroupName: z.string(),
  errorMessage: z.string().optional().nullable(),
})

const CampaignOptionSchema = z.object({
  campaignId: z.number(),
  campaignName: z.string()
})

const AdGroupOptionSchema = z.object({
  adGroupId: z.number(),
  adGroupName: z.string()
})

export const GetActivationsSchema = z.object({
  activations: z.array(ActivationSchema),
  totalCount: z.string(),
  counts: z.object({
    pending: z.number(),
    active: z.number(),
    removed: z.number(),
    failed: z.number()
  }),
  config: z.object({
    campaignOptions: z.array(CampaignOptionSchema),
    adGroupOptions: z.array(AdGroupOptionSchema)
  })
})

export const UpdateActivationStatusSchema = z.object({
  affectedRows: z.number(),
  error: z.string().optional()
})

export const SubmitActivationsSchema = z.object({
  message: z.string(),
  data: z.object({
    hashtagsCountActivated: z.number(),
    hashtagsCountFailed: z.number(),
    errorMessages: z
      .array(
        z.object({
          message: z.string(),
          adgroup_name: z.string(),
          adgroup_id: z.number()
        })
      )
      .min(0)
      .optional()
  })
})
export type SubmitActivationsType = z.infer<typeof SubmitActivationsSchema>

export type IActivation = z.infer<typeof ActivationSchema>
export type ICampaignOption = z.infer<typeof CampaignOptionSchema>
export type IAdGroupOption = z.infer<typeof AdGroupOptionSchema>

interface ITab {
  name: string
  id: string
  count?: number
}

export interface ITabs {
  pending: ITab
  active: ITab
  removed: ITab
  failed: ITab
}

export const ActivationStatus: ITabs = {
  pending: { name: 'In Progress', id: 'PENDING' },
  active: { name: 'Active', id: 'ACTIVE' },
  removed: { name: 'Removed', id: 'REMOVED' },
  failed: { name: 'Failed', id: 'FAILED' }
}
