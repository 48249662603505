import React from 'react'
import SidePanel from '@/views/Discover/Boards/components/conversations/SidePanel'
import {
  OVERVIEW_SIDE_PANEL_DEFAULT_TAB_ID,
  OVERVIEW_SIDE_PANEL_HEADER_TITLE,
  OVERVIEW_SIDE_PANEL_TAB_IDS,
  OVERVIEW_SIDE_PANEL_TAB_OPTIONS
} from '@/views/Discover/Boards/Narratives/constants'
import NarrativesOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/NarrativesOverview'
import ThemesOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/ThemesOverview'
import PlatformsOverview from '@/views/Discover/Boards/Narratives/OverviewSidePanel/PlatformsOverview'
import { INarrative } from '@/views/Discover/Boards/types'
import {
  AggregatedOverviewType,
  useOverview
} from '@/views/Discover/Boards/Narratives/OverviewSidePanel/useOverview'
import { Loader } from 'rsuite'

type OverviewSidePanelProps = {
  openOverviewPanel: boolean
  setOpenOverviewPanel: React.Dispatch<React.SetStateAction<boolean>>
  brandProfileId: number
  boardIds: number[]
  minPublishDate: Date
  maxPublishDate: Date
}

const OverviewSidePanel = ({
  openOverviewPanel,
  setOpenOverviewPanel,
  boardIds,
  brandProfileId,
  maxPublishDate,
  minPublishDate
}: OverviewSidePanelProps) => {
  const [activeTabId, setActiveTabId] = React.useState<string>(OVERVIEW_SIDE_PANEL_DEFAULT_TAB_ID)
  const [aggregateOverview, setAggregateOverview] = React.useState<AggregatedOverviewType>()

  const {
    aggregatedOverviewQuery
  } = useOverview({brandProfileId, boardIds, minPublishDate, maxPublishDate})

  React.useEffect(() => {
    aggregatedOverviewQuery.refetch().then(({data})=> {
      setAggregateOverview(data)
    })
  }, [])

  return (
    <SidePanel
      open={openOverviewPanel}
      activeTabId={activeTabId}
      setActiveTabId={setActiveTabId}
      headerTitle={OVERVIEW_SIDE_PANEL_HEADER_TITLE}
      tabOptions={OVERVIEW_SIDE_PANEL_TAB_OPTIONS}
      onHide={() => setOpenOverviewPanel(() => false)}
      dataTestId="conversations-overview-side-panel"
    >
      {activeTabId === OVERVIEW_SIDE_PANEL_TAB_IDS.TAB_NARRATIVE && (
        <NarrativesOverview narrativesOverview={aggregateOverview?.narrativesOverview || []} />
      )}
      {activeTabId === OVERVIEW_SIDE_PANEL_TAB_IDS.TAB_THEMES &&
        <ThemesOverview themesOverview={aggregateOverview?.themesOverview || []} />
      }
      {activeTabId === OVERVIEW_SIDE_PANEL_TAB_IDS.TAB_PLATFORMS &&
        <PlatformsOverview platformsOverview={aggregateOverview?.platformsOverview || []} />}
    </SidePanel>
  )
}

export default OverviewSidePanel
