import { useQuery } from "@tanstack/react-query";
import { api } from "@/api/api";
import { IMoment, INarrative, ITrend, ITopic } from "../types";
import { logError } from "@/utils";

export function useGenerateSummaryData(moments: IMoment[], narratives: INarrative[], trends: ITrend[], topics: ITopic[], callSummaryOnSearch: boolean) {
  const summaryQuery = useQuery(
    ['generateSummary', moments, narratives, trends, topics, callSummaryOnSearch],
    async () => {
      const data = {
        moments: moments.slice(0, 20).map((m) => ({ name: m.name, summary: m.name })),
        narratives: narratives.slice(0, 20).map((n) => ({ name: n.name, summary: n.name })),
        trends: trends.slice(0, 20).map((t) => ({ name: t.name, summary: t.overview })),
        topics: topics.slice(0, 20).map((t) => ({ name: t.name, summary: t.overview }))
      };      

      const response = await api.analysis.summarize(data);
      return response.data;
    },
    {
      retry: 1,
      onError: (err) => {
        logError(err, { info: 'Error generating summary' })
      },
      enabled: callSummaryOnSearch
    }
  );

  return {
    summaryData: summaryQuery.data,
    isLoadingSummary: summaryQuery.isLoading,
  }
}

