import React, { useState } from 'react'
import { Icon, Input, InputGroup } from 'rsuite'
import debounce from 'just-debounce-it'

import { sightlyBlue } from '@/assets/jss/colorConstants'

/**
 * Search box component.
 * @param {Object} props - Component props.
 * @param {(searchKey: string) => void} props.handleSearch - Function called when the search keyword changes.
 * @param {string} [props.placeholder] - Placeholder text for the search box.
 * @param {boolean} [props.disabled] - Whether the search box is disabled.
 * @returns {JSX.Element} The rendered component.
 */
const SearchBox = ({
  className = '',
  handleSearch: onSearch,
  placeholder = 'Search',
  disabled = false,
  dataTestId = 'search-box',
  height = '38px',
  showClearButton = false, 
}: {
  className?: string;
  handleSearch: (searchKey: string) => void;
  placeholder?: string;
  disabled?: boolean;
  dataTestId?: string;
  height?: string;
  showClearButton?: boolean;
}) => {
  const [inputValue, setInputValue] = useState('');
  const keywordRef = React.useRef<any>()
  const debouncedHandleSearch = React.useCallback(
    debounce((searchKeyword: string) => {
      keywordRef.current.value = searchKeyword
      onSearch(searchKeyword ?? '');
    }, 500),
    []
  )
  return (
    <div data-testid={dataTestId ?? 'search-box'} id='pendo-search-box' className={className ?? ''}>
      <InputGroup
        inside
        style={{ height: height ?? '38px', padding: '0 2px' }}
      >
        <InputGroup.Button>
          <Icon icon="search" />
        </InputGroup.Button>
        <Input
          data-testid="search-box-input"
          disabled={disabled}
          placeholder={placeholder ?? 'Search'}
          onChange={(searchKeyword: string) => {
            setInputValue(searchKeyword);
            debouncedHandleSearch(searchKeyword);
          }}
          inputRef={keywordRef}
        />
          {(showClearButton ? inputValue : true) && (
          <InputGroup.Button
            data-testid="search-box-clear-button"
            onClick={() => {
              if (keywordRef.current) keywordRef.current.value = '';
              setInputValue('');
              onSearch('');
            }}
          >
            <Icon icon="close" />
          </InputGroup.Button>
        )}
      </InputGroup>
      <style>
        {`
          .rs-input:focus, .rs-input:hover {
            border-color: ${sightlyBlue};
            outline: 0;
          }
            .rs-input-group.rs-input-group-inside .rs-input{
            min-width: 7rem;
            }
        `}
      </style>
    </div>
  )
}

export default SearchBox
