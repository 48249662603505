import React from 'react'
import { BrandProfileStatusType } from '../../../../../hooks/brandProfile/useBrandProfile'

const ConfirmSubmit = ({ brandProfile }: { brandProfile: BrandProfileStatusType }) => {
    let opinionIncompleteCountInfo = null
    if (brandProfile.opinionIncompleteCount > 0) {
        opinionIncompleteCountInfo = `${brandProfile.opinionIncompleteCount} Opinion`
    }

    let scenarioV2IncompleteCountInfo = null
    if (brandProfile.scenarioV2IncompleteCount > 0) {
        scenarioV2IncompleteCountInfo = `${brandProfile.scenarioV2IncompleteCount} Scenario`
    }

    let queriesCompleteInfo = null
    if (!brandProfile.queriesComplete) {
        queriesCompleteInfo = `No queries entered`
    }

    return (
        <>
            You have some incomplete items on your Brand Mentality: <br /> <br />
            {opinionIncompleteCountInfo && `${opinionIncompleteCountInfo}. `}
            {scenarioV2IncompleteCountInfo && `${scenarioV2IncompleteCountInfo}. `}
            {queriesCompleteInfo && `${queriesCompleteInfo}. `}
            <br /> <br />
            If you click 'Submit', we will skip those questions and submit it anyway. Would you like to proceed?
        </>
    )
}

export default ConfirmSubmit
