import React from 'react';
import SightlyButton from '@/components/Sightly/SightlyButton';
import useUsers from '@/hooks/useUsers';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import toast from 'react-hot-toast';
import PortalModal from '@/components/PortalModal/PortalModal';
import useUser from '@/hooks/useUser';
import { rqKeys } from '@/ReactQueryKeyFactory';
import useRoles from '@/hooks/useRoles';
import { FormGroup, Radio, RadioGroup, Checkbox, CheckboxGroup } from 'rsuite';
import { SightlyModernFormInput } from '@/components/Sightly/SightlyModern/Form/SightlyModernFormInput';
import { useSightlyForm } from '@/components/Sightly/SightlyModern/Form/hooks/useSightlyForm';
import { InviteFormType, InviteFormSchema } from '@/views/Settings/Users/types';
import { api } from '@/api/api';
import { ApiErrorResponse } from '@/api/apiTypes';
import { AxiosError } from 'axios';
import { FormLabel } from '@/components/Sightly/SightlyFormElements/SightlyFormLabel';
import { products } from '@/staticData/products';

export type InviteUserExternalProps = {
  isOpen: boolean
  setIsOpen: Function
}

export const InviteUserExternal = ({ isOpen, setIsOpen }: InviteUserExternalProps) => {
  const { data: roles } = useRoles()
  const { refetch } = useUsers()

  const { currentAccount } = useUser()

  const queryClient = useQueryClient()

  // Wrap setIsOpen function prop to reset form state
  const handleClose = () => {
    setIsOpen(false)
    reset()
  }

  const externalViewerRoleId = roles?.find((r: any) => r.name === 'Viewer - External')?.id
  const externalEditorRoleId = roles?.find((r: any) => r.name === 'Editor - External')?.id
  const externalAdminRoleId = roles?.find((r: any) => r.name === 'Admin - External')?.id

  const {
    sightlyRegister,
    handleSubmit,
    formState: { isValid },
    reset,
    getValues,
    setValue,
    trigger
  } = useSightlyForm<InviteFormType>(InviteFormSchema, {
    defaultValues: {
      accounts: [currentAccount?.accountId],
      roles: []
    }
  })
  const inviteMutation = useMutation(api.user.create, {
    onSuccess: (_) => {
      setIsOpen(false)
      toast.success('Successfully invited user!')
      // to refresh product quota
      queryClient.fetchQuery(rqKeys.subscriptionQuotaQueryKey(currentAccount?.accountId, products.USERS))
      refetch()
    },
    onError: (err: AxiosError<ApiErrorResponse>) => {
      setIsOpen(false)
      toast.error('Failed to invite user')
    }
  })

  const handleRoleChange = (value: number[]) => {
    setValue('roles', value)
    trigger('roles')
  }

  const onInviteSubmit = handleSubmit((data) => {
    inviteMutation.mutate({
      ...data,
      accounts: data.accounts.map((a) => {
        return { accountId: a }
      }) as any,
      userType: 'External',
      roles: data.roles.map((roleId) => ({
        id: Number(roleId),
        name: '',
        description: '',
        userType: 'External'
      }))
    })
  })

  return (
    <PortalModal
      data-testid="invite-user-external-modal"
      open={isOpen}
      handleClose={handleClose}
      footerButtons={[
        <SightlyButton
          type="cancel"
          id="cancel-create-invite"
          handleClick={handleClose}
          text="Cancel"
        />,
        <SightlyButton
          disabled={!isValid || inviteMutation.isLoading}
          id="submit-create-invite"
          handleClick={onInviteSubmit}
          text="Invite"
        />
      ]}
    >
      <h4 className="mb-6">Invite member</h4>
      <div className="grid gap-4">
        <div className="grid grid-cols-2 gap-4">
          <SightlyModernFormInput {...sightlyRegister('firstName', 'First name')} />
          <SightlyModernFormInput {...sightlyRegister('lastName', 'Last name')} />
        </div>
        <SightlyModernFormInput
          {...sightlyRegister('company', 'Company name')}
          placeholder="Your company name"
        />
        <SightlyModernFormInput
          {...sightlyRegister('email', 'Work email address')}
          placeholder="you@example.com"
        />
        <FormGroup>
          <FormLabel
            id="roleId"
            text="Roles"
          />

          <CheckboxGroup
            name="rolesCheckbox"
            value={getValues('roles')}
            onChange={handleRoleChange}
          >
            <Checkbox data-testid={'checkbox-role'} value={externalAdminRoleId}>
              <p data-testid={'p-role-name'} className="font-semibold">Admin</p>
              <p>Management access to view, create, edit and delete</p>
            </Checkbox>
            <Checkbox data-testid={'checkbox-role'} value={externalEditorRoleId}>
              <p data-testid={'p-role-name'} className="font-semibold">Editor</p>
              <p>Access to view, create and edit</p>
            </Checkbox>
            <Checkbox data-testid={'checkbox-role'} value={externalViewerRoleId}>
              <p data-testid={'p-role-name'} className="font-semibold">Viewer</p>
              <p>Access to view only</p>
            </Checkbox>
          </CheckboxGroup>
        </FormGroup>
      </div>
    </PortalModal>
  )
}
