import NoImageFoundPath from '@/assets/img/NoImageFound.svg';
import SearchBox from "@/components/SearchBox";
import SightlyButton from "@/components/Sightly/SightlyButton";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { Divider, Loader } from "rsuite";
import { trendTopicController } from "../controllers/TrendTopicController";
import { useGetTrendsByTopicId } from "../hooks/useTopics";
import { useGetTrendMoments, useGetTrendNarratives } from "../hooks/useTrends";

interface MomentViewSlideOverProps {
  trendId?: string;
  topicId?: string;
}

const TopicMomentViewSlideOver: React.FC<MomentViewSlideOverProps> = ({
  topicId,
  trendId,
}) => {
  const { topicsQuery } = useGetTrendsByTopicId(topicId!);
  const [expandedTrends, setExpandedTrends] = useState<string[]>([]);
  const [momentPages, setMomentPages] = useState<Record<string, number>>({});
  const [momentsDataByTrend, setMomentsDataByTrend] = useState<Record<string, { data: any[]; totalPages: number }>>({});
  const [narrativesDataByTrend, setNarrativesDataByTrend] = useState<Record<string, any[]>>({});
  const [loading, setLoadingState] = useState<boolean>(false);
  const [search, setSearch] = React.useState<string>();
  const { momentsQuery } = useGetTrendMoments(
    trendId!,
    search,
    momentPages[trendId!] || 1,
    20
  );
  const { narrativesQuery } = useGetTrendNarratives(trendId!, 1, 20);

  const handlePageChange = (trendId: string, newPage: number) => {
    setMomentPages((prev) => ({ ...prev, [trendId]: newPage }));
    trendTopicController.getMomentsMethod(trendId, newPage, setMomentsDataByTrend, setLoadingState);
  };

  useEffect(() => {
    if (momentsQuery?.data) {
      setMomentsDataByTrend((prev) => ({
        ...prev,
        [trendId!]: {
          data: momentsQuery.data.data,
          totalPages: momentsQuery.data.totalPages,
        },
      }));
    }

    if (narrativesQuery?.data) {
      setNarrativesDataByTrend((prev) => ({
        ...prev,
        [trendId!]: narrativesQuery.data,
      }));
    }
  }, [momentsQuery?.data, narrativesQuery?.data, trendId]);

  useEffect(() => {
    if (topicsQuery.data) {
      topicsQuery.data.forEach(async (trend) => {
        trendTopicController.getMomentsMethod(trend.id, 1, setMomentsDataByTrend, setLoadingState);
        setMomentPages((prev) => ({ ...prev, [trend.id!]: 1 }))
      });
    }
  }, [topicsQuery.data]);

  return (
    <div className="mt-[-2vh] pr-5">
      <Divider className="mb-[2vh]" />
      <div className="flex flex-col gap-4">
        <SearchBox
          dataTestId="trend-moment-search-box"
          handleSearch={setSearch}
          placeholder="Search by name"
          className="w-[40%]"
        />

        <div className="my-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg min-w-full">
          <table className="table-fixed bg-gray-50 w-full divide-y divide-gray-300">
            <thead className="text-[0.5rem] w-full">
              <tr className="text-gray-400 uppercase w-full">
                <th className="py-3.5 w-2/12"></th>
                <th className="text-left py-3.5 w-4/12">Moment Name</th>
                <th className="py-3.5 w-2/12">Articles</th>
                <th className="py-3.5 w-2/12">Keywords</th>
                <th className="py-3.5 w-2/12">Videos</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {topicsQuery.data ? (
                topicsQuery.data.map((trend) => {
                  const momentsData = momentsDataByTrend[trend.id] || { data: [], totalPages: 0 };
                  return (
                <React.Fragment key={trend.id}>
                  <tr
                    className="fade-in animate-in cursor-pointer table-row"
                    onClick={() =>
                      setExpandedTrends((prev) =>
                        prev.includes(trend.id)
                          ? prev.filter((id) => id !== trend.id)
                          : [...prev, trend.id]
                      )
                    }
                  >
                    <td className="py-3.5 flex justify-center">
                      <ChevronDownIcon
                        className={`text-gray-400 w-5 transform transition-transform ${expandedTrends.includes(trend.id) ? "rotate-180" : ""}`}
                      />
                    </td>
                    <td className="truncate pr-[0.5vw]">{trend.name}
                    </td>
                  </tr>

                  {expandedTrends.includes(trend.id) && (
                    <tr>
                      <td colSpan={5}>
                        <div className="max-h-[30vh] overflow-y-auto w-full">
                          <table className="table-fixed w-full">
                            <tbody className="divide-y divide-gray-200 w-full">
                              {momentsData?.data.map((moment) => (
                                <tr key={moment.id} className="w-full">
                                  <td className="py-3.5 flex justify-center">
                                    <img
                                      src={moment.imageUrl || NoImageFoundPath}
                                      alt={moment.name}
                                      className="w-[4vw] aspect-square rounded-full object-cover"
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.onerror = null;
                                        target.src = NoImageFoundPath;
                                      }}
                                    />

                                  </td>
                                  <td className="text-left pl-0.5 py-3.5 w-4/12 truncate">
                                    {moment.name}
                                  </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {moment.articleCount || 0}
                                  </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {moment.keywordCount || 0}
                                  </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {moment.videoCount || 0}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="flex justify-center p-4">
                            <SightlyButton
                              datatestid="load-more-moments-button"
                              block
                              id={`loadmorebutton-${trend.id}`}
                              text="Load More"
                              handleClick={() =>
                                handlePageChange(trend.id, momentPages[trend.id] + 1)
                              }
                              loading={loading}
                              disabled={loading || momentPages[trend.id] >= momentsData.totalPages}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
                  )
                })
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className="h-full flex items-center justify-center">
                      <Loader size="md" content="Loading..." center />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TopicMomentViewSlideOver;
