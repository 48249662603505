import React from 'react';
import { IArticle } from '@/views/Trends/types';
import { ClockIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import NoImageFoundPath from '@/assets/img/NoImageFound.svg';

dayjs.extend(relativeTime);

interface ArticleCardProps {
  article: IArticle
}

const ArticleResultCard = ({ article }:ArticleCardProps) => {

  return <div data-testid="topic-trend-article-item" className="flex items-center p-4">
    <img data-testid="topic-trend-article-item-image" 
      src={article.imageUrl ?? NoImageFoundPath} 
      alt={article.title} 
      className="w-20 h-20 mr-4"
      onError={(e) => {
        e.preventDefault()
        e.currentTarget.src = NoImageFoundPath;
      }} />
    <div className="flex flex-col">
      <h3 data-testid="topic-trend-article-item-description" className="text-lg font-semibold">{article.title}</h3>
      <p className="text-gray-500">
        <ClockIcon className="h-4 mr-1 inline-block" />
        <span data-testid="topic-trend-article-item-published-by">
          {article.sourceName}
        </span>
        <span data-testid="topic-trend-article-item-published" className="text-gray-400 text-sm ml-1">
          {dayjs(article.publishedAt).fromNow()}
        </span>
      </p>
    </div>
  </div>
};

export default ArticleResultCard;