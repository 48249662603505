
export type MenuItem = {
    name: string,
    value: string
}

export const menuItems: MenuItem[] = [
    {
        name: 'Ranking',
        value: 'ranking'
    },
    {
        name: 'Most Placements',
        value: 'placements'
    },
    {
        name: 'Date Published (oldest)',
        value: 'publishedAsc'
    },
    {
        name: 'Date Published (newest)',
        value: 'publishedDesc'
    },
]

export enum SortOrder {
    ASC = 'asc',
    DESC = 'desc',
    EMPTY = ''
  }

export type SortState = {
  column: string;
  direction: SortOrder;
};