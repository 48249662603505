import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

import targetAudienceIcon from '@/assets/img/target-audience.svg';
import performanceIcon from '@/assets/img/performance.svg';
import summaryReportIcon from '@/assets/img/summary-report.svg';

export enum DEVICE_DIMENSION {
  AD_ID = 'adId',
  AD_NAME = 'adName',
  AD_TYPE = 'adType',
  CLIENT_CREATIVE_ID = 'clientCreativeId',
  CLIENT_CREATIVE_NAME = 'clientCreativeName',
  DEVICE = 'device',
}

interface DeviceDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | DEVICE_DIMENSION
}

export interface DeviceReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | DEVICE_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | DeviceDimensionOption)[]
}

export const dimensionOptions: Option<DeviceDimensionOption> = {
  [DEVICE_DIMENSION.AD_ID]: {
    name: 'Ad Id',
    value: DEVICE_DIMENSION.AD_ID,
    description: 'Unique identifier for the advertisement.' // Generated description
  },
  [DEVICE_DIMENSION.AD_NAME]: {
    name: 'Ad Name',
    value: DEVICE_DIMENSION.AD_NAME,
    description: 'Name of the ad' // Generated description
  },
  [DEVICE_DIMENSION.AD_TYPE]: {
    name: 'Ad Type',
    value: DEVICE_DIMENSION.AD_TYPE,
    description: 'Type of the ad' // Generated description
  },
  [DEVICE_DIMENSION.CLIENT_CREATIVE_ID]: {
      name: 'Client Creative Id',
      value: DEVICE_DIMENSION.CLIENT_CREATIVE_ID,
      description: 'Identifier for the creative.' // Generated description
  },
  [DEVICE_DIMENSION.CLIENT_CREATIVE_NAME]: {
      name: 'Client Creative Name',
      value: DEVICE_DIMENSION.CLIENT_CREATIVE_NAME,
      description: 'Name of the creative.' // Generated description
  },
  [DEVICE_DIMENSION.DEVICE]: {
      name: 'Device',
      value: DEVICE_DIMENSION.DEVICE,
      description: `CONNECTED_TV,
                    DESKTOP,
                    MOBILE,
                    TABLET`
  },
}

export const baseDeviceReport: DeviceReport = {
  name: 'Device Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.DEVICE,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}

export const performanceDetailTemplate: DeviceReport = {
  ...baseDeviceReport,
  name: 'Performance Detail',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.CLIENT_PACKAGE_ID,
    GENERIC_DIMENSION.CLIENT_PACKAGE_NAME,
    GENERIC_DIMENSION.CLIENT_PLACEMENT_ID,
    GENERIC_DIMENSION.CLIENT_PLACEMENT_NAME,
    DEVICE_DIMENSION.CLIENT_CREATIVE_ID,
    DEVICE_DIMENSION.CLIENT_CREATIVE_NAME,
    GENERIC_DIMENSION.SIGHTLY_PLACEMENT_NAME,
    GENERIC_DIMENSION.SIGHTLY_PLACEMENT_ID,
    GENERIC_DIMENSION.CAMPAIGN_NAME,
    GENERIC_DIMENSION.PERSONA,
    GENERIC_DIMENSION.PARTNER,
    GENERIC_DIMENSION.PLATFORM,
    DEVICE_DIMENSION.AD_NAME,
    GENERIC_DIMENSION.AD_FORMAT,
    DEVICE_DIMENSION.DEVICE,
    METRIC.IMPRESSIONS,
    METRIC.VIEWS,
    METRIC.VIEW_RATE,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.CPV,
    METRIC.CPM,
    METRIC.COST,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
  icon: performanceIcon
}


export const campaignSummaryTemplate: DeviceReport = {
  ...baseDeviceReport,
  name: 'Campaign Summary',
  description: 'A detailed overview of the performance and effectiveness of a specific marketing campaign',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.AD_FORMAT,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
  icon: summaryReportIcon
}

export const adGroupReportTemplate: DeviceReport = {
  ...baseDeviceReport,
  name: 'Ad Group Report',
  description: 'A detailed overview of the performance and effectiveness of a specific marketing campaign',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.AD_GROUP_NAME,
    GENERIC_DIMENSION.AD_FORMAT,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
  icon: targetAudienceIcon
}

export const personaReportTemplate: DeviceReport = {
  ...baseDeviceReport,
  name: 'Persona Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.PERSONA,
    GENERIC_DIMENSION.AD_FORMAT,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}

export const deviceReportTemplate: DeviceReport = {
  ...baseDeviceReport,
  name: 'Device Report',
  columns: [
    METRIC.STAT_DATE,
    GENERIC_DIMENSION.CUSTOMER_ID,
    GENERIC_DIMENSION.INSERTION_ORDER_NAME,
    GENERIC_DIMENSION.AD_FORMAT,
    DEVICE_DIMENSION.DEVICE,
    METRIC.IMPRESSIONS,
    METRIC.CPM,
    METRIC.VIEWS,
    METRIC.CLICKS,
    METRIC.CTR,
    METRIC.COST,
    METRIC.VCR,
    METRIC.QUARTILE_25,
    METRIC.QUARTILE_50,
    METRIC.QUARTILE_75,
    METRIC.QUARTILE_100,
  ],
}
