import { BrandProfile } from '@/classes/brandProfile';
import { InfoPage } from '@/components/InfoPage';
import { LoadingItems } from '@/components/LoadingItems';
import { LoadingPage } from '@/components/LoadingPage';
import SightlyButton from '@/components/Sightly/SightlyButton';
import { routes } from '@/routes';
import SocialNarrativeSlideOver from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialNarrativeSlideOver';
import { MomentSlideOver } from '@/views/Discover/Moments/v2/components/MomentSlideOver/MomentSlideOver';
import { ClusterType } from '@/views/Discover/Moments/v2/types';
import ResultCard from '@/views/Trends/SearchResults/components/ResultCard';
import ResultMomentCard from '@/views/Trends/SearchResults/components/ResultMomentCard';
import ResultSocialNarrativeCard from '@/views/Trends/SearchResults/components/ResultNarrativeCard';
import { useTopicTrendMomentById } from '@/views/Trends/hooks/useTopicTrendMoments';
import { INarrative, ISearchResult, ITopic, ITrend } from '@/views/Trends/types';
import { useNavigate } from '@tanstack/react-location';
import React, { useEffect, useState } from 'react';

interface IProps {
  results?: ISearchResult[];
  type: 'topic' | 'trend' | 'moment' | 'narrative';
  isLoading: boolean;
  page: number;
  pageSize: number;
  totalItems: number;
  handlePageChange: (newPage: number) => void;
  brandProfileId?: number
  brandProfileSelectOptions?: BrandProfile[]
}

const ResultCardList = ({ results, type, handlePageChange, isLoading, 
  page, pageSize, totalItems, 
  brandProfileId, brandProfileSelectOptions }: IProps) => {

  const navigate = useNavigate()

  const [openPanel, setOpenPanel] = useState(false);

  const [narrativeId, setNarrativeId] = React.useState<string>();
  const [narrativeTab, setNarrativeTab] = React.useState<string>();
  
  const [momentId, setMomentId] = React.useState<number>();
  const [moment, setMoment] = React.useState<ClusterType>();
  const { momentsQuery } = useTopicTrendMomentById(momentId, brandProfileId)

  const handleResultSelect = (type: 'narrative' | 'moment', id: number | string) => {
    switch (type) {
      case 'narrative':
        setOpenPanel(true);
        setNarrativeId(String(id))
        break;
      case 'moment':
        if (momentId === Number(id)) {
          setOpenPanel(true)
          break;
        }
        setMomentId(Number(id))
        break;
    }
  };

  useEffect(() => {
    if (!momentsQuery.data) return
    
    setOpenPanel(true);
    setMoment(momentsQuery.data);
  }, [momentsQuery.data])

  const handleResultTopicTrendSelect = (result: ISearchResult) => {
    navigate({ to: type === 'topic' ? `${routes.app.trends.topicDetails.path}/${result.id}` : `${routes.app.trends.trendDetails.path}/${result.id}` })
  };

  const renderResults = () => {
    if (!results) return
    
    switch (type) {
      case 'topic':
      case 'trend':
        const castedResults = type === 'topic' ? results as ITopic[] : results as ITrend[]
        return castedResults.map((result) => (
          <ResultCard
            key={`search-${type}-result-${result.id}`}
            data-testid={`search-${type}-result-${result.id}`}
            result={result}
            handleSelect={handleResultTopicTrendSelect}
          />
        ));
      case 'moment':
        return results.map((result) => {
          return <ResultMomentCard
            key={`search-moment-result-${result.id}`}
            data-testid={`search-moment-result-${result.id}`}
            moment={result}
            handleSelect={(id: number) => handleResultSelect('moment', id)}
          />;
        });

      case 'narrative':
        return (results as INarrative[]).map((result) => (
          <ResultSocialNarrativeCard
            key={`search-narrative-result-${result.id}`}
            data-testid={`search-narrative-result-${result.id}`}
            narrative={result}
            toggleSocialNarrativePanel={(id: number, tab?: string) => {
              setNarrativeTab(tab)
              handleResultSelect('narrative', id)
            }}
          />
        ));
      default:
        return null;
    }
  };

  if (isLoading) {
    return (
      <div className='flex flex-col w-full'>
        <LoadingPage message={`Fetching ${type}s`} />
        <LoadingItems />
      </div>
    )
  }

  if (!results) {
    return (
      <InfoPage message={`There was an unknown error fetching ${type}s. Please refresh the page to try again. If the problem persists, please contact support.`} />
    )
  }

  if (results.length === 0) {
    return (
      <InfoPage message={`There are no ${type}s for your filters`} />
    )
  } else {
    return (
      <div className="flex flex-col gap-4">
        {renderResults()}
        {totalItems > pageSize * page && (

          <div className="flex justify-center h-10">
            <SightlyButton
              datatestid="load-more-trend-result-button"
              block
              id="loadmorebutton"
              text="Load More"
              handleClick={() => handlePageChange(page + 1)}
              loading={isLoading}
            />
          </div>)}

        { openPanel && (
          type === 'narrative' && narrativeId ? (
            <SocialNarrativeSlideOver
              openSocialNarrativePanel={openPanel}
              setOpenSocialNarrativePanel={setOpenPanel}
              selectedSocialClusterId={String(narrativeId)}
              tab={narrativeTab}
            />
          ) : type === 'moment' && moment ? (
            <MomentSlideOver
              show={openPanel}
              close={setOpenPanel}
              moment={moment}
              brandProfileId={brandProfileId}
              brandProfileSelectOptions={brandProfileSelectOptions}
            />
          ) : null
        )}
      </div>
    )
  };
};

export default ResultCardList;
