import { Link, useNavigate } from '@tanstack/react-location'
import React from 'react'
import toast from 'react-hot-toast'
import { LoadingPage } from '@/components/LoadingPage'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { ThemeType } from '@/views/Admin/Scenarios/types'
import { useThemes } from '@/views/Admin/Scenarios/useThemes'
import ActionsCell from '../components/ActionsCell'
import { useMutation } from '@tanstack/react-query'
import { api } from '@/views/Admin/Scenarios/api'
import { logError } from '@/utils'

export const Themes = () => {
  const [includeDeleted, setIncludeDeleted] = React.useState(false)
  const themesQuery = useThemes({ includeDeleted })
  const navigate = useNavigate()

  const deleteMutation = useMutation((themeId: Number) => api.themes.delete(Number(themeId)), {
    onSuccess: () => {
      toast.success('Theme deleted')
      themesQuery.refetch()
    },
    onError: (err: any) => {
      toast.error(err.response?.data?.message || 'Unknown error occurred')
      logError(err, { info: 'error deleting theme' })
    }
  })

  const handleDelete = (themeId: Number) => {
    deleteMutation.mutate(themeId)
  }

  const recoverMutation = useMutation((themeId: Number) => api.themes.recover(Number(themeId)), {
    onSuccess: () => {
      toast.success('Theme recovered')
      themesQuery.refetch()
    },
    onError: (err) => {
      toast.error('Unknown error occurred')
      logError(err, { info: 'error recovering theme' })
    }
  })

  const handleRecover = (themeId: Number) => {
    recoverMutation.mutate(themeId)
  }

  if (themesQuery.isLoading) {
    return <LoadingPage message="Fetching Themes" />
  }

  return (
    <div className="p-8">
      <div className="px-4 sm:px-6 lg:px-8">
        <Link to="/app/admin/scenarios">Back to Scenarios</Link>
        <div className="sm:flex sm:items-center mt-2">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Scenario Themes</h1>
            <p className="mt-2 text-sm text-gray-700"></p>
          </div>
          <div className="mt-4 sm:mt-0 sm:ml-16 flex gap-2">
            <SightlyButton
              handleClick={() => navigate({ to: 'create' })}
              id="addTheme"
              text="Add Theme"
            />
          </div>
        </div>

        <div className="mt-8 flex flex-col">
          <SightlyToggle
            id="archivedToggle"
            enabled={includeDeleted}
            setEnabled={setIncludeDeleted}
            enabledText="Include Deleted"
            disabledText=""
          />
          <div className="pt-3 -my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                      >
                        Name
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Description
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Archived
                      </th>

                      <th
                        scope="col"
                        className="relative py-3.5 pl-3 pr-4 sm:pr-6 text-right"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {themesQuery?.data?.map((theme: ThemeType) => (
                      <tr key={theme.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                          {theme.name}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-400 truncate max-w-lg">
                          {theme.description}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                          {theme.deletedAt ? 'True' : 'False'}
                        </td>

                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                          {theme.deletedAt ? (
                            <ActionsCell
                              rowData={theme}
                              onEdit={() => navigate({ to: `/app/admin/scenarios/themes/edit/${theme.id}` })}
                              onRecover={() => handleRecover(theme.id)}
                            />
                          ) : (
                            <ActionsCell
                              rowData={theme}
                              onEdit={() => navigate({ to: `/app/admin/scenarios/themes/edit/${theme.id}` })}
                              onDelete={() => handleDelete(theme.id)}
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
