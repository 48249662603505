import { listBuilderAxios } from '@/axiosInstances'
import { GetSocialClusterSchema } from '@/views/Discover/Boards/types';
var fileDownload = require('js-file-download');

interface DownloadProps {
  brandProfileId: number
  boardIds: number[]
  clusterIds: number[]
  minPublishDate: string
  maxPublishDate: string
  sortBy: string
  themes: number[]
  topicIds: number[] | string[]
}

export const socialCluster = {
  get: async (brandProfileId: any, boardIds: any, minPublishDate: any, maxPublishDate: any, sortBy: string, themes: number[], topicIds: number[] | string[], limit: number, offset: number, signal?: AbortSignal) => {
    const url = '/social-clusters'

    const body = {
        brandProfileId: brandProfileId,
        boardIds: boardIds,
        minPublishDate: minPublishDate,
        maxPublishDate: maxPublishDate,
        filters: {
          sortBy,
          themes,
          topics: topicIds
        },
        limit,
        offset
    }

    const result = await listBuilderAxios.post(url, body, { signal })
    return GetSocialClusterSchema.parse(result.data);
  },
  getOverviewById: async (socialClusterId: string, signal?: AbortSignal) => {
    const url = `/social-clusters/overview/${socialClusterId}`;
    const result = await listBuilderAxios.get(url, { signal });
    return result.data;
  },
  getOverview: async (brandProfileId: any, boardIds: any, minPublishDate: any, maxPublishDate: any, signal?: AbortSignal) => {
    const url = '/social-clusters/overview'

    const body = {
        brandProfileId: brandProfileId,
        boardIds: boardIds,
        minPublishDate: minPublishDate,
        maxPublishDate: maxPublishDate
    }

    const result = await listBuilderAxios.post(url, body, { signal })
    // to do: uncomment wonce typing mismatch bug solution is found
    // return GetOverviewSocialClusterSchema.parse(result.data);
    return result.data;
  },
  download: async ({ 
    brandProfileId,
    boardIds,
    clusterIds,
    minPublishDate, // '2024-04-01'
    maxPublishDate, // '2024-06-30'
    sortBy,
    themes,
    topicIds,
  }: DownloadProps) => {
    const url = `/social-clusters/download`
    let info = { 
      brandProfileId,
      boardIds,
      clusterIds,
      minPublishDate,
      maxPublishDate,
      sortBy,
      themes,
      topicIds,
     }

     const body = {
         brandProfileId: brandProfileId,
         boardIds: boardIds,
         clusterIds: clusterIds,
         minPublishDate: minPublishDate,
         maxPublishDate: maxPublishDate,
         filters: {
           sortBy,
           themes,
           topics: topicIds
         },
     }

    const result = await listBuilderAxios({
        method: 'POST',
        url: url,
        responseType: 'blob',
        data: body
    })
    if (!result) {
        return Promise.reject('Unknown download error')
    }
    fileDownload(result.data, `narratives.xlsx`)
},
}
