import React from 'react'
import Table from 'rsuite/lib/Table'
import { TableColumnJs } from '@/utils'
import SightlyCheckbox from '@/components/Sightly/SightlyFormElements/SightlyCheckbox'
import { IActivation } from '@/views/TikTok/Activations/types'
import { MagnifyingGlassIcon, BoltIcon as SolidBoltIcon } from '@heroicons/react/24/solid';
import { BoltIcon as OutlineBoltIcon } from '@heroicons/react/24/outline';
import TablePagination from 'rsuite/lib/Table/TablePagination'
import Tooltip from '@/components/TailwindTooltip'

interface ICheckboxesConfig {
    handleSelectAll: (checked: boolean) => void
    handleCheckboxChange: (activationId: number) => void
    selected: Set<number>
    allSelected: boolean
    disableOfflineHashtags: boolean
}

interface IProps {
    activations: IActivation[]
    checkboxesConfig?: ICheckboxesConfig
    page: number
    total: number
    pageSize: number
    searchTerm: string | undefined
    currentTab: string
    onChangePageSize: (size: number) => void
    onChangePage: (page: number) => void
    clearSearchFilter: () => void
}

const CHECKBOX_MAX_WIDTH = 130;
const ADGROUP_MAX_WIDTH = 250;
const HASHTAG_MAX_WIDTH = 100;
const DATE_MAX_WIDTH = 100;

const isTextTruncated = (element: HTMLElement | null) => {
    return element ? element.scrollWidth > element.clientWidth : false;
};

const ActivationsTable = ({ activations, checkboxesConfig, page, total, pageSize, searchTerm, currentTab, onChangePageSize, onChangePage,  clearSearchFilter }: IProps) => {

    return (
        <>
        {searchTerm && searchTerm.length > 0 && (!activations || activations.length <= 0) ? (
            <>
            <div className="flex flex-col justify-center items-center text-center space-y-4 w-[30%] m-auto">
                <MagnifyingGlassIcon className="h-16 w-16 text-gray-500" />

                <h2 className="font-bold text-lg">No results for "{searchTerm}"</h2>

                <p className="text-lg">
                    You may want to try using different search terms, checking for typos, or adjusting filters.
                </p>

                <a
                    href="#"
                    className="text-sightly-blue text-lg hover:no-underline"
                    onClick={clearSearchFilter}
                >
                    Clear search bar
                </a>
            </div>
            </> ) : (
        <>
        <Table
            loading={false}
            autoHeight
            data={activations}
            bordered
            className='shadow-md rounded-md px-2'
            data-testid="activations-activations-results-table"
        >
            {checkboxesConfig && (<TableColumnJs width={CHECKBOX_MAX_WIDTH} resizable>
                <Table.HeaderCell>
                    <SightlyCheckbox
                        id={'select-all-activations-hashtags'}
                        handleChange={checkboxesConfig.handleSelectAll ?? (() => { })}
                        checked={checkboxesConfig.selected.size >= activations.length && checkboxesConfig.selected.size > 0}
                        indeterminate={checkboxesConfig.selected.size > 0 && checkboxesConfig.selected.size < activations.length}
                        disabled={!activations || activations.length < 1} />
                    {checkboxesConfig.selected.size > 0 && <span className="ml-4">{checkboxesConfig.selected.size} selected</span>}
                </Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: number }) => {
                        return <SightlyCheckbox
                            id={`select-${rowData.activationId}-activations-hashtag`}
                            handleChange={() => checkboxesConfig.handleCheckboxChange(rowData.activationId)}
                            dataTestId={`activations-activations-results-table-select-column-${rowData.activationId}`}
                            checked={checkboxesConfig.selected.has(rowData.activationId)} />
                    }}
                </Table.Cell>
            </TableColumnJs>)}
            <TableColumnJs width={DATE_MAX_WIDTH} resizable>
                <Table.HeaderCell>Date added</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, createdAt: string }) => {
                        const date = new Date(rowData.createdAt)
                        const month = String(date.getMonth() + 1).padStart(2, '0')
                        const day = String(date.getDate()).padStart(2, '0')
                        const year = String(date.getFullYear()).slice(-2)
                        return <p data-testid={`activations-activations-results-table-date-column-${rowData.activationId}`}>{`${month}/${day}/${year}`}</p>
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs flexGrow={1} >
                <Table.HeaderCell>Hashtag</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, hashtagName: string }) => {
                        return (
                            <Tooltip 
                                content={<div className="text-left">#{rowData.hashtagName}</div>} 
                                disabled={!isTextTruncated(document.getElementById(`activations-activations-results-table-hashtag-column-${rowData.activationId}`))}
                            >
                                <p 
                                    id={`activations-activations-results-table-hashtag-column-${rowData.activationId}`} 
                                    data-testid={`activations-activations-results-table-hashtag-column-${rowData.activationId}`} 
                                    className='truncate'>
                                    #{rowData.hashtagName}
                                </p>
                            </Tooltip>
                        )
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs width={HASHTAG_MAX_WIDTH}>
                <Table.HeaderCell>Hashtag status</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, hashtagStatus: string }) => {
                        if ('ONLINE' === rowData.hashtagStatus) {
                            return <SolidBoltIcon className={"h-4 text-sightlyPurple"} data-testid={`activations-activations-results-table-hashtag-status-column-${rowData.hashtagStatus}`} />
                        } else {
                            return <OutlineBoltIcon className={"h-4 text-sightlyPurple"} data-testid={`activations-activations-results-table-hashtag-status-column-${rowData.hashtagStatus}`} />
                        }
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs flexGrow={1} >
                <Table.HeaderCell>Campaign</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, campaignName: string }) => {
                        return (
                            <Tooltip 
                                content={<div className="text-left">{rowData.campaignName}</div>} 
                                disabled={!isTextTruncated(document.getElementById(`activations-activations-results-table-campaign-column-${rowData.activationId}`))}
                            >
                                <p 
                                    id={`activations-activations-results-table-campaign-column-${rowData.activationId}`} 
                                    data-testid={`activations-activations-results-table-campaign-column-${rowData.activationId}`} 
                                    className='truncate'>
                                    {rowData.campaignName}
                                </p>
                            </Tooltip>
                        )
                    }}
                </Table.Cell>
            </TableColumnJs>

            <TableColumnJs width={ADGROUP_MAX_WIDTH} resizable>
                <Table.HeaderCell>Adgroup</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, adGroupName: string }) => {
                        return (
                            <Tooltip 
                                content={<div className="text-left">{rowData.adGroupName}</div>} 
                                disabled={!isTextTruncated(document.getElementById(`activations-activations-results-table-adgroup-column-${rowData.activationId}`))}
                            >
                                <p 
                                    id={`activations-activations-results-table-adgroup-column-${rowData.activationId}`} 
                                    data-testid={`activations-activations-results-table-adgroup-column-${rowData.activationId}`} 
                                    className={`w-[${ADGROUP_MAX_WIDTH - 50}px] truncate`}>
                                    {rowData.adGroupName}
                                </p>
                            </Tooltip>
                        )
                    }}
                </Table.Cell>
            </TableColumnJs>
         { currentTab === 'FAILED' ? 
            <TableColumnJs width={ADGROUP_MAX_WIDTH} resizable>
                <Table.HeaderCell>Error Message</Table.HeaderCell>
                <Table.Cell>
                    {(rowData: { activationId: string, errorMessage: string }) => {
                        return (
                            <Tooltip 
                                content={<div className="text-left">{rowData.errorMessage || ""}</div>} 
                                disabled={!isTextTruncated(document.getElementById(`activations-activations-results-table-error-message-column-${rowData.activationId}`))}
                            >
                                <p 
                                    id={`activations-activations-results-table-error-message-column-${rowData.activationId}`} 
                                    data-testid={`activations-activations-results-table-error-message-column-${rowData.activationId}`} 
                                    className={`truncate`}>
                                    {rowData.errorMessage || ""}
                                </p>
                            </Tooltip>
                        )
                    }}
                </Table.Cell>
            </TableColumnJs> : null 
            }
        </Table>
            <TablePagination
                activePage={page}
                total={total}
                onChangePage={onChangePage} 
                onChangeLength={onChangePageSize}
                lengthMenu={[
                    {
                        value: 10,
                        label: 10
                    },
                    {
                        value: 20,
                        label: 20
                    }
                ]}
                renderTotal={() => {
                    return <div>Page {page}</div>
                }}
                displayLength={pageSize}
            />
        </>
        )}
        </>
    )
}

export default ActivationsTable