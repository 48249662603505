import React from 'react'
import { Link } from '@tanstack/react-location'
import SightlyInputForm from '@/components/Sightly/SightlyFormElements/SightlyInputForm'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { useMutation } from '@tanstack/react-query'
import { api } from '@/views/Admin/Scenarios/api'
import { LoadingPage } from '@/components/LoadingPage'
import { CheckTreePicker } from 'rsuite'
import { PostScenarioSchema, PostScenarioType } from '@/views/Admin/Scenarios/types'
import { FormLabel } from '@/components/Sightly/SightlyFormElements/SightlyFormLabel'
import SightlyButton from '@/components/Sightly/SightlyButton'
import { FormError } from '@/components/Sightly/SightlyFormElements/SightlyFormError'
import toast from 'react-hot-toast'
import { isAxiosError } from '@/utils_ts'
import { logError } from '@/utils'
import { useThemes } from '@/views/Admin/Scenarios/useThemes'

export const CreateScenario = () => {
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    control,
    getValues,
    formState: { errors, isDirty, isValid }
  } = useForm<PostScenarioType>({
    mode: 'all',
    criteriaMode: 'all',
    defaultValues: {
      name: '',
      themes: []
    },
    resolver: zodResolver(PostScenarioSchema)
  })

  const onSubmit = (scenario: PostScenarioType) => {
    const themes = scenario.themes.map((t) => ({ id: t }))
    submitMutation.mutate({ ...scenario, themes })
  }

  const submitMutation = useMutation(api.scenarios.post, {
    onSuccess: () => {
      toast.success('Changes saved')
    },
    onError: (err) => {
      if (isAxiosError<any>(err)) {
        // Access to config, request, and response
        const isDuplicateNameError = err?.response?.data.includes('already exists')
        if (isDuplicateNameError) {
          toast.error('That name is already used, please try another name')
        } else {
          toast.error('Unknown error ocurred')
          logError(err, { info: 'error posting scenario' })
        }
      } else {
        toast.error('Unknown error ocurred')
        logError(err, { info: 'error posting scenario' })
      }
    }
  })

  const themesQuery = useThemes({ includeDeleted: false })

  if (themesQuery.isLoading) {
    return <LoadingPage message="Fetching data" />
  }

  return (
    <div className="w-full flex justify-center items-center mt-12">
      <div className="w-3/6">
        <Link to={'../'}>Back to Scenarios</Link>
        <div className="mt-2 md:flex md:items-center md:justify-between">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
              Create Scenario
            </h2>
          </div>
        </div>
        <form
          className="w-full space-y-4 mt-8"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SightlyInputForm
            control={control}
            id="name"
            name="name"
            register={register}
            label="Name"
            required
            autoFocus
            error={errors?.name?.message}
          />

          <div>
            <FormLabel
              text="Themes"
              id="themes"
              required
            />
            <CheckTreePicker
              preventOverflow
              data={themesQuery.data || []}
              labelKey="name"
              valueKey="id"
              onChange={(v) => {
                setValue('themes', v, {
                  shouldValidate: true,
                  shouldDirty: true
                })
              }}
              block
            />
            <FormError
              id="message"
              error={errors?.themes?.message}
            />
          </div>

          <SightlyButton
            id="submitButton"
            handleClick={() => undefined}
            text="Submit"
            disabled={!isDirty || !isValid || getValues().themes.length === 0}
          />
        </form>
      </div>
    </div>
  )
}
