export enum REPORT_TYPE {
    DETAIL = 'detail',
    // SUMMARY = 'summary', TODO: To be implemented with the Performance Summary report
}

export enum GRANULARITY {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year',
    SUMMARY = 'summary',
}

export enum GROUPING {
    INSERTION_ORDER = 'insertionOrder',
    REPORT = 'report',
    AD_FORMAT = 'adFormat',
}

export enum TEMPLATE_TYPE {
    AGE = 'age',
    AUDIENCE = 'audience',
    CREATIVE = 'creative',
    DEVICE = 'device',
    DMA_REGION = 'dmaRegion',
    GENDER = 'gender',
    GOOGLE_PLACEMENT = 'placement',
    HOUSEHOLD_INCOME= 'householdIncome',
    INDUSTRY_CATEGORY = 'industryCategory',
    KEYWORD= 'keyword',
    MOMENT = 'moment',
    MOMENT_KEYWORD = 'momentKeyword',
    MOMENT_SCENARIO = 'scenario',
    NEWS_CATEGORY = 'newsCategory',
    PARENTAL_STATUS = 'parentalStatus',
    TOPIC = 'topic',
}


// Shared dimensions across all TEMPLATE_TYPE's
export enum GENERIC_DIMENSION {
    ACCOUNT_ID = 'accountId',
    ACCOUNT_NAME = 'accountName',
    AD_FORMAT = 'adFormat',
    AD_GROUP_ID = 'adGroupId',
    AD_GROUP_NAME = 'adGroupName',
    CAMPAIGN_ID = 'campaignId',
    CAMPAIGN_NAME = 'campaignName',
    CLIENT_PACKAGE_NAME = 'clientPackageName',
    CLIENT_PACKAGE_ID = 'clientPackageId',
    CLIENT_PLACEMENT_ID = 'clientPlacementId',
    CLIENT_PLACEMENT_NAME = 'clientPlacementName',
    CUSTOMER_ID = 'customerId',
    CUSTOMER_NAME = 'customerName',
    INSERTION_ORDER_NAME = 'insertionOrderName',
    INSERTION_ORDER_ID = 'insertionOrderId',
    PERSONA = 'persona',
    PARTNER = 'partner',
    PLATFORM = 'platform',
    SIGHTLY_PLACEMENT_ID = 'sightlyPlacementId',
    SIGHTLY_PLACEMENT_NAME = 'sightlyPlacementName',
}


export enum METRIC {
    CLICKS ='clicks',
    COST ='cost',
    CPM ='cpm',
    CPV ='cpv',
    CPC ='cpc',
    CTR ='ctr',
    IMPRESSIONS ='impressions',
    MARGIN ='margin',
    QUARTILE_25 ='q25',
    QUARTILE_50 ='q50',
    QUARTILE_75 ='q75',
    QUARTILE_100 ='q100',
    STAT_DATE = 'statDate',
    VCR ='vcr',
    VIEWS ='views',
    VIEW_RATE ='viewRate',
}

export enum CONVERSION {
    ALL_CONVERSIONS = 'allConversions',
    ADD_TO_CART = 'addToCart',
    BEGIN_CHECKOUT = 'beginCheckout',
    BOOK_APPOINTMENT = 'bookAppointment',
    CONTACT = 'contact',
    CONVERTED_LEAD = 'convertedLead',
    // DEFAULT = 'default',
    DOWNLOAD = 'download',
    ENGAGEMENT = 'engagement',
    GET_DIRECTIONS = 'getDirections',
    IMPORTED_LEAD = 'importedLead',
    OUTBOUND_CLICK = 'outboundClick',
    PAGE_VIEW = 'pageView',
    PAGE_VISIT = 'pageVisit',
    PHONE_CALL_LEAD = 'phoneCallLead',
    PURCHASE = 'purchase',
    QUALIFIED_LEAD = 'qualifiedLead',
    REQUEST_QUOTE = 'requestQuote',
    SIGNUP = 'signup',
    STORE_SALE = 'storeSale',
    STORE_VISIT = 'storeVisit',
    SUBMIT_LEAD_FORM = 'submitLeadForm',
    SUBSCRIBE_PAID = 'subscribePaid',
    // UNKNOWN = 'unknown',
    // UNSPECIFIED = 'unspecified',
}

export interface BaseOptions{
    name: string,
    description: string
}
export interface GenericDimensionOption extends BaseOptions{
    value: GENERIC_DIMENSION
}

export interface MetricOption extends BaseOptions{
    value: METRIC
}

export interface ConversionOption extends BaseOptions{
    value: CONVERSION
}

export interface InternalColumn {
    name: string;
    value: string; 
    category: string;
    description: string;
}

interface ReportConfiguration {
    name: string,
    type: REPORT_TYPE,
    templateType: TEMPLATE_TYPE
    columns: (GENERIC_DIMENSION | METRIC | CONVERSION | string | InternalColumn | undefined)[]
    icon?: string
}

export interface ReportTemplate extends ReportConfiguration{
    description: string
    dimensions: GenericDimensionOption[]
    metrics: MetricOption[]
    conversions: ConversionOption[]
}

export interface InsertionOrder {
    id: number,
    name: string
}

export interface ActivationReportConfiguration {
    name: string,
    insertionOrders: InsertionOrder[],
    startDate: Date,
    endDate: Date,
    granularity: GRANULARITY
    grouping: GROUPING
    reports: ReportConfiguration[]
}

export type Option<T> = {
    [key: string]: T;
}

export const genericDimensionOptions: Option<GenericDimensionOption> = {
    [GENERIC_DIMENSION.ACCOUNT_ID]: {
        name: 'Account Id',
        value: GENERIC_DIMENSION.ACCOUNT_ID,
        description: 'Unique identifier for an account' // Generated description
    },
    [GENERIC_DIMENSION.ACCOUNT_NAME]: {
        name: 'Account Name',
        value: GENERIC_DIMENSION.ACCOUNT_NAME,
        description: 'Name associated with an account' // Generated description
    },
    [GENERIC_DIMENSION.AD_FORMAT]: {
        name: 'Ad Format',
        value: GENERIC_DIMENSION.AD_FORMAT,
        description: 'Type of advertisement format' // Generated description
    },
    [GENERIC_DIMENSION.INSERTION_ORDER_ID]: {
        name: 'Insertion Order Id',
        value: GENERIC_DIMENSION.INSERTION_ORDER_ID,
        description: 'Unique identifier for an insertion order' // Generated description
    },
    [GENERIC_DIMENSION.INSERTION_ORDER_NAME]: {
        name: 'Insertion Order Name',
        value: GENERIC_DIMENSION.INSERTION_ORDER_NAME,
        description: 'Name of the Insertion Order (IO) associated with the campaign' 
    },
    [GENERIC_DIMENSION.CLIENT_PACKAGE_ID]: {
        name: 'Client Package Id',
        value: GENERIC_DIMENSION.CLIENT_PACKAGE_ID,
        description: 'Unique identifier for a client package' // Generated description
    },
    [GENERIC_DIMENSION.CLIENT_PACKAGE_NAME]: {
        name: 'Client Package Name',
        value: GENERIC_DIMENSION.CLIENT_PACKAGE_NAME,
        description: 'Name assigned to a client package' // Generated description
    },
    [GENERIC_DIMENSION.CLIENT_PLACEMENT_ID]: {
        name: 'Client Placement Id',
        value: GENERIC_DIMENSION.CLIENT_PLACEMENT_ID,
        description: 'Unique identifier for a client placement' // Generated description
    },
    [GENERIC_DIMENSION.CLIENT_PLACEMENT_NAME]: {
        name: 'Client Placement Name',
        value: GENERIC_DIMENSION.CLIENT_PLACEMENT_NAME,
        description: 'Name assigned to a client placement' // Generated description
    },
    [GENERIC_DIMENSION.SIGHTLY_PLACEMENT_ID]: {
        name: 'Sightly Placement Id',
        value: GENERIC_DIMENSION.SIGHTLY_PLACEMENT_ID,
        description: 'Unique identifier for a Sightly placement' // Generated description
    },
    [GENERIC_DIMENSION.SIGHTLY_PLACEMENT_NAME]: {
        name: 'Sightly Placement Name',
        value: GENERIC_DIMENSION.SIGHTLY_PLACEMENT_NAME,
        description: 'Name assigned to a Sightly placement' // Generated description
    },
    [GENERIC_DIMENSION.PERSONA]: {
        name: 'Persona',
        value: GENERIC_DIMENSION.PERSONA,
        description: 'Target Audience'
    },
    [GENERIC_DIMENSION.PARTNER]: {
        name: 'Partner',
        value: GENERIC_DIMENSION.PARTNER,
        description: 'Target Audience'
    },
    [GENERIC_DIMENSION.PLATFORM]: {
        name: 'Platform',
        value: GENERIC_DIMENSION.PLATFORM,
        description: 'The platform where the ad was displayed' // Generated description
    },
    [GENERIC_DIMENSION.CUSTOMER_ID]: {
        name: 'Customer Id',
        value: GENERIC_DIMENSION.CUSTOMER_ID,
        description: 'Customer ID for the ad campaign' // Generated description
    },
    [GENERIC_DIMENSION.CUSTOMER_NAME]: {
        name: 'Customer Name',
        value: GENERIC_DIMENSION.CUSTOMER_NAME,
        description: 'Customer name for the ad campaign' // Generated description
    },
    [GENERIC_DIMENSION.CAMPAIGN_ID]: {
        name: 'Campaign Id',
        value: GENERIC_DIMENSION.CAMPAIGN_ID,
        description: 'Campaign ID (CID) for the ad campaign'
    },
    [GENERIC_DIMENSION.CAMPAIGN_NAME]: {
        name: 'Campaign Name',
        value: GENERIC_DIMENSION.CAMPAIGN_NAME,
        description: 'Name of the advertising campaign' // Generated description
    },
    [GENERIC_DIMENSION.AD_GROUP_ID]: {
        name: 'Ad Group Id',
        value: GENERIC_DIMENSION.AD_GROUP_ID,
        description: 'Id of the ad group within the campaign'
    },
    [GENERIC_DIMENSION.AD_GROUP_NAME]: {
        name: 'Ad Group Name',
        value: GENERIC_DIMENSION.AD_GROUP_NAME,
        description: 'Name of the ad group within the campaign'
    },
}

export const metricOptions: Option<MetricOption> = {
    [METRIC.STAT_DATE]: {
        name: 'Date',
        value: METRIC.STAT_DATE,
        description: 'The date for which the data is reported (Daily or Aggregated for the Flight)'
    },
    [METRIC.IMPRESSIONS]: {
        name: 'Impressions',
        value: METRIC.IMPRESSIONS,
        description: 'Number of times your ad was displayed'
    },
    [METRIC.VIEWS]: {
        name: 'Views',
        value: METRIC.VIEWS,
        description: 'Percentage of impressions that resulted in a view'
    },
    [METRIC.VIEW_RATE]: {
        name: 'View Rate',
        value: METRIC.VIEW_RATE,
        description: 'Percentage of impressions that resulted in a view'
    },
    [METRIC.VCR]: {
        name: 'VCR',
        value: METRIC.VCR,
        description: 'Measures the percentage of viewers who watch a video ad in its entirety'
    },
    [METRIC.CLICKS]: {
        name: 'Clicks',
        value: METRIC.CLICKS,
        description: 'Number of times viewers clicked on your ad'
    },
    [METRIC.CPM]: {
        name: 'CPM',
        value: METRIC.CPM,
        description: 'Number of times viewers watched part or all of your ad'
    },
    [METRIC.CPV]: {
        name: 'CPV',
        value: METRIC.CPV,
        description: 'Cost per video view'
    },
    [METRIC.CPC]: {
        name: 'CPC',
        value: METRIC.CPC,
        description: 'Cost per ad click'
    },
    [METRIC.CTR]: {
        name: 'CTR',
        value: METRIC.CTR,
        description: 'Percentage of viewers who clicked on your ad'
    },
    [METRIC.COST]: {
        name: 'Cost',
        value: METRIC.COST,
        description: 'Total cost of the campaign for the specified date range'
    },
    [METRIC.MARGIN]: {
        name: 'Margin',
        value: METRIC.MARGIN,
        description: 'The difference between ad revenue and ad costs'
    },
    [METRIC.QUARTILE_25]: {
        name: 'Video Quartile 25%',
        value: METRIC.QUARTILE_25,
        description: 'Percentage of viewers who watched at least 25% of your ad'
    },
    [METRIC.QUARTILE_50]: {
        name: 'Video Quartile 50%',
        value: METRIC.QUARTILE_50,
        description: 'Percentage of viewers who watched at least 50% of your ad'
    },
    [METRIC.QUARTILE_75]: {
        name: 'Video Quartile 75%',
        value: METRIC.QUARTILE_75,
        description: 'Percentage of viewers who watched at least 75% of your ad'
    },
    [METRIC.QUARTILE_100]: {
        name: 'Video Quartile 100%',
        value: METRIC.QUARTILE_100,
        description: 'Percentage of viewers who watched your entire ad'
    },
}

export const conversionOptions: Option<ConversionOption> = {
    [CONVERSION.ALL_CONVERSIONS]: {
        name: 'All Conversions',
        value: CONVERSION.ALL_CONVERSIONS,
        description: 'Total count of all conversion actions' // Generated description
    },
    [CONVERSION.ADD_TO_CART]: {
        name: 'Add To Cart',
        value: CONVERSION.ADD_TO_CART,
        description: 'Instances of adding items to a shopping cart' // Generated description
    },
    [CONVERSION.BEGIN_CHECKOUT]: {
        name: 'Begin Checkout',
        value: CONVERSION.BEGIN_CHECKOUT,
        description: 'Initiation of the checkout process' // Generated description
    },
    [CONVERSION.BOOK_APPOINTMENT]: {
        name: 'Book Appointment',
        value: CONVERSION.BOOK_APPOINTMENT,
        description: 'Scheduling of an appointment' // Generated description
    },
    [CONVERSION.CONVERTED_LEAD]: {
        name: 'Converted Lead',
        value: CONVERSION.CONVERTED_LEAD,
        description: 'Leads that have been successfully converted' // Generated description
    },
    // [CONVERSION.DEFAULT]: {
    //     name: 'Default',
    //     value: CONVERSION.DEFAULT
    // },
    [CONVERSION.DOWNLOAD]: {
        name: 'Download',
        value: CONVERSION.DOWNLOAD,
        description: 'Instances of downloading content' // Generated description
    },
    [CONVERSION.ENGAGEMENT]: {
        name: 'Engagement',
        value: CONVERSION.ENGAGEMENT,
        description: 'Interactions indicating user engagement' // Generated description
    },
    [CONVERSION.GET_DIRECTIONS]: {
        name: 'Get Directions',
        value: CONVERSION.GET_DIRECTIONS,
        description: 'Requests for location directions' // Generated description
    },
    [CONVERSION.IMPORTED_LEAD]: {
        name: 'Imported Lead',
        value: CONVERSION.IMPORTED_LEAD,
        description: 'Leads imported from external sources' // Generated description
    },
    [CONVERSION.OUTBOUND_CLICK]: {
        name: 'Outbound Click',
        value: CONVERSION.OUTBOUND_CLICK,
        description: 'Clicks leading to external sites' // Generated description
    },
    [CONVERSION.PAGE_VIEW]: {
        name: 'Page View',
        value: CONVERSION.PAGE_VIEW,
        description: 'Views of a specific page' // Generated description
    },
    [CONVERSION.PHONE_CALL_LEAD]: {
        name: 'Phone Call Lead',
        value: CONVERSION.PHONE_CALL_LEAD,
        description: 'Leads generated through phone calls' // Generated description
    },
    [CONVERSION.QUALIFIED_LEAD]: {
        name: 'Qualified Lead',
        value: CONVERSION.QUALIFIED_LEAD,
        description: 'Leads that meet qualification criteria' // Generated description
    },
    [CONVERSION.REQUEST_QUOTE]: {
        name: 'Request Quote',
        value: CONVERSION.REQUEST_QUOTE,
        description: 'Requests for pricing or quotes' // Generated description
    },
    [CONVERSION.SIGNUP]: {
        name: 'Signup',
        value: CONVERSION.SIGNUP,
        description: 'Registrations or signups completed' // Generated description
    },
    [CONVERSION.STORE_SALE]: {
        name: 'Store Sale',
        value: CONVERSION.STORE_SALE,
        description: 'Sales completed in a store' // Generated description
    },
    [CONVERSION.SUBMIT_LEAD_FORM]: {
        name: 'Submit Lead Form',
        value: CONVERSION.SUBMIT_LEAD_FORM,
        description: 'Submission of lead generation forms' // Generated description
    },
    [CONVERSION.SUBSCRIBE_PAID]: {
        name: 'Subscriber paid',
        value: CONVERSION.SUBSCRIBE_PAID,
        description: 'Paid subscriptions completed' // Generated description
    },
    // [CONVERSION.UNKNOWN]: {
    //     name: 'Unknown',
    //     value: CONVERSION.UNKNOWN
    // },
    // [CONVERSION.UNSPECIFIED]: {
    //     name: 'Unspecified',
    //     value: CONVERSION.UNSPECIFIED
    // },
    [CONVERSION.CONTACT]: {
        name: 'Contact',
        value: CONVERSION.CONTACT,
        description: 'Instances of contact made' // Generated description
    },
    [CONVERSION.PAGE_VISIT]: {
        name: 'Page Visit',
        value: CONVERSION.PAGE_VISIT,
        description: 'Visits to a specific page' // Generated description
    },
    [CONVERSION.STORE_VISIT]: {
        name: 'Store Visit',
        value: CONVERSION.STORE_VISIT,
        description: 'Visits to a physical store location' // Generated description
    },
    [CONVERSION.PURCHASE]: {
        name: 'Purchase',
        value: CONVERSION.PURCHASE,
        description: 'Completed purchase transactions' // Generated description
    },
}

export const templateGenericDimensionOptions:GenericDimensionOption[] = Object.keys(genericDimensionOptions).map(key => genericDimensionOptions[key])
export const templateMetricOptions:MetricOption[] = Object.keys(metricOptions).map(key => metricOptions[key])
export const templateConversionOptions:ConversionOption[] = Object.keys(conversionOptions).map(key => conversionOptions[key])
