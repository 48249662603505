import { useQuery } from '@tanstack/react-query';
import { userAccountAxios } from '@/axiosInstances';
import { rqKeys } from '@/ReactQueryKeyFactory';
import { z } from 'zod';
import { logError } from '@/utils';

export enum sectionsEnum {
    'competitors' = 'competitors',
    'influencers' = 'influencers',
    'events' = 'events',
    'philanthropic' = 'philanthropic',
    'socialAndEnvironmental' = 'socialAndEnvironmental'
}

export type Question = {
    brandProfileId?: number
    keywords: string[]
    question?: string
    questionId: number
    responseText: string
}

const StatusSchema = z.object({
    brandProfileNameComplete: z.boolean(),
    opinionIncompleteCount: z.number(),
    scenarioV2IncompleteCount: z.number(),
    twitterComplete: z.boolean(),
    websiteComplete: z.boolean(),
    queriesComplete: z.boolean()
})

export type BrandProfileStatusType = z.infer<typeof StatusSchema>

const getBrandProfileStatus = async (brandProfileId: number) => {
    const url = `/brand-profile/${brandProfileId}/status`

    const result = await userAccountAxios.get(url)

    return StatusSchema.parse(result.data)
}

const useBrandProfile = (brandProfileId: number) => {
    const {
        data: status,
        isFetched: statusIsFetched,
        isLoading: statusIsLoading
    } = useQuery(rqKeys.brandProfileStatusKey(brandProfileId), () => getBrandProfileStatus(brandProfileId), {
        enabled: brandProfileId !== null && brandProfileId > 0,
        onError: (err) => {
            logError(err)
        }
    })

    return {
        status,
        statusIsFetched,
        statusIsLoading
    }
}

export default useBrandProfile
