import React from 'react'
import { Button, Modal } from 'rsuite';

export interface IProps {
    /** Title to be displayed in the Information Modal */
    title: string
    /** Information messages to be displayed in the Information Modal */
    informationMessages: string[]
    /** Variable to show or hide the Information Modal */
    isInformatioModalVisible: boolean
    /** Function to set the variable to show or hide the Information Modal */
    setIsInformatioModalVisible: Function
}

/**
 * Component to display in the Loading Modal.
 * @prop {string} title Title to be displayed in the Information Modal.
 * @prop {string[]} informationMessages Information messages to be displayed in the Information Modal.
 * @prop {boolean} isInformatioModalVisible Variable to show or hide the Information Modal.
 * @prop {Function} setIsInformatioModalVisible Function to set the variable to show or hide the Information Modal.

 */

export const InformationModal = ( { title, informationMessages, isInformatioModalVisible, setIsInformatioModalVisible } : IProps) => {

    const handleCloseErrorModal = () => {
        setIsInformatioModalVisible(false)
    }

    return (
        <div>
            <Modal
                data-testid='div-report-generator-error-modal'
                show={isInformatioModalVisible}
                onHide={handleCloseErrorModal}
            >
                <Modal.Header>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {informationMessages.map((message, index) => (
                        <p key={index}>{message}</p>
                    ))}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        id='close-modal-error-button'
                        data-testid={'close-modal-error-button'}
                        className='rounded-md w-[129px] h-[40px]'
                        appearance="primary"
                        style={{ backgroundColor: '#7B61FF' }}
                        onClick={handleCloseErrorModal}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
