import React from 'react'
import SightlyButton from '@/components/Sightly/SightlyButton';
import { useNavigate, useSearch } from '@tanstack/react-location';
import { routes } from '@/routes';
import { MyLocationGenerics } from '@/classes/utils';
import { segmentsController } from '@/views/Engage/Segments/controller/SegmentController';
import { useQuery } from '@tanstack/react-query';
import { SegmentsViewTable } from '@/views/Engage/Segments/components/SegmentsTable';

export const ViewSegments = () => {
    const navigate = useNavigate()
    const { accountId } = useSearch<MyLocationGenerics>()

    const page = 1
    const pageSize = 10

    const orderBy = 'updatedAt';
    const sortBy = 'desc';

    const {
        data: tableData
    } = useQuery(['getSegments', accountId, page, pageSize, orderBy, sortBy], segmentsController.getSegments)

    const hasTableData = tableData != undefined ? tableData['results'].length : 0

    return (
        <>
            <div className="flex justify-between mt-8 mx-8 mb-4 pb-0" data-testid="segments-table">

                <div className="sightlyPanelHeader" data-testid="segments-table-header">
                    <div className="flex flex-row">
                        Segments
                    </div>
                </div>

                {hasTableData ? (
                    <div>
                        <SightlyButton
                            datatestid={'button-smart-list-create-new-segment'}
                            id="createSegmentButton"
                            text="New Segment"
                            type='purple'
                            disabled={false}
                            handleClick={() => {
                                // navigate({
                                //     to: routes.app.engage.segments.new.path,
                                // })
                            }}
                        />
                    </div>
                ) : null}

            </div>
            {hasTableData
                ?
                <div className="mx-8" data-testid="segments-table-component">
                    <SegmentsViewTable tableData={tableData} accountId={accountId!} />
                </div>
                :
                <div className="w-full flex items-center flex-col" data-testid="segments-table-create">
                    <div className="flex items-center flex-col pt-32">
                        <p>
                            <b>No Segments</b>
                        </p>
                        <p>Get started by creating a new Segment</p>
                        <div className="p-4">
                            <SightlyButton
                                datatestid={'button-smart-list-new-segment'}
                                id="createSegmentButton"
                                text="+ New Segment"
                                disabled={false}
                                type='purple'
                                handleClick={() => {
                                    // navigate({
                                    //     to: routes.app.engage.segments.new.path,
                                    //     search: (search) => ({ accountId: search?.accountId })
                                    // })
                                }}
                            />
                        </div>
                    </div>
                </div>

            }
        </>
    )
}
