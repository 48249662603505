import { InfoPage } from '@/components/InfoPage';
import { LoadingPage } from '@/components/LoadingPage';
import ArticleResultsList from '@/views/Trends/TrendDetails/components/ArticleResultsList';
import SocialPostResultsList from '@/views/Trends/TrendDetails/components/SocialPostResultsList';
import SankeyGraphComponent from '@/views/Trends/components/SankeyGraphComponent';
import { IArticle, ISocialPost } from '@/views/Trends/types';
import { Tabs } from 'flowbite-react';
import React from 'react';

interface ISanKeyData {
  momentNodes: {
    type: string;
    name: string;
  }[];
  momentLinks: {
    value: number;
    source: number;
    target: number;
  }[];
  narrativeNodes: {
    type: string;
    name: string;
  }[];
  narrativeLinks: {
    value: number;
    source: number;
    target: number;
  }[];
}

interface IProps {
  trendId?: string;
  topicId?: string;
  articles: IArticle[],
  articlesError: any,
  articlesLoading: boolean,
  socialPosts: ISocialPost[],
  socialPostsError: any,
  socialPostsLoading: boolean,
  sanKeyData?: ISanKeyData,
  sanKeyLoading: boolean
}

const DetailDisplaySections = ({ topicId, trendId, articles, articlesError, articlesLoading, socialPosts,
  socialPostsError, socialPostsLoading, sanKeyData, sanKeyLoading }: IProps) => {

  const sankeyMomentdData = sanKeyData?.momentNodes && sanKeyData.momentNodes.length > 0
    ? {
      nodes: [...sanKeyData.momentNodes],
      links: [...sanKeyData.momentLinks]
    }
    : undefined;

  const sankeyNarrativeData = sanKeyData?.narrativeNodes && sanKeyData.narrativeNodes.length > 0
    ? {
      nodes: [...sanKeyData.narrativeNodes],
      links: [...sanKeyData.narrativeLinks]
    }
    : undefined;

  const renderContent = (tab: 'article' | 'social post', data: any[], isLoading: boolean, error: boolean) => {
    if (error) {
      return <InfoPage message={`There was an error fetching ${tab}s. Please contact support for help.`} />;
    }

    if (isLoading) {
      return <LoadingPage message={`Loading ${tab}s`} />;
    }

    if (data.length > 0) {
      if (tab === 'article') {
        return <div data-testid="articles-tab-panel">
          <ArticleResultsList articles={data} />
        </div>
      }
      return <div data-testid="social-posts-tab-panel">
        <SocialPostResultsList socialPosts={data} />
      </div>;
    }

    return <InfoPage data-testid={`${tab}-no-data`} message="No social posts to show." />;
  };



  return (
    <div className='w-full h-full'>
      <Tabs aria-label="Default tabs"
        style="underline"
        theme={{
          tablist: {
            tabitem: {
              base: 'flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:ring-0  focus:outline-none rounded-t-lg border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 border-bottom-color:sightlyPurple',
            },
          },
        }}
      >
        <Tabs.Item active title={"Trend Breakdown"}>
          <div data-testid="trend-breakdown-tab-panel" >
              <SankeyGraphComponent data-test loading={sanKeyLoading} dataNarratives={sankeyNarrativeData} dataMoments={sankeyMomentdData} trendId={trendId} topicId={topicId} />
          </div>
        </Tabs.Item>

        <Tabs.Item title={"Articles"} data-testid="articles-tab-panel">
          {renderContent('article', articles, articlesLoading, articlesError)}
        </Tabs.Item>
        <Tabs.Item title={"Social Posts"} data-testid="social-posts-tab-panel">
          {renderContent('social post', socialPosts, socialPostsLoading, socialPostsError)}
        </Tabs.Item>
      </Tabs>
    </div>
  )
}

export default DetailDisplaySections;
