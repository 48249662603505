import React, { useEffect, useState } from 'react';
import useBrandProfiles from '@/hooks/brandProfile/useBrandProfiles'
import useBoards, { Board } from './useBoards'
import {
  PATH_MOMENTS_V2_AI,
  PATH_MOMENTS_V2_CREATE_CUSTOM,
  PATH_SOCIAL_TOPICS_DASHBOARD,
  routes,
} from '@/routes'
import { Link, useNavigate } from '@tanstack/react-location'
import { location } from '@/RouteContainer'
import { MyLocationGenerics } from '@/classes/utils'
import useUser from '@/hooks/useUser'
import { accentColor } from '@/assets/jss/colorConstants'
import { BoardsCreatedGauge } from './components/BoardsCreatedGauge'
import { PlusIcon, SparklesIcon } from '@heroicons/react/20/solid'
import Tooltip from '@/components/TailwindTooltip'
import SightlyToggle from '@/components/Sightly/SightlyFormElements/SightlyToggle'
import DeleteBoardModal from './DeleteBoardModal'
import {SightlyPopover} from '@/components/Popover';
import { ReactComponent as BoardGroupIcon } from '@/assets/img/boardGroupIcon.svg'
import { ReactComponent as BoardColumnIcon } from '@/assets/img/boardColumnIcon.svg'
import GenerateAIModal from '@/components/Modals/AIGenerate/GenerateAIModal';
import { BoardFromTemplateModal } from './components/BoardFromTemplateModal';
import BoardSelectCard from '@/views/Discover/Boards/BoardSelectCard';
import BoardSelectSearch from '@/views/Discover/Boards/components/BoardSelectSearch';
import userCanPermissionProductQuota, { PermissionCheckType } from '@/services/userCanPermissionProductQuota';

type IProps = {
  handleBrandProfileChange: (brandProfileId: number) => void
  resetBrandProfileIdMoments?: (brandProfileId: number) => void
  brandProfileId: number | null | undefined
  boards: Board[]
  handleSelectBoard: (id: number) => void
  showBackToDashboards?: boolean
  selectedBoards: number[]
  hideNewBoardButton?: boolean
  handleSelectAllBoards?: () => void
  clearSelectedBoards?: () => void
  handleDeleteBoard?:() => void
}

const Sidebar = ({
  handleBrandProfileChange,
  resetBrandProfileIdMoments,
  brandProfileId,
  boards,
  handleSelectBoard,
  handleSelectAllBoards,
  showBackToDashboards,
  selectedBoards,
  hideNewBoardButton,
  clearSelectedBoards,
  handleDeleteBoard,
}: IProps) => {
  const navigate = useNavigate<MyLocationGenerics>()
  const navigateGenerateAI = useNavigate()
  const { products, perms, userPermissions, userProducts } = useUser()
  const { handleDelete, boardsQuotaTally, handleRename } = useBoards()
  const [showDeleteAlert, setShowDeleteAlert] = React.useState(false)

  //State to show a button fot the BoardFromTemplate until the we have the dropdown.
  const [ showBoardFromTemplate, setShowBoardFromTemplate] = React.useState(false)

  const [deletingBoardId, setDeletingBoardId] = React.useState<number>()
  const [visible, setVisible] = useState<boolean>(false);

  const {
    data: brandProfiles,
    isLoading: brandProfilesIsLoading,
    isFetched: brandProfilesIsFetched
  } = useBrandProfiles({ submittedOnly: true })

  // Handles the brandProfile change when the account is changed from the Horizontal Navbar
  useEffect(() => {
    brandProfileId && resetBrandProfileIdMoments && resetBrandProfileIdMoments(brandProfileId)
  }, [brandProfileId])

  const handleAIGenerateCancel = () => {
    setVisible(false);  // Close the modal
  };

  const handleAIGenerateClick = (data: string) => {
    navigateGenerateAI({
      to: PATH_MOMENTS_V2_AI,
      search: (old) => {
        return {
          ...old,
          aiPromptText: data,
          brandProfileId,
          editingBoard: false,
          boardId: null
        }
      }
    });
  };

  const handleAIGenerateOpen = () => {
    setVisible(true);
  };

  if (brandProfilesIsLoading || !brandProfilesIsFetched) return null;

  const items = [
    {
      id: 'create-ai',
      name: 'New Board with AI',
      icon: <SparklesIcon className="h-5" />,
      divider: true,
      handler: handleAIGenerateOpen
    },
    {
      id: 'createCustom',
      name: 'New Custom Board',
      icon: <BoardColumnIcon className="h-5" />,
      divider: false,
      handler: () => navigate({ to: PATH_MOMENTS_V2_CREATE_CUSTOM })
    },
    {
      id: 'createGeneral',
      name: 'New Board from Template',
      icon: <BoardGroupIcon className="h-5" />,
      divider: false,
      handler: () => setShowBoardFromTemplate(true)
    },
  ]

  return (
    <div className="h-full pt-5 overflow-y-auto sidebar">
      <div className="bp">
        <div className="relative" data-testid="moment-board-brand-profile-picker">
          <GenerateAIModal  show={visible} handleClick={handleAIGenerateClick} handleCancel = {handleAIGenerateCancel} header = "Build a Board About"/>

          <BoardSelectSearch
            value={brandProfileId}
            options={brandProfiles}
            label={"Brand Profile"}
            labelKey="brandProfileName"
            valueKey="brandProfileId"
            onChange={(val: number) => {
              handleBrandProfileChange(Number(val));
            }}
          />
        </div>

        { userCanPermissionProductQuota({
            requiredPermissionValue: perms.BRAND_PROFILE_UPDATE,
            requiredProductValue: products.BOARDS,
            userPermissions: userPermissions,
            userProducts: userProducts,
            checkType: PermissionCheckType.PRODUCT_PERMISSION_CHECK,
          }) && (
          <div className="flex justify-end w-full mt-1 ml-auto">
            <Link
              id="pendo-go-to-brand-profile-link"
              style={{ color: accentColor, fontWeight: 600, fontSize: 12 }}
              to={`${routes.app.brandProfiles.path}/brandProfile/${brandProfileId}/brandInformation`}
            >
              View this brand profile
            </Link>
          </div>
        )}
      </div>

      <div className="w-full">
        <h4 className="flex items-center justify-between my-4">
          Boards
          <div id='pendo-new-board-button'>
            <SightlyPopover
              items={items}
              popoverButtonClass="add-board-plus-button"
              icon={
                <Tooltip
                  disabled={!hideNewBoardButton}
                  content={<p>Board creation not available. Please check your board quota limit or user permissions</p>}
                >
                  <PlusIcon className="h-6 text-white rounded bg-sightlyPurple" />
                </Tooltip>
              }
              disabledButton={hideNewBoardButton}
            />
          </div>
        </h4>
        <div className="w-full mb-6 border border-solid border-slate-50" />
      </div>

      <div className="flex flex-col w-full h-full gap-4">
          {!location.current.pathname.includes('/create') && boards && boards.length > 0 && (
            <div className="flex gap-2 font-bold text-gray-700" data-testid="moment-board-selectAll">
              <SightlyToggle
                isDisabled={false}
                id="selectAllBoardToggle"
                switchClass="pendo-select-all-board-toggle-selector"
                setEnabled={(bool: boolean) => {
                  bool ? handleSelectAllBoards && handleSelectAllBoards() : clearSelectedBoards && clearSelectedBoards()
                }}
                enabled={boards.length === selectedBoards.length}
              />
              {'Select All'}
            </div>
          )}
          {!location.current.pathname.includes('boards/create') &&
            boards
              .map((board, index) => {
              return <BoardSelectCard
                key={`${index}-${board.name}`}
                index={index}
                board={board}
                handleSelectBoard={handleSelectBoard}
                selectedBoards={selectedBoards}
                perms={perms}
                navigate={navigate}
                setDeletingBoardId={setDeletingBoardId}
                setShowDeleteAlert={setShowDeleteAlert}
                handleRename={handleRename}
                userCanPermissionProductQuota={userCanPermissionProductQuota}
                products={products}
                userPermissions={userPermissions}
                userProducts={userProducts}                />
            })}

          <div className="mt-auto">
            {(userCanPermissionProductQuota({requiredProductValue: products.KRAFT_SOCIAL_METABASE_DASHBOARD, userProducts, checkType: PermissionCheckType.PRODUCT_CHECK})
              || userCanPermissionProductQuota({requiredProductValue: products.LULULEMON_SOCIAL_METABASE_DASHBOARD, userProducts, checkType: PermissionCheckType.PRODUCT_CHECK})
              || userCanPermissionProductQuota({requiredProductValue: products.DEMO_SOCIAL_METABASE_DASHBOARD, userProducts, checkType: PermissionCheckType.PRODUCT_CHECK}))
              && !location.current.pathname.includes('moments/v2/settings')
              && userCanPermissionProductQuota({
                  requiredPermissionValue: perms.REPORTING_READ,
                  userPermissions: userPermissions,
                  checkType: PermissionCheckType.PERMISSION_CHECK,
                })
              && (
              <button
                id='pendo-new-board-button'
                data-testid='sidebar-new-board'
                className="w-full p-2 my-2 font-semibold text-white rounded bg-sightlyBlue"
                onClick={() =>
                  navigate({
                    to: PATH_SOCIAL_TOPICS_DASHBOARD,
                  })
                }
              >
                Social Topics Dashboard
                <span className='px-2 ml-1 bg-purple-700 rounded-full text-zinc-50'>
                  New
                </span>
              </button>
            )}

            <BoardsCreatedGauge
              tally={boardsQuotaTally}
              backgroundColor="#f5f5f5"
            />
          </div>

          { showBoardFromTemplate && (
              <BoardFromTemplateModal handleClose={ () => setShowBoardFromTemplate(false) } open={ showBoardFromTemplate } ></BoardFromTemplateModal>
            )
          }
        </div>
      <style>
        {`
                .sidebar {
                    grid-area: sidebar;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding-left: 20px;
                    padding-right: 20px;
                    padding-bottom: 64px;
                    border-right: 2px solid #f8f8f8;
                }	
                .bp {
                    width: 100%;
                }
            `}
      </style>
      <DeleteBoardModal
        handleCancel={() => setShowDeleteAlert(false)}
        handleClose={() => setShowDeleteAlert(false)}
        handleDelete={() => {
          if (!deletingBoardId) return
          handleDeleteBoard && handleDeleteBoard()
          handleDelete(deletingBoardId)
          setShowDeleteAlert(false)
        }}
        open={showDeleteAlert}
      />
    </div>
  )
}

export default Sidebar;
