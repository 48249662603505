import ResultCardList from "@/views/Trends/SearchResults/components/ResultCardList";
import { INarrative } from "@/views/Trends/types";
import React, { useEffect, useState } from "react";

interface NarrativeTabProps {
    data: INarrative[];
    error: any;
    isLoading: boolean;
    page: number;
    pageSize: number;
    setPage: Function
}

export const NarrativeTab: React.FC<NarrativeTabProps> = ({
    data,
    error,
    isLoading,
    page,
    pageSize,
    setPage
}) => {

    const [results, setResults] = useState<INarrative[]>();

    useEffect(() => {
        if (error) return

        if (page === 1) {
            setResults(data);
        } else {
            setResults((prevResults) => {
                const newResults = [...(prevResults || []), ...data];
                return Array.from(new Map(newResults.map((item) => [item.id, item])).values());
            });
        }
    }, [error, data, page]);

    const handleLoadMore = () => {
        setPage((prevPage: number) => prevPage + 1);
    };

    const paginatedResults = results?.slice(0, page * pageSize);

    return (
        <ResultCardList
            data-testid="narrative-tab-result-list"
            results={paginatedResults}
            type="narrative"
            isLoading={isLoading}
            page={page}
            totalItems={results?.length || -1}
            pageSize={pageSize}
            handlePageChange={handleLoadMore}
        />
    );
};