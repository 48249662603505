import React, { useEffect } from "react";
import { Divider, Loader } from "rsuite";
import SearchBox from "@/components/SearchBox";
import { useGetTrendMoments, useGetTrendNarratives, useTrendById } from "../hooks/useTrends";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import SightlyButton from "@/components/Sightly/SightlyButton";
import { IMoment } from "@/views/Trends/types";
import NoImageFoundPath from '@/assets/img/NoImageFound.svg';

interface MomentViewSlideOverProps {
  trendId?: string;
}

const TrendMomentViewSlideOver: React.FC<MomentViewSlideOverProps> = ({ trendId }) => {
  const { trendQuery } = useTrendById(trendId!);
  const { narrativesQuery } = useGetTrendNarratives(trendId!, 1, 20);

  const [expandedTrends, setExpandedTrends] = React.useState<string[]>([]);
  const [momentsData, setMomentsData] = React.useState<IMoment[]>([]);

  const [search, setSearch] = React.useState<string>();

  const [momentPages, setMomentPages] = React.useState<Record<string, number>>({
    [trendId!]: 1,
  });

  const { momentsQuery, isLoading } = useGetTrendMoments(
    trendId!,
    search,
    momentPages[trendId!] || 1,
    20
  );

  useEffect(() => {
    setMomentsData([]);
  }, [search]);

  useEffect(() => {
    if (momentsQuery.data) {
      setMomentsData((prev) => [...prev, ...momentsQuery.data.data]);
    }
  }, [momentsQuery.data]);

  const handlePageChange = (trendId: string, newPage: number) => {
    setMomentPages((prev) => ({ ...prev, [trendId]: newPage }));
  };

  return (
    <div className="mt-[-2vh]">
      <Divider className="mb-[2vh]" />
      <div className="flex flex-col gap-4">
        <SearchBox
          dataTestId="trend-moment-search-box"
          handleSearch={setSearch}
          placeholder="Search by name"
          className="min-w-[25vw] w-[80%]"
        />

        <div className="my-3 shadow ring-1 ring-black ring-opacity-5 md:rounded-lg min-w-full">
          <table className="table-fixed bg-gray-50 w-full divide-y divide-gray-300">
            <thead className="text-[0.5rem] w-full">
              <tr className="text-gray-400 uppercase w-full">
                <th className="py-3.5 w-2/12"></th>
                <th className="text-left py-3.5 w-4/12">Moment Name</th>
                <th className="py-3.5 w-2/12">Articles</th>
                <th className="py-3.5 w-2/12">Keywords</th>
                <th className="py-3.5 w-2/12">Videos</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {trendQuery.data ? (
                <React.Fragment key={trendQuery.data?.data.id}>
                  <tr
                    className="fade-in animate-in cursor-pointer table-row"
                    onClick={() =>
                      setExpandedTrends((prev) =>
                        prev.includes(trendQuery.data?.data.id!)
                          ? prev.filter((id) => id !== trendQuery.data?.data.id)
                          : [...prev, trendQuery.data?.data.id!]
                      )
                    }
                  >
                    <td className="py-3.5 flex justify-center">
                      <ChevronDownIcon
                        className={`text-gray-400 w-5 transform transition-transform ${expandedTrends.includes(trendQuery.data?.data.id!) ? "rotate-180" : ""}`}
                      />
                    </td>
                    <td className="truncate pr-[0.5vw] w-[4/12]">{trendQuery.data?.data.name}
                    </td>
                    <td/>
                    <td/>
                    <td/>
                  </tr>

                  {expandedTrends.includes(trendQuery.data?.data.id!) && (
                    <tr>
                      <td colSpan={5}>
                        <div className="max-h-[30vh] overflow-y-auto w-full">
                          <table className="table-fixed w-full">
                            <tbody className="divide-y divide-gray-200 w-full">
                              {momentsData?.map((moment) => (
                                <tr key={moment.id} className="w-full">
                                  <td className="py-3.5 flex justify-center">
                                    <img
                                      src={moment.imageUrl || NoImageFoundPath}
                                      alt={moment.name}
                                      className="w-[4vw] aspect-square rounded-full object-cover"
                                      onError={(e) => {
                                        const target = e.target as HTMLImageElement;
                                        target.onerror = null;
                                        target.src = NoImageFoundPath;
                                      }}
                                    />

                                  </td>
                                  <td className="text-left pl-0.5 py-3.5 w-4/12 truncate">
                                    {moment.name}
                                  </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {moment.articleCount || 0}
                                  </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {moment.keywordCount || 0}
                                  </td>
                                  <td className="text-center py-3.5 w-2/12">
                                    {moment.videoCount || 0}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                          <div className="flex justify-center p-4">
                            <SightlyButton
                              datatestid="load-more-moments-button"
                              block
                              id={`loadmorebutton-${trendQuery.data?.data.id}`}
                              text="Load More"
                              handleClick={() =>
                                handlePageChange(trendQuery.data?.data.id!, momentPages[trendQuery.data?.data.id!] + 1)
                              }
                              loading={isLoading}
                              disabled={isLoading || momentPages[trendQuery.data?.data.id!] >= momentsQuery.data!.totalPages}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ) : (
                <tr>
                  <td colSpan={5}>
                    <div className="h-full flex items-center justify-center">
                      <Loader size="md" content="Loading..." center />
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default TrendMomentViewSlideOver;
