import { api } from "@/api/api";

export const trendTopicController = {
  getMomentsMethod: async (
    trendId: string,
    newPage: number,
    setMomentData: Function,
    setLoading: (loading: boolean) => void
  ) => {
    setLoading(true);
    try {
      const momentsData = await api.trends.getMoments(trendId, undefined, newPage || 1, 20);

      setMomentData((prev: Record<string, { data: any[]; totalPages: number }>) => ({
        ...prev,
        [trendId]: {
          data: [...(prev[trendId]?.data || []), ...momentsData.data],
          totalPages: momentsData.totalPages,
        },
      }));
      
      return momentsData;
    } finally {
      setLoading(false);
    }
  },
};
