import { z } from "zod";

export const SegmentSchema = z.object({
  id: z.number(),
  name: z.string(),
  description: z.string(),
  keywords: z.array(z.string()),
  accountId: z.number(),
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
  deletedAt: z.string().datetime().nullable(),
});

export const SegmentPaginatedResponseSchema = z.object({
  count: z.number(),
  totalCount: z.number(),
  currentPage: z.number(),
  nextPage: z.number().nullable(),
  lastPage: z.number(),
  results: z.array(SegmentSchema),
});

export type Segment = z.infer<typeof SegmentSchema>;
export type GetSegmentsResponse = z.infer<typeof SegmentPaginatedResponseSchema>;
