import SearchBox from '@/components/SearchBox';
import DateRangeSection from '@/components/Sightly/SightlyFormElements/DateRangeSection';
import SocialPostResultCard from '@/views/Trends/TrendDetails/components/SocialPostResultCard';
import { ISocialPost, metaData } from '@/views/Trends/types';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import React, { useState } from 'react';

interface SocialPostListProps {
  socialPosts: ISocialPost[]
}

const SocialPostResultsList = ({ socialPosts }: SocialPostListProps) => {
  const [filteredSocialPosts, setFilteredSocialPosts] = useState(socialPosts);
  const [showMoreStates, setShowMoreStates] = useState<{ [key: string]: boolean }>({});

  const handleSearch = (keyword: string) => {
    const lowerKeyword = keyword.toLowerCase();

    const newFilteredsocialPosts = filteredSocialPosts.filter((socialPost) => socialPost.post.toLowerCase().includes(lowerKeyword));

    setFilteredSocialPosts(newFilteredsocialPosts);
  }

  const sourceTypes = filteredSocialPosts
    .map((socialPost) => socialPost.source)
    .flatMap((sourceType) => (Array.isArray(sourceType) ? sourceType : [sourceType]))
    .filter((sourceType, index, arr) => arr.indexOf(sourceType) === index);

  const getLast60Days = (): [Date, Date] => {
    const now = new Date();
    const sixtyDaysAgo = new Date(now);
    sixtyDaysAgo.setDate(sixtyDaysAgo.getDate() - 60);
    sixtyDaysAgo.setHours(0, 0, 0, 0);
    return [sixtyDaysAgo, now];
  }

  const startingRange = getLast60Days();

  const [startingDateObj, setStartingDateObj] = React.useState<Date>(startingRange[0]);
  const [endingDateObj, setEndingDateObj] = React.useState<Date>(startingRange[1]);

  const handleChangeDateRange = (selectedRange: any) => {
    setStartingDateObj(selectedRange[0]);
    setEndingDateObj(selectedRange[1]);

    // Filter when range is updated
    // set filtered socialPosts by how many are within the date range
    const newFilteredArticles = filteredSocialPosts.filter((socialPost) => {
      const timestamp = dayjs(socialPost.publishedDate);
      const startTime = dayjs(selectedRange[0]);
      const endTime = dayjs(selectedRange[1]);
      return timestamp.isAfter(startTime) && timestamp.isBefore(endTime);
    });

    setFilteredSocialPosts(newFilteredArticles);
  };

  const toggleShowMore = (sourceType: string) => {
    setShowMoreStates((prev) => ({
      ...prev,
      [sourceType]: !prev[sourceType],
    }));
  };

  return (
    <div>
      <div className="w-full flex justify-between">
        <div>
          <SearchBox
            dataTestId="social-post-result-list-search-box"
            handleSearch={handleSearch}
            placeholder="Search Social Posts"
            className='mb-3'
            showClearButton={true}
          />
        </div>
        <div>
          <DateRangeSection
            onSelectDateRange={handleChangeDateRange}
            rangeValue={[startingDateObj, endingDateObj]}
            data-testid="social-post-date-range"
          />
        </div>
      </div>
      <div className="w-full flex justify-end py-2" data-testid="social-post-result-list-count">
        Showing 1-{filteredSocialPosts.length} of {socialPosts.length}
      </div>
      {sourceTypes.map((sourceType, index) => {
        const filteredSocialPostsForSourceType = filteredSocialPosts.filter((socialPost) =>
          socialPost.source === sourceType
        );
        const showMoreButton = filteredSocialPostsForSourceType.length > 4;
        const showMore = showMoreStates[sourceType] || false;

        return (
          <div 
            key={`source-type-map-key-${index}`} 
            className="flex flex-col mt-4" 
            data-testid={`source-type-container-${sourceType}`}
          >
            <h3 className="mb-2" data-testid={`source-type-header-${sourceType}`}>
              {sourceType}
            </h3>
            <div className="flex flex-wrap" data-testid={`source-type-posts-${sourceType}`}>
              {filteredSocialPostsForSourceType
                .slice(0, showMore ? filteredSocialPostsForSourceType.length : 4)
                .map((socialPost, idx) => (
                  <SocialPostResultCard
                    key={`social-post-result-${socialPost.id}-${idx}`}
                    socialPost={socialPost}
                    data-testid={`social-post-card-${socialPost.id}-${idx}`}
                  />
                ))}
              {showMoreButton && (
                <div 
                  className="flex items-center" 
                  data-testid={`source-type-show-more-container-${sourceType}`}
                >
                  <button
                    className="rounded-full bg-[#7B61FF] h-[40px] w-[40px] flex items-center justify-center"
                    onClick={() => toggleShowMore(sourceType)}
                    data-testid={`toggle-show-more-button-${sourceType}`}
                  >
                    {showMore ? (
                      <ChevronLeftIcon 
                        className="w-5 h-5" 
                        aria-hidden="true" 
                        color="#FFFFFF" 
                        data-testid="chevron-left-icon"
                      />
                    ) : (
                      <ChevronRightIcon 
                        className="w-5 h-5" 
                        aria-hidden="true" 
                        color="#FFFFFF" 
                        data-testid="chevron-right-icon"
                      />
                    )}
                  </button>
                </div>
              )}
            </div>
          </div>
        );
        
      })}
    </div>
  )
};

export default SocialPostResultsList;