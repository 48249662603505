import React from 'react';

interface IProps {
  selectedTabIndex: number
  setSelectedTabIndex: Function
  fixedHeadline?: String
  tabs: Array<{ label: string; id: string; isVisible: boolean }>
}

export default function BoardTabs({ selectedTabIndex, setSelectedTabIndex, fixedHeadline, tabs }: IProps) {
  const handleClick = (index: number) => {
    setSelectedTabIndex(index);
  };

  const getSelectedTabStyle = (tabIndex: number) =>
    selectedTabIndex === tabIndex ? 'font-semibold border-sightlyPurple' : 'border-transparent';

  return (
    <>
      {fixedHeadline &&
        <h3 className="flex-row grow w-full mt-3 px-6">{fixedHeadline}</h3>
      }
      <div data-testid="anticipation-board-header-tabs" className="flex h-16 px-6 gap-8 pointer">
      {tabs.map((tab, index) =>
        tab.isVisible ? (
          <button
            key={tab.id}
            id={`pendo-${tab.id === 'moments' ? 'moments' : 'conversations'}-tab`}
            className={`flex flex-col justify-center cursor-pointer border-b-4 ${getSelectedTabStyle(index)}`}
            onClick={() => handleClick(index)}
          >
            {tab.label}
          </button>
        ) : null
      )}
      </div>
    </>
  )
}