import {
  ReportTemplate,
  REPORT_TYPE,
  Option,
  TEMPLATE_TYPE,
  templateGenericDimensionOptions,
  templateMetricOptions,
  templateConversionOptions,
  GENERIC_DIMENSION,
  METRIC, GenericDimensionOption, CONVERSION
} from "@/views/ActivationReports/types/activation-report.types";

export enum INDUSTRY_CATEGORY_DIMENSION {
    BRAND_PROFILE_NAME = 'brandProfileName',
    INDUSTRY_CATEGORY = 'industryCategory',
}

interface IndustryCategoryDimensionOption extends Omit<GenericDimensionOption, 'value'> {
  name: string
  value: GENERIC_DIMENSION | INDUSTRY_CATEGORY_DIMENSION
}

export interface IndustryCategoryReport extends Omit<ReportTemplate, 'columns' | 'dimensions'> {
  columns: (GENERIC_DIMENSION | INDUSTRY_CATEGORY_DIMENSION | METRIC | CONVERSION)[],
  dimensions: (GenericDimensionOption | IndustryCategoryDimensionOption)[]
}

export const dimensionOptions: Option<IndustryCategoryDimensionOption> = {
  [INDUSTRY_CATEGORY_DIMENSION.BRAND_PROFILE_NAME]: {
    name: 'Brand Profile Name',
    value: INDUSTRY_CATEGORY_DIMENSION.BRAND_PROFILE_NAME,
    description: 'Name associated with a brand profile' // Generated description
  },
  [INDUSTRY_CATEGORY_DIMENSION.INDUSTRY_CATEGORY]: {
    name: 'Industry Category',
    value: INDUSTRY_CATEGORY_DIMENSION.INDUSTRY_CATEGORY,
    description: 'Represents different industry sectors' // Generated description
  },
}

export const baseIndustryCategoryReport: IndustryCategoryReport = {
  name: 'Industry Category Report',
  description: '',
  type: REPORT_TYPE.DETAIL,
  templateType: TEMPLATE_TYPE.INDUSTRY_CATEGORY,
  columns: [],
  dimensions: [
    ...templateGenericDimensionOptions,
    ...Object.keys(dimensionOptions).map(key => dimensionOptions[key])
  ],
  metrics: templateMetricOptions,
  conversions: templateConversionOptions
}
