import SightlySelect from '@/components/Sightly/SightlyFormElements/SightlySelect'
import { logError } from '@/utils'
import BoardOverviewCardComponent from '@/views/Discover/Boards/components/conversations/BoardOverviewCard'
import StatsComponent from '@/views/Discover/Boards/components/conversations/StatsComponent'
import React, { useEffect, useState } from 'react'
import {
  FaAddressBook,
  FaFacebook,
  FaInstagram,
  FaReddit,
  FaTiktok,
  FaTwitch,
  FaTwitter,
  FaYoutube
} from 'react-icons/fa'
import { Panel } from 'rsuite'

import { MyLocationGenerics } from '@/classes/utils'
import { InfoPage } from '@/components/InfoPage'
import { LoadingPage } from '@/components/LoadingPage'
import NarrativesPanel, { ISortBy, ITheme, ITopic } from '@/views/Discover/Boards/Narratives/NarrativesPanel'
import OverviewSidePanel from '@/views/Discover/Boards/Narratives/OverviewSidePanel/OverviewSidePanel'
import SocialNarrativeSlideOver from '@/views/Discover/Boards/Narratives/SocialNarrativePanel/SocialNarrativeSlideOver'
import NoFoundMessage from '@/views/Discover/Boards/components/NoFoundMessage'
import { INarrative, IOverview, IPostCountPerDayJSON } from '@/views/Discover/Boards/types'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useSearch } from '@tanstack/react-location'

// The new date to filter is id x 15 days
const timeRangeOptions = [
  { id: 1, label: '15 Days' },
  { id: 2, label: '30 Days' },
  { id: 4, label: '60 Days' }
]

const topPlatforms = [
  {
    name: 'YouTube',
    url: 'https://www.youtube.com',
    icon: <FaYoutube className="w-10 h-10 text-red-500 cursor-pointer" />
  },
  {
    name: 'Blogs & Forums',
    url: '',
    icon: <FaAddressBook className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'News',
    url: '',
    icon: <FaAddressBook className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'Reviews',
    url: '',
    icon: <FaAddressBook className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'TikTok',
    url: 'https://www.tiktok.com',
    icon: <FaTiktok className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'X',
    url: 'https://www.x.com',
    icon: <FaTwitter className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'Twitch',
    url: 'https://www.twitch.tv',
    icon: <FaTwitch className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'Reddit',
    url: 'https://www.reddit.com',
    icon: <FaReddit className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'Facebook',
    url: 'https://www.facebook.com',
    icon: <FaFacebook className="w-10 text-black cursor-pointer h-9" />
  },
  {
    name: 'Instagram',
    url: 'https://www.instagram.com',
    icon: <FaInstagram className="w-10 h-10 text-purple-500 cursor-pointer" />
  }
]

interface IProps {
  getSelectedBoardNames: () => string
  selectedTabIndex: number
  setSelectedTabIndex: (index: number) => void
  today: Date
  pastDate: Date
  timeRange: number
  setTimeRange: (range: number) => void
  page: number
  setPage: (page: number) => void
  selectedTopics: number[]
  setSelectedTopics: (topics: number[]) => void
  selectedThemes: number[]
  setSelectedThemes: (themes: number[]) => void
  selectedSortBy: ISortBy
  setSelectedSortBy: (sortBy: ISortBy) => void
  socialOverview: any
  socialNarrativesData: any
  socialNarrativesIsLoading: boolean
  socialNarrativesError: any
  handleDownloadNarratives: any
}

export default function Narratives({
  today,
  pastDate,
  getSelectedBoardNames,
  selectedTabIndex,
  setSelectedTabIndex,
  timeRange,
  setTimeRange,
  page,
  setPage,
  selectedTopics,
  setSelectedTopics,
  selectedThemes,
  setSelectedThemes,
  selectedSortBy,
  setSelectedSortBy,
  socialOverview,
  socialNarrativesData,
  socialNarrativesIsLoading,
  socialNarrativesError,
  handleDownloadNarratives
}: IProps) {
  const [narratives, setNarratives] = useState<INarrative[]>([])
  const [previousPagesNarratives, setPreviousPagesNarratives] = useState<INarrative[]>([])
  const [overview, setOverview] = useState<IOverview | undefined>()

  const [openOverviewPanel, setOpenOverviewPanel] = React.useState<boolean>(false)

  const [topicOptions, setTopicOptions] = React.useState<ITopic[]>([])
  const [themesOptions, setThemeOptions] = React.useState<ITheme[]>([])
  const [sortByOptions, setSortByOptions] = React.useState<ISortBy[]>([])
  const [topPostCountNarratives, setTopPostCountNarratives] = useState<INarrative[]>([])
  const [isDataReady, setIsDataReady] = useState(false)
  const [openSocialNarrativePanel, setOpenSocialNarrativePanel] = React.useState<boolean>(false)
  const [selectedSocialClusterId, setSelectedSocialClusterId] = useState<string>('')

  const toggleSocialNarrativePanel = (socialClusterId: string) => {
    const openSlideover = !openSocialNarrativePanel
    if (openSlideover) {
      setSelectedSocialClusterId(socialClusterId)
    } else {
      setSelectedSocialClusterId('')
    }
    setOpenSocialNarrativePanel(openSlideover)
  }

  /* Card pagination */
  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 3
  const startIndex = currentPage * itemsPerPage

  const { brandProfileId, boardIds } = useSearch<MyLocationGenerics>()

  const isLoading = socialOverview.isLoading && socialNarrativesIsLoading
  const error = socialOverview.error || socialNarrativesError
  const overviewData = socialOverview?.data?.overviewData

  const narrativesData: any[] = socialNarrativesData?.socialClusters?.result ?? []
  const hasNext = socialNarrativesData?.socialClusters?.hasNext ?? false
  const totalCount = socialNarrativesData?.socialClusters?.totalCount ?? 0

  const config = socialNarrativesData?.config

  useEffect(() => {
    if (!isLoading && overviewData && narrativesData && config) {
      setOverview(overviewData)
      setNarratives(previousPagesNarratives.concat(narrativesData))
      setPreviousPagesNarratives([]) // reset saved narratives from new page
      setTopicOptions(config.topicOptions ?? [])
      setThemeOptions(config.themeOptions ?? [])
      setSortByOptions(config.sortByOptions ?? [])
      setTopPostCountNarratives(
        narrativesData
          .sort((a: { postCount: number }, b: { postCount: number }) => b.postCount - a.postCount)
          .slice(0, 6)
      )
      setIsDataReady(true)
    }
    if (error) {
      logError(error)
    }
  }, [isLoading, overviewData, narrativesData, error])

  useEffect(() => {
    if (isLoading) {
      setIsDataReady(false)
    }
  }, [isLoading])

  useEffect(() => {
    setSelectedThemes([])
  }, [timeRange])

  const handleNext = () => {
    setCurrentPage((prev) => (prev + 1) % Math.ceil(topPostCountNarratives.length / itemsPerPage))
  }

  const handlePrev = () => {
    setCurrentPage(
      (prev) =>
        (prev - 1 + Math.ceil(topPostCountNarratives.length / itemsPerPage)) %
        Math.ceil(topPostCountNarratives.length / itemsPerPage)
    )
  }

  const getTopPlatformIcons = (platforms: string[]) => {
    return platforms
      .map((platformName) => {
        return topPlatforms.filter((platform) => platform.name === platformName)
      })
      .flat()
  }

  if (boardIds?.length === 0) {
    return <InfoPage message="Please select a board" />
  }

  return (
    <>
      <div className="px-4 main">
        <div
          className="w-full h-full bg-white"
          data-testid="conversations-main-panel"
        >
          <div
            className="flex justify-end mt-6"
            data-testid="conversations-filters-panel-dateDropdown"
          >
            <SightlySelect
              id="dateDropdown"
              width={162}
              options={timeRangeOptions}
              buttonClass="conversations-date-dropdown-select"
              labelKey="label"
              valueKey="id"
              onChange={setTimeRange}
              value={timeRange}
            />
          </div>

          {boardIds?.length == 0 ? (
            <div
              data-testid="conversations-no-data"
              className="h-[50%] place-self-center flex justify-center items-center flex-col w-full"
            >
              <NoFoundMessage
                title="No narratives found"
                subtitle="Select another date range or another AI board to view related narratives."
              />
            </div>
          ) : isDataReady ? (
            narratives.length > 0 ? (
              <div data-testid="conversations-overview-panel">
                <Panel
                  header={
                    <div className="w-full pb-4 border-b">
                      <span className="text-xl font-bold">Overview</span>
                    </div>
                  }
                  collapsible
                  defaultExpanded={true}
                  className="hover:overflow-visible"
                >
                  <div className="flex flex-col gap-6">
                    <div data-testid="conversations-statscomponent-container">
                      {overview && (
                        <StatsComponent
                          narrativesIdentified={overview.narrativesIdentified}
                          platformSources={overview.platformSources}
                          topPlatforms={getTopPlatformIcons(overview.topPlatforms)}
                          topThemes={overview.topThemes}
                          data-testid="conversations-statscomponent"
                          setIsViewMoreClicked={setOpenOverviewPanel}
                        />
                      )}
                    </div>
                    <div className="flex flex-col gap-4">
                      <p
                        data-testid="conversations-trending-title"
                        className="text-sm font-bold"
                      >
                        What’s Trending
                      </p>
                      <div
                        className="flex items-center"
                        data-testid="conversations-trending-container"
                      >
                        {currentPage > 0 && (
                          <button
                            onClick={handlePrev}
                            className="p-1 mr-2 bg-purple-500 rounded-full"
                            data-testid="conversations-prev-button"
                          >
                            <ChevronLeftIcon className="w-3 h-3 text-white" />
                          </button>
                        )}
                        <div
                          className="grid grid-cols-1 gap-8 xl:grid-cols-2 2xl:grid-cols-3"
                          data-testid="conversations-cards-container"
                        >
                          {overview?.topClusters.slice(startIndex, startIndex + itemsPerPage).map((data, index) => {
                            let color = chartColor(index)
                            return (
                              <div className="col-span-1">
                                <BoardOverviewCardComponent
                                  key={index}
                                  clusterId={data.cluster.id}
                                  title={data.cluster.name}
                                  totalPosts={data.cluster.postCount}
                                  growth={parseInt(data.growth)}
                                  index={startIndex + index + 1}
                                  fillColor={color.fill}
                                  strokeColor={color.stroke}
                                  data={parseAreaChartData(data.cluster.postCountPerDayJson)}
                                  data-testid={`conversations-card-${index}`}
                                  toggleSocialNarrativePanel={toggleSocialNarrativePanel}
                                />
                              </div>
                            )
                          })}
                        </div>

                        {currentPage < Math.ceil(topPostCountNarratives.length / itemsPerPage) - 1 && (
                          <button
                            onClick={handleNext}
                            className="p-1 ml-2 bg-purple-500 rounded-full"
                            data-testid="conversations-next-button"
                          >
                            <ChevronRightIcon className="w-3 h-3 text-white" />
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                  {openOverviewPanel && brandProfileId && boardIds && (
                    <OverviewSidePanel
                      {...{
                        openOverviewPanel,
                        setOpenOverviewPanel,
                        brandProfileId,
                        boardIds,
                        minPublishDate: pastDate,
                        maxPublishDate: today
                      }}
                    />
                  )}
                  {openSocialNarrativePanel && (
                    <SocialNarrativeSlideOver
                      {...{
                        openSocialNarrativePanel,
                        setOpenSocialNarrativePanel,
                        socialData: narrativesData,
                        selectedSocialClusterId
                      }}
                    />
                  )}
                </Panel>
                <NarrativesPanel
                  narratives={narratives}
                  topicOptions={topicOptions}
                  themeOptions={themesOptions}
                  sortByOptions={sortByOptions}
                  data-testid="conversations-narratives-panel"
                  topics={selectedTopics}
                  setTopics={setSelectedTopics}
                  themes={selectedThemes}
                  setThemes={setSelectedThemes}
                  selectedSortBy={selectedSortBy}
                  setSelectedSortBy={setSelectedSortBy}
                  handleDownloadNarratives={handleDownloadNarratives}
                  hasNext={hasNext}
                  totalCount={totalCount}
                  isLoading={socialNarrativesIsLoading}
                  page={page}
                  setPage={setPage}
                  setPreviousPagesNarratives={setPreviousPagesNarratives}
                  parseAreaChartData={parseAreaChartData}
                  toggleSocialNarrativePanel={toggleSocialNarrativePanel}
                />
              </div>
            ) : (
              <div
                data-testid="conversations-no-data"
                className="h-[50%] place-self-center flex justify-center items-center flex-col w-full"
              >
                <NoFoundMessage
                  title="No narratives found"
                  subtitle="Select another date range or another AI board to view related narratives."
                />
              </div>
            )
          ) : (
            <div className="h-[50%] place-self-center">
              <LoadingPage
                data-testid="conversations-fetching-narratives"
                message={'Fetching narratives'}
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

function parseAreaChartData(data: IPostCountPerDayJSON[]) {
  const sortedData = data.sort((a, b) => new Date(a.publishDate).getTime() - new Date(b.publishDate).getTime())

  return sortedData.map((d, index, array) => {
    const previousPostCount = index > 0 ? array[index - 1].postCount : null
    const growth = previousPostCount ? (d.postCount - previousPostCount) / previousPostCount : null

    return {
      date: new Date(d.publishDate).toLocaleDateString('en-US', { year: '2-digit', month: '2-digit', day: '2-digit' }),
      posts: d.postCount,
      growth: growth ? Number((growth * 100).toFixed(0)) : null
    }
  })
}

function chartColor(index: number) {
  const colors = [
    { stroke: '#5B8FF9', fill: '#CEDEFE' },
    { stroke: '#E8684A', fill: '#F9D2C9' },
    { stroke: '#5D7092', fill: '#C7CDD9' }
  ]
  return colors[index % 3]
}
