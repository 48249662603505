import { typeOneSections } from './views/BrandProfiles/BrandProfile/components/Queries/constants'
import { NewBoardNlpPromptFormSchema, NewBoardNlpPromptFormType, NLBoardRequest, MomentPreviewsType } from '@/views/Discover/Boards/types';

type MomentsProps = {
    boardIds: number[]
    brandProfileId: number | undefined
    sortBy: string
    startDate: string
    endDate: string
    actions: string[] | undefined
    activationStatus: string[]
    searchTerm: string
    aylienIndustries: string[]
    aylienNews: string[]
}
type TaxonomiesProps = {
    brandProfileId: number | undefined
    startDate?: string,
    endDate?: string,
    boardIds: number[]
    actions: string[] | undefined
    searchTerm: string
    activationStatus: string[]
}

export const rqKeys = {
    userKey: (userId: number, packageAccountId?: number) => ['useUserKey', userId, packageAccountId] as const,
    subscriptionQuotaQueryKey: (accountId?:number, productName?:string) => ['subscriptionQuota',accountId, productName] as const,
    embedUrlsKey: (accountId: number | undefined) => ['embedUrls', accountId] as const,
    listsKey: (accountId: number) => ['lists', accountId] as const,
    rolesKey: () => ['rolesPermissionsQuery'] as const,
    usersKeys: (accountId: number | undefined) => ['users', accountId] as const,
    brandProfileCreateMutationKey: (accountId: number | undefined) => ['createBrandProfile',accountId] as const,
    brandProfileAiGenerateKey: (accountId: number | undefined) => ['aiGenerateBrandProfile',accountId] as const,
    brandProfileBasic: (brandProfileId: number) => ['brandProfile', brandProfileId] as const,
    brandProfileInsertionOrders: (brandProfileId: number | undefined) =>
        ['brandProfileInsertionOrders', brandProfileId] as const,
    brandProfileStatusKey: (brandProfileId: number) => ['brandProfileStatus', brandProfileId] as const,
    brandProfileKeywordsKey: (brandProfileId: number) => ['brandProfileKeywords', brandProfileId] as const,
    brandProfileScenarios: (brandProfileId: number) => ['scenarios', brandProfileId] as const,
    brandProfileOpinions: (brandProfileId: number, sortBy?: 'least' | 'most') => ['opinions', brandProfileId, sortBy] as const,
    brandProfileOpinionsAIMutation: (accountId?: number) => ['opinionsAiGeneration', accountId] as const,
    brandProfileEvents: (brandProfileId: number) => ['events', brandProfileId] as const,
    brandProfileSocial: (brandProfileId: number) => ['social', brandProfileId] as const,
    brandProfileBoards: (brandProfileId: number | undefined) => ['boards', brandProfileId] as const,
    boardV1: (boardId: number) => ['boardId', boardId] as const,
    brandProfileQuery: (queryType: typeOneSections, brandProfileId: number) => [queryType, brandProfileId] as const,
    aylienIndBasicInfo: () => ['aylienIndustriesBasicInfo'] as const,
    justificationMoments: () => ['relevance_summary'] as const,
    wikiOptions: (wikiSearchTerm: string) => ['wikiOptions', wikiSearchTerm] as const,
    socialAylien: () => ['aylienItems'] as const,
    opinionTypesKey: (excludeArchived: boolean | undefined) => ['opinionTypes', excludeArchived] as const,
    brandProfilesKey: (accountId: number | undefined) => ['brandProfiles', accountId] as const,
    opinionQueryKey: (opinionId: string) => ['adminOpinion', opinionId] as const,
    adminOpinions: () => ['adminOpinions'] as const,
    adminTopics: () => ['fetchAdminTopics'] as const,
    excludedContentThemes: () => ['fetchExcludedTopicThemes'] as const,
    sensitiveContentCategories: () => ['fetchSensitiveContentCategories'] as const,
    adminIabCatsStatic: () => ['fetchIabCategoriesStatic'] as const,
    adminIabCategories: () => ['adminIabCategories'] as const,
    adminAylienNews: () => ['fetchAylienNewsAdmin'] as const,
    adminAylienInd: () => ['fetchAylienIndustryAdmin'] as const,
    adminPermissionRoles: () => ['adminPermissionRoles'] as const,
    scenario: (scenarioId: number) => ['adminscenario', scenarioId] as const,
    scenarioDataSegments: () => ['dataSegments'] as const,
    scenarioLabels: () => ['scenariolabels'] as const,
    scenarioTypes: () => ['scenarioTypes'] as const,
    hashtagDataKey: (taxonomyCode: string, page: number, theme?: string) => ['hashtagDataKey', { taxonomyCode, theme, page}] as const,
    messageDataKey: (taxonomyCode: string, page: number, theme?: string) => ['messageDataKey', { taxonomyCode, theme, page}] as const,
    metricDataKey: (taxonomyCode: string, theme?: string) => ['metricDataKey', { taxonomyCode, theme}] as const,
    metricsDataKey: (taxonomyCode: string, theme?: string) => ['metricDataKey', { taxonomyCode, theme}] as const,
    targetViewInsertionOrders: () => ['targetViewInsertionOrders'] as const,
    targetViewInsertionOrdersByAccountId: (accountId: number) => ['targetViewInsertionOrders'] as const,
    trendsLatestBatch: () => ['trendsLatestBatch'] as const,
    trends: (
        brandProfileId: number | undefined,
        date: string | undefined,
        hour: number | undefined,
        sortBy: string,
        isDemo: boolean
    ) => ['trends', brandProfileId, date, hour, sortBy, isDemo] as const,
    moments: ({
        boardIds,
        brandProfileId,
        sortBy,
        startDate,
        endDate,
        actions,
        activationStatus,
        searchTerm,
        aylienIndustries,
        aylienNews
    }: MomentsProps) =>
        [
            'moments',
            boardIds,
            brandProfileId,
            sortBy,
            startDate,
            endDate,
            actions,
            activationStatus,
            searchTerm,
            aylienIndustries,
            aylienNews
        ] as const,
    momentPreviews: (({
        prompt,
        ideasToLeanInto,
        ideasToAvoid,
        thumbsUpMoments,
        thumbsDownMoments,
        iteration
    }:NewBoardNlpPromptFormType) => [
        'momentPreviews',
        { prompt,
        ideasToLeanInto,
        ideasToAvoid,
        thumbsUpMoments,
        thumbsDownMoments,
        iteration }
    ] as const),
    taxonomies: ({ brandProfileId, startDate, endDate, boardIds, actions, searchTerm, activationStatus }: TaxonomiesProps) =>
        ['taxonomies', brandProfileId, startDate, endDate, boardIds, actions, searchTerm, activationStatus] as const,
    searchedAylienNewsQueryKey: (searchTerm:string, brandProfileId: number|undefined) => ['searchNews',searchTerm,'brandProfileId',brandProfileId],
    searchedAylienIndustriesQueryKey: (searchTerm:string, brandProfileId: number|undefined) => ['searchIndustries',searchTerm,'brandProfileId',brandProfileId],
    userCompletionSteps: (userId:number) => ['userId', userId]
}
